import React, { useState } from 'react';

import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
} from "@react-pdf/renderer";
import Reactpdfrender from './Reactpdfrender';

const styles = StyleSheet.create({
    viewer: {
        width: window.innerWidth / 3,
        height: window.innerHeight / 2,
    },
    //further code...
});
const Reactpdfviewer = () => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    return (
      
         <div>
             <Document file="https://www.africau.edu/images/default/sample.pdf" onLoadSuccess={onDocumentLoadSuccess}>
                 <Page pageNumber={pageNumber} />
             </Document>
             <p>
                 Page {pageNumber} of {numPages}
             </p>
               {/* <Reactpdfrender /> */}
         </div>
    );
}

export default Reactpdfviewer