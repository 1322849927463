import React, { useEffect, useLayoutEffect, useState } from "react";
import { useFormik } from "formik";
import appoinmentService from "../services/inventory.service";
import { Appoinmentvalidationschema } from "../components/Validation";
import ReactPaginate from "react-paginate";
import { DateRangePicker, Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";
import Loader from "../components/Loader";
import Toastmessage from "../components/Toastmessage";
import { Link, useNavigate, useSearchParams /*  */ } from "react-router-dom";
import Alertmsg from "../components/Alertmsg";
import imagename from "../assets/images/user.png";
import analyticsService from "../services/analytics.service";
import categoryService from "../services/category.service";
import employeeService from "../services/employee.service";
import axios from "axios";
import companyService from "../services/company.service";

const Companystate = ({ navigation }) => {
  let navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const [iserror, setIserror] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [ismessage, setIsmessage] = useState(false);

  const [employeedata, setEmployeedata] = useState([]);
  const [result, setResult] = useState([]);
  //smart table data
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState("");
  const [filterrole, setFilterrole] = useState("main");

  const [filtercategory, setFiltercategory] = useState("");
  const [search, setSearch] = useState("");
  const [between, setBetween] = useState("");
  const [sort, setSort] = useState("com_fullname ASC");
  const [group, setGroup] = useState("mat_name");

  const [pagecount, setPagecount] = useState("");
  const [resultCount, setResultcount] = useState("");
  const [categorylist, setCategorylist] = useState([]);
  const [companys, setCompanys] = useState([]);

  //filter arrays
  const statusfilterarr = [
    { status_id: "01", status_name: "pending" },
    { status_id: "02", status_name: "active" },
    { status_id: "03", status_name: "inactive" },
  ];
  const rolefilterarr = [
    { status_id: "01", status_name: "main" },
    { status_id: "02", status_name: "vendor" },
  ];
  const [statusfilterarray, setStatusfilterarray] = useState(statusfilterarr);
  //date range
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };
  const handleSelectdate = async (date) => {
    await setStartDate(date.selection.startDate);
    await setEndDate(date.selection.endDate);
    setBetween(
      format(date.selection.startDate, "yyyy-MM-dd") +
        " , " +
        format(date.selection.endDate, "yyyy-MM-dd")
    );
  };
  const resetdaterange = () => {
    setBetween("");
    setStartDate(new Date());
    setEndDate(new Date());
  };

  //use effects
  useEffect(() => {
    getallCompany();
    getallCategory();
  }, []);
  useLayoutEffect(() => {
    getallCompany();
  }, [filter, filterrole, search]);
  /* useLayoutEffect(() => {
    if (
      searchParams.get("category") == null ||
      searchParams.get("category") == "all"
    ) {
      setFiltercategory("");
    } else {
      setFiltercategory(searchParams.get("category"));
    }
  }, [searchParams.get("category")]); */

  //get allcompany
  const getallCompany = async () => {
    setIsLoaded(true);
    var data = {
      limit: limit,
      page: page,
      filter: filter,
      filterrole: filterrole,
      search: search,
      between: "",
      sort: sort,
      group: "",
    };
    return await companyService
      .getallCompany(data)
      .then((res) => {
        console.log(res.data.result);
        if (res.data.result) {
          setIsLoaded(false);
          // setIserror(res.data.status);
          //setIsmessage(res.data.message);
          setCompanys(res.data.result.data);
          setResult(res.data);
          setPage(res.data.result.page);
          setLimit(res.data.result.limit);
          setPagecount(res.data.result.pagecount);
          setResultcount(res.data.result.resultcount);
          console.log(res.data);
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
      });
  };

  //pagination
  const paginationfn = (event) => {
    setPage(event.selected + 1);
    console.log(event.selected + 1);
  };
  //search
  const searchfn = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };
  //filter
  const [filterArr, setfilterArr] = useState([]);
  const filterfn = (value) => {
    setPage(1);
    var index = filterArr.indexOf(value);
    if (index > -1) {
      filterArr.splice(index, 1);
      setFilter(filterArr.toString());
    } else {
      filterArr.push(value);
      setFilter(filterArr.toString());
    }
  };
  //filter role
  const [filterroleArr, setfilterroleArr] = useState([]);
  const filterrolefn = (value) => {
    setPage(1);
    var index = filterroleArr.indexOf(value);
    if (index > -1) {
      filterroleArr.splice(index, 1);
      setFilterrole(filterroleArr.toString());
    } else {
      filterroleArr.push(value);
      setFilterrole(filterroleArr.toString());
    }
  };
  //sort
  const sortfn = (value) => {
    /*    setSort(value); */
    if (sort === value + " desc") {
      setSort(value + " asc");
      console.log(value + " asc");
    } else if (sort === value + " asc") {
      setSort("");
      console.log(value + "");
    } else {
      setSort(value + " desc");
      console.log(value + " desc");
    }
  };
  //between
  const [betweenstart, setBetweenstart] = useState("");
  const [betweenend, setBetweenend] = useState("");
  const betweenfn = (value, e) => {
    setPage(1);
    if (e === null || e === "") {
      setBetween("");
      setBetweenstart("");
      setBetweenend("");
    } else {
      if (value === "start") {
        setBetween(e + "," + e);
        console.log(e + "," + e);
        setBetweenstart(e);
        setBetweenend(e);
      } else if (value === "end") {
        setBetweenend(e);
        const dd = between.split(",");
        setBetween(dd[0] + "," + e);
        console.log(dd[0] + "," + e);
        setBetweenstart(dd[0]);
        setBetweenend(e);
      } else {
        setBetween("");
        setBetweenstart("");
        setBetweenend("");
      }
    }
  };

  //get allcategory
  const getallCategory = async () => {
    var data = {
      limit: 10,
      page: 1,
      filter: "",
      filtercategory: "main",
      search: "",
      between: "",
      sort: "category_name ASC",
      group: "",
    };
    return await categoryService
      .getallCategory(data)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          setCategorylist(res.data.result.data);
        } else {
          setCategorylist([]);
        }
      })
      .catch((err) => {
        setCategorylist([]);
      });
  };
  const selectedcolor = (value) => {
    console.log(value);
    if (value === "installation") {
      return "badge rounded-pill bg-success";
    } else if (value === "servicing") {
      return "badge rounded-pill bg-warning ";
    } else if (value === "contract") {
      return "badge rounded-pill bg-danger";
    } else if (value === "rectification") {
      return "badge rounded-pill bg-danger";
    } else if (value === null) {
      return "badge rounded-pill bg-success";
    } else {
      return "text-primary mr-2 p-2";
    }
  };
  const [serviceslist, setServiceslist] = useState([
    "all",
    "installation",
    "servicing",
    "contract",
    "rectification",
  ]);

  const MovingComponent = (props, { children }) => {
    const [state, setState] = useState([]);
    //get allappoinment
    useEffect(() => {}, []);
    useLayoutEffect(() => {
      getallCompanyoverview();
    }, [props.com_id, props.mat_category]);

    const getallCompanyoverview = async (value) => {
      var data = {
        limit: limit,
        page: page,
        filter: "",
        filtercategory: props.mat_category
          ? props.mat_category
          : "compressor" /* filtercategory */,
        /*    filtercompany: props.com_id ? props.com_id : "", */
        filtercompany: props.com_id ? props.com_id : "",
        search: "",
        between: between,
        sort: "mat_name ASC",
        group: "mat_name",
      };
      return await analyticsService
        .getallcompanyStacklist(data)
        .then((res) => {
          console.log(res.data.result.data);
          if (res.data.result) {
            setState(res.data.result.data);
          } else {
            setState([]);
          }
        })
        .catch((err) => {
          setState([]);
        });
    };
    if (props.com_id) {
      return (
        <>
          {state != null ? (
            <>
              {state.map((data, index) => (
                <td className="text-center pt-3" key={index}>
                  <div style={{ minWidth: 200 }}>
                    <span className="">
                      <span className="p-2 ps-3 pe-3">{data.mat_in_qty}</span>
                      <span className="p-2 ps-3 pe-3">{data.mat_out_qty}</span>
                      <span className="badge bg-light shadow-sm text-dark border  p-2 ps-4 pe-4">
                        {data.mat_in_qty - data.mat_out_qty}
                      </span>
                    </span>
                  </div>
                </td>
              ))}
            </>
          ) : (
            <></>
          )}
        </>
      );
    } else {
      return (
        <>
          {state != null ? (
            <>
              {state.map((data, index) => (
                <th className="text-center" key={index}>
                  <div>{data.mat_name}</div>
                  <div style={{ fontWeight: 500, color: "gray" }}>
                    In | Out | Bal
                  </div>
                </th>
              ))}
            </>
          ) : (
            <></>
          )}
        </>
      );
    }
  };
  // Call appoinment clicks to request another page.
  if (ismessage == "Network Error") {
    return <Alertmsg alertmsg={ismessage} type={iserror} />;
  }
  /* if (isLoaded == true) {
    return <Loader />
  } */

  return (
    <div className="">
      {/*  container */}
      <div className="appoinment-container">
        <div className="flex-fill mb-3">
          <h2>Company Usage</h2>
        </div>
        <div className="pb-4 d-flex  flex-wrap">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            {/*  <li className="nav-item" role="presentation">
              <button
                className={
                  searchParams.get("category") === undefined ||
                  searchParams.get("category") === null
                    ? "nav-link active text-capitalize"
                    : "nav-link text-capitalize"
                }
                onClick={() => {
                  setFiltercategory("");
                  navigate("/companystate");
                }}
                type="button"
                role="tab"
                aria-selected="true"
              >
                All
              </button>
            </li> */}
            {categorylist.map((categorylist, index) => (
              <li key={index} className="nav-item" role="presentation">
                <button
                  className={
                    categorylist.category_name === "compressor" &&
                    searchParams.get("category") === null
                      ? "nav-link active text-capitalize"
                      : searchParams.get("category") ===
                        categorylist.category_name
                      ? "nav-link active text-capitalize"
                      : "nav-link text-capitalize"
                  }
                  onClick={() =>
                    navigate(
                      "/companystate?category=" + categorylist.category_name
                    )
                  }
                  type="button"
                  role="tab"
                  aria-selected="true"
                >
                  {categorylist.category_name}
                </button>
              </li>
            ))}
          </ul>
        </div>
        {/* tebale header part search,filter,sort */}
        <div className="d-md-flex  mb-2 ">
          {/* create appoinment */}
          {/*  <div className="m-1">
            <button
              onClick={() => {
                navigate("/appoinmentbooking");
              }}
              type="submit"
              className="btn btn-primary  w-100"
            >
              <i className="fa fa-plus-circle"></i> Create
            </button>
          </div> */}
          {/* filter status*/}
          <div className="dropdown m-1">
            <button
              className="btn btn-primary w-100 dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fa fa-filter" aria-hidden="true"></i> Filter
            </button>
            <ul
              className="dropdown-menu p-2"
              aria-labelledby="dropdownMenuButton1"
            >
              <li className="text-secondary pb-1 pt-1">Status</li>
              {statusfilterarr.map((statusfilterarr, index) => (
                <li key={index}>
                  <div className="form-check">
                    <input
                      onChange={(e) => filterfn(statusfilterarr.status_name)}
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    ></input>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      {statusfilterarr.status_name}
                    </label>
                  </div>
                </li>
              ))}
              <li className="text-secondary pb-1 pt-1">Role</li>
              {rolefilterarr.map((rolefilterarr, index) => (
                <li key={index}>
                  <div className="form-check">
                    <input
                      onChange={(e) => filterrolefn(rolefilterarr.status_name)}
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    ></input>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      {rolefilterarr.status_name}
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          {/* search */}
          <div className=" m-1">
            <input
              className="form-control"
              onChange={searchfn}
              placeholder="search"
              type="search"
            ></input>
          </div>
          {/*  date range  */}
          <div className="m-1"></div>
          {/* between */}

          {/*  sort */}
          <div className="m-1 ms-auto ">
            <div className="">
              <button
                className="btn bg-white dropdown-toggle"
                id="dropdownMenuClickableInside"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false"
              >
                <i className="fa fa-calendar" aria-hidden="true"></i> {between}
                {between ? (
                  <a
                    className="ms-2 me-2 p-1 text-secondary"
                    onClick={resetdaterange}
                  >
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </a>
                ) : (
                  <></>
                )}
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuClickableInside"
              >
                <DateRangePicker
                  ranges={[selectionRange]}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={1}
                  direction="horizontal"
                  onChange={(date) => handleSelectdate(date)}
                />
              </ul>
            </div>
            <div className="input-group d-none">
              <span
                className="input-group-text bg-white border-end-0"
                id="basic-addon1"
              >
                Sort :{" "}
              </span>
              <select
                value={sort}
                onChange={(e) => {
                  setSort(e.target.value);
                }}
                className="form-select border-start-0"
                aria-label="emp_status"
              >
                <option value="" disabled>
                  Select
                </option>
                <option value="appt_date">Appoinment date</option>
                <option value="appt_category">Category</option>
                <option value="">Clear</option>
              </select>
            </div>
          </div>
        </div>
        {/* table section*/}
        <div className="table-responsive  border-0 mb-2">
          {/*  all */}
          <table className="table table-hover ">
            <thead>
              <tr>
                <th>#</th>
                <th scope="col">
                  <div>name</div>
                  <div></div>
                </th>
                <MovingComponent
                  com_id=""
                  mat_category={
                    searchParams.get("category") === null ||
                    searchParams.get("category") === undefined
                      ? "compressor"
                      : searchParams.get("category")
                  }
                />
                {/*  <th style={{ width: 100 }} scope="col">
                  Action
                </th> */}
              </tr>
            </thead>
            <tbody>
              {/* table data */}
              {companys ? (
                <>
                  {companys.map((data, index) => (
                    <tr key={index}>
                      <td>
                        <div>
                          <img
                            style={{
                              width: 40,
                              height: 40,
                              borderRadius: "50%",
                            }}
                            src={imagename ? imagename : imagename}
                          />
                        </div>
                      </td>
                      <td style={{ minWidth: 150 }} className="text-capitalize">
                        {data.com_fullname}
                      </td>
                      <MovingComponent
                        com_id={data.com_id}
                        mat_category={
                          searchParams.get("category") === null ||
                          searchParams.get("category") === undefined
                            ? "compressor"
                            : searchParams.get("category")
                        }
                      />
                      {/*  {getallCompanyoverview(data.emp_username).map(
                          (materialdata, index) => (
                            <td key={index}>{materialdata.mat_name}</td>
                          )
                        )} */}

                      {/* <td className="p-1 m-0 ">
                        <Link
                          className="btn btn-light me-2"
                          to={"/appoinmentbooking?appt_id=" + data.emp_id}
                        >
                          <i className="fa fa-eye" aria-hidden="true"></i>
                        </Link>
                      </td> */}
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={10} className="text-center">
                    <Alertmsg alertmsg={ismessage} />
                  </td>
                </tr>
              )}
            </tbody>
            <caption className="">
              <div className="d-flex">
                <div className="flex-fill"></div>
                <div className="p-1 "> Items per page </div>
                <div>
                  {" "}
                  <select
                    value={limit}
                    onChange={(e) => {
                      setLimit(e.target.value);
                      setPage(1);
                    }}
                    className="form-select form-select-sm"
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                  </select>
                </div>
                <div className="p-1 me-4">
                  {page * limit - limit}-{page * limit} of {resultCount}
                </div>
                <ReactPaginate
                  previousLabel="< Prev"
                  nextLabel="Next >"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  pageCount={pagecount}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={3}
                  onPageChange={paginationfn}
                  containerClassName="pagination"
                  activeClassName="actives"
                  disabledLinkClassName="text-secondary"
                  forcePage={page - 1}
                />
              </div>
            </caption>
          </table>

          <div className="text-center">
            {/*  loader and error handler */}
            {/*  {<Toastmessage ref={ref} type={error} errormsg={ismessage} />} */}
            {isLoaded ? <Loader /> : <></>}
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Companystate;
