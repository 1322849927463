import React from 'react'
import Category from './Category'
import Company from './Company'
import Material from './Material'

const Categorysetup = () => {
    return (
        <div className='container'>
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Company</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Category</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-materials-tab" data-bs-toggle="pill" data-bs-target="#pills-materials" type="button" role="tab" aria-controls="pills-materials" aria-selected="false">Materials</button>
                </li>
            </ul>
            <div className="tab-content " id="pills-tabContent">
                <div className="tab-pane fade show active border-0" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <Company/>
                </div>
                <div className="tab-pane fade border-0" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <Category/>
                </div>
                <div className="tab-pane fade border-0" id="pills-materials" role="tabpanel" aria-labelledby="pills-materials-tab">
                    <Material/>
                </div>
            </div>
        </div>
    )
}

export default Categorysetup