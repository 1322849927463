import React, { useState } from "react";
import {
    CropperRef,
    RectangleStencil,
    CircleStencil,
    Cropper,
} from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";
const defaultSrc =
    "https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg";
const Reactadvcrop = (props, { ratio, stencil }) => {
    const [aspectRatio, setAspectRatio] = useState(4 / 4);
    const [stencilComponent, setStencilComponent] = useState(RectangleStencil);

    const [image, setImage] = useState(defaultSrc);
    const [croppedimage, setCroppedimage] = useState();

    const onChange = (cropper) => {
        /*   console.log(cropper.getCoordinates(), cropper.getCanvas().toDataURL()); */
        if (cropper) {
            /* console.log(cropper.getCanvas()?.toDataURL("image/png")); */
             console.log(cropper.getCoordinates(), cropper.getCanvas());
            cropper.getCanvas()?.toDataURL("image/png");
            setCroppedimage(cropper.getCanvas()?.toDataURL("image/png"));
         
            const formData = new FormData();
            formData.append("file", cropper.getCanvas()?.toDataURL("image/png"));
            /* setCroppedimage(formData); */
            // console.log(formData);
        }
    };

    return (
        <div className="container">
            <div className="row shadow p-2">
                <div style={{ background: '#DFDFDF' }} className="col-md-8 p-0 m-0">
                    <div>
                        <Cropper
                            stencilComponent={stencilComponent}
                            /* CircleStencil , RectangleStencil */
                            stencilProps={{
                                aspectRatio: aspectRatio,
                                movable: true,
                                resizable: true,
                            }}
                            src={image}
                            onChange={onChange}
                            className={"cropper"}
                        />
                    </div>
                    <div className="border">
                        <input type="file" onChange={onChange} />
                    </div>
                </div>
                <div className="col-md-4">
                    <div>
                        <img src={croppedimage} style={{ width: "100%" }} />
                    </div>
                    <div className="d-flex justify-content-between p-2">
                        <button data-bs-dismiss="modal" className="btn bt-light">Cancel</button>
                        <button onClick={() => props.newFunc(croppedimage)} className="btn btn-primary">Save</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Reactadvcrop