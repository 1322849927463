import React from 'react'
const columnsdata = [
    { id: '283672', name: 'thamizjh', username: 'tam', phone: '343545', email: 'tam@gmail.com', website: 'hello.com' },
    { id: '283665772', name: 'trdf', username: 'tdfam', phone: '343545dfdf', email: 'fgds@gmail.com', website: 'dfgrfg.com' },
    { id: '2834543672', name: 'uffdfd', username: 'tadfm', phone: '343545df', email: 'fgfg@gmail.com', website: 'fdggfd.com' }
]
const newArray = columnsdata.map(({ id, phone, ...keepAttrs }) => keepAttrs)
const Datatable = () => {

    return (
        <div className='container datatable-container'>
            <table className="table bg-white">
                <thead>
                    <tr className='text-capitalize' key={"header"}>
                        {Object.keys(newArray[0]).map((key) => (
                            <th>{key}</th>
                        ))}
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {newArray.map((newArray) => (
                        <tr key={newArray.id} >{Object.values(newArray).map((val) => (
                            <td className={val === 'tam' ? 'activestatus' : ''}>{val}</td>
                        ))}
                            <td><a className='btn btn-primary m-0 pt-0 pb-0 '>view</a></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default Datatable