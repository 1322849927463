import axios from "axios";
import { serverURL, headeroption, headeroptionupload } from "../http-common";

class InventoryService {
  getallInventory(data) {
    return axios.get(serverURL + "/inventory-api?req=smarttableInventory", {
      params: data,
      headers: headeroption,
    });
  }
  getallInventoryjoin(data) {
    return axios.get(
      serverURL + "/inventory-api?req=smarttablejoinInventory",
      {
        params: data,
        headers: headeroption,
      }
    );
  }
  getallInventoryemp(data) {
    return axios.get(
      serverURL + "/inventory-api?req=smarttableInventoryemp",
      {
        params: data,
        headers: headeroption,
      }
    );
  }
  getallInventoryempdata(data) {
    return axios.get(
      serverURL + "/inventory-api?req=smarttableInventorydata",
      {
        params: data,
        headers: headeroption,
      }
    );
  }

  getInventory(receipt_id) {
    return axios.get(serverURL + "/inventory-api?req=getInventory", {
      params: { receipt_id: receipt_id },
      headers: headeroption,
    });
  }
  searchInventory(search_customer, filter_appt_category) {
    return axios.get(serverURL + "/inventory-api?req=searchInventory", {
      params: {
        search_customer: search_customer,
        filter_appt_category: filter_appt_category,
      },
      headers: headeroption,
    });
  }
  createInventory(data) {
    return axios.post(
      serverURL + "/inventory-api?req=createInventory",
      data,
      {
        headers: headeroption,
      }
    );
  }
  updateInventory(data) {
    return axios.put(
      serverURL + "/inventory-api?req=updateInventory",
      data,
      {
        headers: headeroption,
      }
    );
  }
  deleteInventory(receipt_id) {
    return axios.delete(serverURL + "/inventory-api?req=deleteInventory", {
      params: { receipt_id: receipt_id },
      headers: headeroption,
    });
  }
  deleteAll() {
    return axios.delete(serverURL + "/inventory-api?req=deleteInventory");
  }
  filterInventorybydateandteam(filter_receipt_team, filter_receipt_date) {
    return axios.get(
      serverURL + "/inventory-api?req=filterInventorybydateandteam",
      {
        params: {
          filter_receipt_team: filter_receipt_team,
          filter_receipt_date: filter_receipt_date,
        },
        headers: headeroption,
      }
    );
  }
  allInventoryconnectlist(filter_appt_connect) {
    return axios.get(
      serverURL + "/inventory-api?req=allInventoryconnectlist",
      {
        params: {
          filter_appt_connect: filter_appt_connect,
        },
        headers: headeroption,
      }
    );
  }

  findByTitle(emp_name) {
    return axios.get(serverURL + "/employee/inventory-api?name=" + emp_name);
  }
  upload(appt_id, data) {
    return axios.post(
      serverURL + "/inventory-api/uploadsinglefile?appt_id=" + appt_id,
      data,
      { headers: headeroptionupload }
    );
  }
  uploads(appt_id, data) {
    return axios.post(
      serverURL + "/inventory-api/uploadmultifile?appt_id=" + appt_id,
      data,
      { headers: headeroptionupload }
    );
  }
}
export default new InventoryService();
