import axios from "axios";
import { serverURL, headeroption, headeroptionupload } from "../http-common";

class servicehistoryService {
  getallServicehistory(data) {
    return axios.get(serverURL + "/servicehistory-api?req=smarttableServicehistory", {
      params: data,
      headers: headeroption,
    })
  }
  getServicehistory(mat_his_id) {
    return axios.get(serverURL + "/servicehistory-api?req=getServicehistory", {
      params: { mat_his_id: mat_his_id }, headers: headeroption,
    });
  }
  createServicehistory(data) {
    return axios.post(serverURL + "/servicehistory-api?req=createServicehistory", data, {
      headers: headeroption,
    });
  }
  updateServicehistory(data) {
    return axios.put(serverURL + "/servicehistory-api?req=updateServicehistory", data, {
      headers: headeroption,
    });
  }
  updateServicehistorybyapptid(data) {
    return axios.put(serverURL + "/servicehistory-api?req=updateServicehistorybyapptid", data, {
      headers: headeroption,
    });
  }
  deleteServicehistory(mat_his_id) {
    return axios.delete(serverURL + "/servicehistory-api?req=deleteServicehistory", { params: { mat_his_id:mat_his_id }, headers: headeroption, });
  }

  deleteServicehistorybyapptid(receipt_id) {
    return axios.delete(serverURL + "/servicehistory-api?req=deleteServicehistorybyapptid", { params: { receipt_id: receipt_id }, headers: headeroption, });
  }
  deleteAll() {
    return axios.delete(serverURL + "/servicehistory-api?req=deleteServicehistory");
  }
  findByTitle(ser_name) {
    return axios.get(serverURL + "/servicehistory-api?name=" + ser_name);
  }
  upload(ser_his_id, data) {
    return axios.post(serverURL + "/servicehistory-api/uploadsinglefile?ser_his_id=" + ser_his_id, data, { headers: headeroptionupload });
  }
  uploads(ser_his_id, data) {
    return axios.post(serverURL + "/servicehistory-api/uploadmultifile?ser_his_id=" + ser_his_id, data, { headers: headeroptionupload });
  }
}
export default new servicehistoryService();
