import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useFormik } from 'formik';
import userService from '../services/user.service';
import { Uservalidationschema } from '../components/Validation';
import ReactPaginate from 'react-paginate';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { format } from "date-fns";
import { useNavigate } from 'react-router-dom';
import Alertmsg from '../components/Alertmsg';
import { useRef } from 'react';
import Loader from '../components/Loader';
import Toastmessage from '../components/Toastmessage';
const Usersmarttableinlineform = () => {
    const ref = useRef();
    let navigate = useNavigate();
    const [iserror, setIserror] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [ismessage, setIsmessage] = useState(false);

    const [eventname, setEventname] = useState('create')

    const [users, setUsers] = useState([]);
    const [result, setResult] = useState([]);
    //smart table data
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [filter, setFilter] = useState("");
    const [search, setSearch] = useState("");
    const [between, setBetween] = useState("");
    const [sort, setSort] = useState("");
    const [group, setGroup] = useState("");

    const [pagecount, setPagecount] = useState('');
    const [resultCount, setResultcount] = useState('');

    //filter arrays
    const statusfilterarr = [{ status_id: '01', status_name: 'pending' }, { status_id: '02', status_name: 'active' }, { status_id: '03', status_name: 'inactive' }]
    const [filterarray, setFilterarray] = useState(statusfilterarr)
    //date range 
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const selectionRange = {
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
    }
    const handleSelectdate = async (date) => {
        await setStartDate(date.selection.startDate);
        await setEndDate(date.selection.endDate);
        setBetween(format(date.selection.startDate, 'yyyy-MM-dd') + ' , ' + format(date.selection.endDate, 'yyyy-MM-dd'))
    };
    const resetdaterange = (() => {
        setBetween('');
        setStartDate(new Date());
        setEndDate(new Date())
    })
    //form initial value
    const formik = useFormik({
        initialValues: {
            user_id: '',
            user_firstname: '',
            user_lastname: '',
            user_email: '',
            user_status: '',
        },
        validationSchema: Uservalidationschema,
        /*  validateOnChange: false,
         validateOnBlur: false, */
        onSubmit: (values, { resetForm }) => {
            //alert(JSON.stringify(values, null, 1));
            /* resetForm({ values: '' }) */
           
            resetForm()
        }
    });
    //use effects
    useEffect(() => {
        getallUser();
    }, []);
    useLayoutEffect(() => {
        getallUser();
    }, [page, limit, filter, search, between, sort])

    //current event
    const userEvent = (eventname, value) => {
        if (value != '') {
            getUser(value);
            setEventname(eventname)
        }
        setEventname(eventname)
    }
    //get alluser
    const getallUser = async () => {
        setIsLoaded(true);
        var data = {
            limit: limit,
            page: page,
            filter: filter,
            search: search,
            between: between,
            sort: sort,
            group: group,
        };
        return await userService.getallUser(data).
            then(
                (res) => {
                    if (res.data.result) {
                        setIsLoaded(false);
                        // setIserror(res.data.status);
                        //setIsmessage(res.data.message);
                        setUsers(res.data.result.data);
                        setResult(res.data);
                        setPage(res.data.result.page);
                        setLimit(res.data.result.limit);
                        setPagecount(res.data.result.pagecount);
                        setResultcount(res.data.result.resultcount);
                        console.log(res.data);
                    } else {
                        setIserror(res.data.status);
                        setIsmessage(res.data.message);
                        setIsLoaded(false);
                    }
                }
            ).catch(
                (err) => {
                    setIserror(true);
                    setIsmessage(err.message);
                    setIsLoaded(false);
                });
    }
    //pagination
    const paginationfn = (event) => {
        setPage(event.selected + 1);
        console.log(event.selected + 1);
    };
    //search
    const searchfn = (e) => {
        setPage(1);
        setSearch(e.target.value);
    };
    //filter
    const [filterArr, setfilterArr] = useState([]);
    const filterfn = (value) => {
        setPage(1);
        var index = filterArr.indexOf(value);
        if (index > -1) {
            filterArr.splice(index, 1);
            setFilter(filterArr.toString());
        } else {
            filterArr.push(value);
            setFilter(filterArr.toString());
        }
    };
    //sort
    const sortfn = (value) => {
        /*    setSort(value); */
        if (sort == value + " desc") {
            setSort(value + " asc");
            console.log(value + " asc");
        } else if (sort == value + " asc") {
            setSort("");
            console.log(value + "");
        } else {
            setSort(value + " desc");
            console.log(value + " desc");
        }
    };
    //between
    const [betweenstart, setBetweenstart] = useState("");
    const [betweenend, setBetweenend] = useState("");
    const betweenfn = (value, e) => {
        setPage(1);
        if (e === null || e === '') {
            setBetween("");
            setBetweenstart("");
            setBetweenend("");
        } else {
            if (value == "start") {
                setBetween(e + "," + e);
                console.log(e + "," + e);
                setBetweenstart(e);
                setBetweenend(e);
            } else if (value == "end") {
                setBetweenend(e);
                const dd = between.split(",");
                setBetween(dd[0] + "," + e);
                console.log(dd[0] + "," + e);
                setBetweenstart(dd[0]);
                setBetweenend(e);
            } else {
                setBetween("");
                setBetweenstart("");
                setBetweenend("");
            }
        }
    };
    //get user
    const getUser = (value) => {
        setIserror(null)
        setIsmessage(null)
        setIsLoaded(true);
        userService.getUser(value).
            then(
                (res) => {
                    if (res.data.result) {
                        console.log(res.data);
                        setIsLoaded(false);
                        let data = res.data.result;
                        formik.setValues({
                            user_id: data.user_id,
                            user_firstname: data.user_firstname,
                            user_lastname: data.user_lastname,
                            user_status: data.user_status,
                            user_email: data.user_email == null ? '' : data.user_email,
                        });
                    } else {
                        setIserror(res.data.status);
                        setIsmessage(res.data.message);
                        setIsLoaded(false);
                    }
                }

            ).catch((err) => {
                setIserror(true);
                setIsmessage(err.message);
                setIsLoaded(false);
            });
    }
    //create user
    const createUser = () => {
        setIserror(null)
        setIsmessage(null)
        setIsLoaded(true);
        userService.createUser(formik.values).
            then(
                (res) => {
                    if (res.data.status) {
                        console.log(res.data);
                        setIsLoaded(false);
                        setIserror(res.data.status);
                        setIsmessage(res.data.message);
                        getallUser();
                        ref.current.log()
                    } else {
                        setIserror(res.data.status);
                        setIsmessage(res.data.message);
                        setIsLoaded(false);
                        ref.current.log()
                    }
                }
            ).catch((err) => {
                setIserror(true);
                setIsmessage(err.message);
                setIsLoaded(false);
                ref.current.log()
            });
    }

    //update user
    const updateUser = (value) => {
        console.log(JSON.stringify(formik.values, null, 2));
        userService.updateUser(formik.values).
            then(
                (res) => {
                    if (res.data.status) {
                        console.log(res.data);
                        setIsLoaded(false);
                        setIserror(res.data.status);
                        setIsmessage(res.data.message);
                        ref.current.log();
                        getallUser();
                    } else {
                        setIserror(res.data.status);
                        setIsmessage(res.data.message);
                        setIsLoaded(false);
                        ref.current.log()
                    }
                }

            ).catch((err) => {
                setIserror(true);
                setIsmessage(err.message);
                setIsLoaded(false);
                ref.current.log()
            });
    }
    //delete user
    const deleteUser = (value) => {
        console.log(value);
        userService.deleteUser(String(value)).
            then(
                (res) => {
                    if (res.data.status) {
                        console.log('succes');
                        setIsLoaded(false);
                        setIserror(res.data.status);
                        setIsmessage(res.data.message);
                        getallUser();
                        ref.current.log()
                    } else {
                        setIserror(res.data.status);
                        setIsmessage(res.data.message);
                        setIsLoaded(false);
                        ref.current.log();
                    }
                }
            ).catch((err) => {
                setIserror(true);
                setIsmessage(err.message);
                setIsLoaded(false);
                ref.current.log()
            });
    }
    // Call user clicks to request another page.  
    if (ismessage == 'Network Error') {
        return <Alertmsg alertmsg={ismessage} type={iserror} />
    }
    return (
        <div className="">
            <div className=''>
                {/*  loader and error handler */}
                {<Toastmessage ref={ref} type={iserror} errormsg={ismessage} />}
                {isLoaded ? <Loader /> : <></>}
            </div>
            {/*  container */}
            <div className="user-container">
                <div className="d-md-flex  mb-2">
                    {/* create user */}
                    <div className='m-1'>
                        <button
                            onClick={formik.handleReset}
                            type="submit"
                            className="btn btn-primary  w-100"
                        >
                            <i className="fa fa-plus-circle"></i> Create
                        </button>
                    </div>
                    {/* filter status*/}
                    <div className="dropdown m-1">
                        <button
                            className="btn btn-primary w-100 dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="fa fa-filter" aria-hidden="true"></i> Filter
                        </button>
                        <ul
                            className="dropdown-menu p-2"
                            aria-labelledby="dropdownMenuButton1"
                        ><li className='text-secondary'>User Status</li>
                            {filterarray.map((filterdata, index) => (
                                <li key={index}>
                                    <div className="form-check">
                                        <input
                                            onChange={(e) => filterfn(filterdata.status_name)}
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="flexCheckDefault"
                                        ></input>
                                        <label
                                            className="form-check-label"
                                            htmlFor="flexCheckDefault"
                                        >
                                            {filterdata.status_name}
                                        </label>
                                    </div>
                                </li>

                            ))}

                        </ul>
                    </div>
                    {/* search */}
                    <div className=" m-1">
                        <input
                            className="form-control"
                            onChange={searchfn}
                            placeholder="search"
                            type="search"
                        ></input>
                    </div>
                    {/*  date range  */}
                    <div className='m-1'>
                        <div className="">
                            <button className="btn bg-white dropdown-toggle" id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                                <i className="fa fa-calendar" aria-hidden="true"></i> {between}{between ? <a className='ms-2 me-2 p-1 text-secondary' onClick={resetdaterange}><i className="fa fa-times" aria-hidden="true"></i></a> : <></>}
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuClickableInside">
                                <DateRangePicker
                                    ranges={[selectionRange]}
                                    onChange={(date) => handleSelectdate(date)}
                                />
                            </ul>
                        </div>
                    </div>
                    {/* between */}
                    {/*  <div className="m-1">
                        <input
                            id="betweenstart"
                            className="form-control"
                            value={betweenstart}
                            onChange={(e) => betweenfn("start", e.target.value ? e.target.value : '')}
                            placeholder="start"
                            type="date"
                        ></input>
                    </div>
                    <div className="m-1">
                        <input
                            id="betweenend"
                            className="form-control"
                            value={betweenend}
                            onChange={(e) => betweenfn("end", e.target.value)}
                            placeholder="start"
                            type="date"
                        ></input>
                    </div> */}
                    {/*  sort */}
                    <div className="m-1 ms-auto">
                        <div className='input-group '>
                            <span className="input-group-text bg-white border-end-0" id="basic-addon1">Sort : </span>
                            <select value={sort} onChange={(e) => { setSort(e.target.value) }} className="form-select border-start-0" aria-label="user_status">
                                <option value='' disabled>Select</option>
                                <option value="user_firstname">Firstname</option>
                                <option value="user_lastname">Lastname</option>
                                <option value="">Clear</option>
                            </select>
                        </div>
                    </div>
                </div>




                {/* table section*/}
                <div className='table-responsive '>
                    <form onSubmit={formik.handleSubmit}  >
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Firstname</th>
                                    <th scope="col">Lastname</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Status</th>
                                    <th style={{ width: 200 }} scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* table form */}
                                <tr>
                                    <th scope="row">x</th>
                                    <td><div className='input-group'>
                                        <input
                                            className='form-control'
                                            placeholder='Enter Firstname'
                                            id="user_firstname"
                                            type="text"
                                            disabled={eventname == 'view' ? true : eventname == 'delete' ? true : false}
                                            {...formik.getFieldProps('user_firstname')}
                                        />
                                    </div>
                                        {formik.touched.user_firstname && formik.errors.user_firstname ? (
                                            <div className='error'>{formik.errors.user_firstname}</div>
                                        ) : null}</td>
                                    <td>
                                        <div className='input-group '>
                                            <input
                                                className='form-control'
                                                placeholder='Enter Lastname'
                                                id="user_lastname"
                                                type="text"
                                                disabled={eventname == 'view' ? true : eventname == 'delete' ? true : false}
                                                {...formik.getFieldProps('user_lastname')}
                                            />
                                        </div>
                                        {formik.touched.user_lastname && formik.errors.user_lastname ? (
                                            <div className='error'>{formik.errors.user_lastname}</div>
                                        ) : null}
                                    </td>
                                    <td>
                                        <div className='input-group '>
                                            <input
                                                className='form-control'
                                                placeholder='Enter Email'
                                                id="user_email"
                                                type="text"
                                                disabled={eventname == 'view' ? true : eventname == 'delete' ? true : false}
                                                {...formik.getFieldProps('user_email')}
                                            />
                                        </div>
                                        {formik.touched.user_email && formik.errors.user_email ? (
                                            <div className='error'>{formik.errors.user_email}</div>
                                        ) : null}
                                    </td>
                                    <td>
                                        <div className='input-group '>
                                            <select id="user_status"  {...formik.getFieldProps('user_status')} className="form-select"
                                                disabled={eventname == 'view' ? true : eventname == 'delete' ? true : false}
                                                aria-label="user_status">
                                                <option value='' disabled>Select status</option>
                                                <option value="pending">Pending</option>
                                                <option value="active">Active</option>
                                                <option value="inactive">Inactive</option>
                                            </select>
                                        </div>
                                        {formik.touched.user_status && formik.errors.user_status ? (
                                            <div className='error'>{formik.errors.user_status}</div>
                                        ) : null}
                                    </td>
                                    <td className='p-1 m-0 '>
                                        {
                                            eventname === 'create' ? <button disabled={!formik.isValid} onClick={() => createUser()} className='btn btn-primary me-2' >Save</button> :
                                                eventname === 'edit' ? <button onClick={() => updateUser(formik.values.user_id)} className='btn btn-primary me-2' >Update</button> :
                                                    eventname === 'delete' ? <button onClick={() => deleteUser(formik.values.user_id)} className='btn btn-danger me-2 '>Delete</button> :
                                                        <button onClick={() => userEvent('create', '')} className='btn btn-primary' >Back</button>
                                        }
                                        <button type='submit' disabled={!formik.dirty}  onClick={() =>{userEvent('create', '');}} className='btn btn-secondary' >Cancel</button>
                                    </td>
                                </tr>
                                {/* table data */}
                                {users ? <>
                                    {users.map((data, index) => (
                                        <tr key={index}>
                                            <td>{data.user_id}</td>
                                            <td>{data.user_firstname}</td>
                                            <td>{data.user_lastname}</td>
                                            <td>{data.user_email}</td>
                                            <td>{data.user_status}</td>
                                            <td className='p-1 m-0'>
                                                <button onClick={() => userEvent('view', data.user_id)} className='btn btn-light me-2 ' ><i className="fa fa-eye" aria-hidden="true"></i>
                                                </button>
                                                <button onClick={() => userEvent('edit', data.user_id)} className='btn btn-light me-2 ' ><i className="fa fa-pencil-square" aria-hidden="true"></i>
                                                </button>
                                                <button onClick={() => userEvent('delete', data.user_id)} className='btn btn-light me-2 ' >
                                                    <i className="fa fa-trash-o" aria-hidden="true"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}</> : <tr><td colSpan={6} className='text-center'>0 data found</td></tr>}

                            </tbody>
                        </table>

                    </form>
                    <div className='d-flex justify-content-end  pt-2'>
                        <div className='p-1 '> Items per page  </div>
                        <div> <select value={limit} onChange={(e) => { setLimit(e.target.value); setPage(1) }} className='form-select form-select-sm'><option value='5'>5</option><option value='10'>10</option><option value='20'>20</option></select></div>
                        <div className='p-1 me-4'>{(page * limit) - limit}-{page * limit} of {resultCount}</div>

                        <ReactPaginate
                            previousLabel="< Prev"
                            nextLabel="Next >"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={pagecount}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={3}
                            onPageChange={paginationfn}
                            containerClassName="pagination"
                            activeClassName="actives"
                            disabledLinkClassName="text-secondary"
                            forcePage={page - 1}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Usersmarttableinlineform