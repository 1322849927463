import React from 'react'

const Notfound = () => {
  return (
    <div className='text-center pt-4 text-secondary'>
      <h1>Oop!</h1>
      <h4>Page Not Found</h4>
      <div><a onClick={()=>{window.history.back()}} className='btn btn-primary'>Go Back</a></div>
    </div>
  )
}

export default Notfound