import axios from "axios";
import { serverURL, headeroption, headeroptionupload } from "../http-common";
import jwt_decode from "jwt-decode";

class authsService {
  userinfo() {
    if (localStorage.getItem("userinfo") !== null) {
      const userData = localStorage.getItem("userinfo");
      // const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9zZ2FpcmNvbi5jb20uc2ciLCJhdWQiOiJodHRwOlwvXC9zZ2FpcmNvbi5jb20uc2ciLCJuYmYiOjE2NzQyMDUxNTcsImlhdCI6MTY3NDIwNTE1MiwiZXhwIjoxNjc0MjA2OTUyLCJyZXN1bHQiOnsidXNlcl9pZCI6MTE3LCJ1c2VyX25hbWUiOiJnaGdoIiwidXNlcl9yb2xlIjoiYWRtaW4ifX0.x3-wZ5tYW2SsrxtfuBOLc-IfJf7Ei-UWlbSMzP-3XsA"
      const userinfo = JSON.parse(userData);

      if (userinfo["token"] !== undefined) {
        const token = userinfo.token;
        const decoded = jwt_decode(token);
        return decoded.result;
      } else {
        const userinfo = {};
        return userinfo;
      }
    } else {
      const userinfo = {};
      return userinfo;
    }
  }
  jwtdecode(token) {
    const decoded = jwt_decode(token);
    return decoded.result;
  }
  userdata() {
    if (localStorage.getItem("userinfo") !== null) {
      const userData = localStorage.getItem("userinfo");
      // const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9zZ2FpcmNvbi5jb20uc2ciLCJhdWQiOiJodHRwOlwvXC9zZ2FpcmNvbi5jb20uc2ciLCJuYmYiOjE2NzQyMDUxNTcsImlhdCI6MTY3NDIwNTE1MiwiZXhwIjoxNjc0MjA2OTUyLCJyZXN1bHQiOnsidXNlcl9pZCI6MTE3LCJ1c2VyX25hbWUiOiJnaGdoIiwidXNlcl9yb2xlIjoiYWRtaW4ifX0.x3-wZ5tYW2SsrxtfuBOLc-IfJf7Ei-UWlbSMzP-3XsA"
      const userinfo = JSON.parse(userData);
      // JWT exp is in seconds
      const decodeds = jwt_decode(userinfo.token);
      const value = decodeds.exp * 1000 > new Date().getTime() ? 1 : 0;

      if (userinfo["token"] !== undefined && value === 1) {
        const token = userinfo.token;
        const decoded = jwt_decode(token);
        return {
          user_role: decoded.result.user_role,
          login_type: decoded.result.login_type,
          token: userinfo.token,
        };
      } else {
        localStorage.clear();
        const userinfo = {};
        return userinfo;
      }
    } else {
      const userinfo = {};
      return userinfo;
    }
  }
  signin(data) {
    return axios.get(serverURL + "/auths-api?req=signin", {
      params: data,
      headers: headeroption,
    });
  }
  signinemp(data) {
    return axios.get(serverURL + "/auths-api?req=signinemp", {
      params: data,
      headers: headeroption,
    });
  }
  signup(data) {
    return axios.post(serverURL + "/auths-api?req=signup", data, {
      headers: headeroption,
    });
  }
  getUserProfile(user_id) {
    return axios.get(serverURL + "/auths-api?req=getUserProfile", {
      params: { user_id: user_id },
      headers: headeroption,
    });
  }
  passwordresetverify(data) {
    return axios.get(serverURL + "/auths-api?req=passwordresetverify", {
      params: data,
      headers: headeroption,
    });
  }
  sentpasswordresetlink(data) {
    return axios.get(serverURL + "/email/passwordresetlink", {
      params: data,
      headers: headeroption,
    });
  }
  updatePassword(data) {
    return axios.put(serverURL + "/auths-api?req=updatepassword", data, {
      headers: headeroption,
    });
  }
  updateUser(data) {
    return axios.put(serverURL + "/users-api?req=updateUser", data, {
      headers: headeroption,
    });
  }
  deleteUser(user_id) {
    return axios.delete(serverURL + "/users-api?req=deleteUser", {
      params: { user_id: user_id },
      headers: headeroption,
    });
  }
  deleteAll() {
    return axios.delete(serverURL + "/users-api?req=deleteUser");
  }
  uploadUser(user_id, data) {
    return axios.post(serverURL + "/uploads-api?req=singlefile", data, {
      params: { user_id: user_id },
      headers: headeroptionupload,
    });
  }
  uploadUsers(brand_id, data) {
    return axios.post(`/uploadmultifile/${brand_id}`, data);
  }
}
export default new authsService();
