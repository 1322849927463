import * as Yup from "yup";
//signin validation form
export const Signinvalidationschema = Yup.object({
  user_email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  user_password: Yup.string().required("Password is required"),
});
export const Signinempvalidationschema = Yup.object({
  emp_username: Yup.string()
    .min(5, "Username must be at least 5 characters")
    .matches(/^[A-Za-z, ]+$/, "Username is invalid only allowed text")
    .required("Username is required"),
  emp_password: Yup.string().required("Password is required"),
});
//resetlink validation form
export const Resetlinkvalidationschema = Yup.object({
  user_email: Yup.string().email("Invalid email address").required("Required"),
  user_name: Yup.string(),
  resetlink: Yup.string(),
});
//password reset validation form
export const Passwordupdatevalidationschema = Yup.object({
  user_email: Yup.string(),
  user_password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(40, "Password must not exceed 40 characters"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("user_password"), null], "Confirm Password does not match"),
});
//signup validation form
export const Signupvalidationschema = Yup.object({
  user_name: Yup.string()
    .min(5, "Must be 5 characters or more")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  user_fullname: Yup.string()
    .min(3, "Must be 3 characters or more")
    .max(20, "Must be 20 characters or less")
    .required("Required"),
  user_email: Yup.string().email("Invalid email address").required("Required"),
  user_password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(40, "Password must not exceed 40 characters"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("user_password"), null], "Confirm Password does not match"),
  acceptTerms: Yup.bool().oneOf([true], "Accept Terms is required"),
});
//user validation form
export const Uservalidationschema = Yup.object().shape({
  user_id: Yup.string(),
  user_firstname: Yup.string().required("Required"),
  user_lastname: Yup.string().required("Required"),
  user_email: Yup.string("Email must be a string")
    .email("Invalid email address")
    .required("Required"),
  user_status: Yup.string(),
});
export const Usersvalidationschema = Yup.object().shape({
  user_id: Yup.string(),
  user_name: Yup.string()
    .min(5, "Must be 5 characters or more")
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  user_fullname: Yup.string()
    .min(3, "Must be 3 characters or more")
    .max(20, "Must be 20 characters or less")
    .required("Required"),
  user_email: Yup.string().email("Invalid email address").required("Required"),
  user_password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters"),
  user_phone: Yup.string(),
  user_address: Yup.string(),
  user_role: Yup.string().required("Required"),
  user_status: Yup.string().required("Required"),
});
//employee validation form
export const Employeevalidationschema = Yup.object().shape({
  emp_id: Yup.string(),
  emp_fullname: Yup.string().required("Required"),
  emp_username: Yup.string()
    .required("Required")
    .min(5, "Username must be at least 6 characters")
    .max(20, "Username must not exceed 20 characters")
    .matches(/^[A-Za-z, ]+$/, "Username is invalid only allowed text"),
  emp_email: Yup.string().email("Invalid email address").required("Required"),
  emp_phone: Yup.string().required("Required"),
  emp_address: Yup.string().required("Required"),
  emp_fin: Yup.string().required("Required"),
  emp_passport: Yup.string(),
  emp_password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(40, "Password must not exceed 40 characters"),
  emp_role: Yup.string().required("Required"),
  emp_status: Yup.string().required("Required"),
});

//company validation form
export const Companyvalidationschema = Yup.object().shape({
  com_id: Yup.string(),
  com_fullname: Yup.string().required("Required"),
  com_username: Yup.string().required("Required"),
  com_role: Yup.string().required("Required"),
  com_address: Yup.string().required("Required"),
  com_status: Yup.string(),
});
//category validation form
export const Categoryvalidationschema = Yup.object().shape({
  category_id: Yup.string(),
  category_name: Yup.string().required("Required"),
  category_type: Yup.string().required("Required"),
  category_status: Yup.string(),
});
//material validation form
export const Materialvalidationschema = Yup.object().shape({
  mat_id: Yup.string(),
  mat_name: Yup.string().required("Required"),
  mat_price: Yup.string(),
  mat_category: Yup.string().required("Required"),
  mat_status: Yup.string().required("Required"),
});

//appoinment service
export const Servicehistoryvalidationschema = Yup.object().shape({
  mat_his_id: Yup.string(),
  receipt_id: Yup.string().required("Required"),
  receipt_type: Yup.string().required("Required"),
  com_id: Yup.string().required("Required"),
  mat_date: Yup.string().required("Required"),
  mat_name: Yup.string().required("Required"),
  mat_category: Yup.string(),
  mat_qty: Yup.number().required("Required"),
  mat_cost: Yup.number(),
  mat_status: Yup.string(),
  team_membera: Yup.string(),
  team_memberb:Yup.string(),
  team_memberc: Yup.string(),
  team_memberd:Yup.string(),
  team_membere: Yup.string(),
  mat_id: Yup.string(),
  createdBy: Yup.string(),
});
//appoinment validation form
export const Inventoryvalidationschema = Yup.object().shape({
  receipt_id: Yup.string(),
  com_id: Yup.string(),
  team_name: Yup.string().required("Required"),
  team_membera: Yup.string(),
  team_memberb: Yup.string(),
  team_memberc: Yup.string(),
  team_memberd: Yup.string(),
  team_membere: Yup.string(),
  receipt_date: Yup.string().required("Required"),
  receipt_type: Yup.string().required("Required"),
  mat_sender: Yup.string().required("Required"),
  mat_receiver: Yup.string().required("Required"),
  mat_sender_address: Yup.string(),
  mat_receiver_address: Yup.string(),
  receipt_status: Yup.string(),
  createdBy: Yup.string(),
});

//GENERAL ALL VALIDATION
export const validationSchema = Yup.object({
  firstName: Yup.string()
    .required("Firstname is required")
    .matches(/^[A-Za-z, ]+$/, "Firstname is invalid only allowed text"),
  lastName: Yup.string().required("Lastname is required"),
  userName: Yup.string()
    .required("Username is required")
    .min(6, "Username must be at least 6 characters")
    .max(20, "Username must not exceed 20 characters"),
  dob: Yup.string().required("Date of Birth is required"),
  gender: Yup.string().required("Gender is required"),
  email: Yup.string().required("Email is required").email("Email is invalid"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(40, "Password must not exceed 40 characters"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
  acceptTerms: Yup.bool().oneOf([true], "Accept Terms is required"),
});
