import React, { useContext, useEffect, useState, useToggle } from "react";
import { Link, Route, Routes, NavLink } from "react-router-dom";
import profileLogo from "../assets/images/user.png";
import Dashboard from "../pages/Dashboard";
import Notfound from "./Notfound";
import Usersmarttableinlineform from "../pages/Usersmarttableinlineform";
import { AuthContext } from "../context/Authcontext";
import Googlecharts from "./Googlecharts";
import Profile from "../pages/Profile";
import Settings from "../pages/Settings";
import Loader from "./Loader";
import Toastmessage from "./Toastmessage";
import Datatable from "./Datatable";
import authsService from "../services/auths.service";
import Usersmarttablewithform from "../pages/Usersmarttablewithform";
import Employee from "../pages/Employee";
import Employeestate from "../pages/Employeestate";
import Usersmarttable from "../pages/Usersmarttable";
import Userevent from "../pages/Userevent";
import Reactcropper from "./Reactcropper";
import Reacteasycrop from "./Reacteasycrop";
import Reactadvcrop from "./Reactadvcrop";
import Reactquill from "./Reactquill";
import Reactpdfrender from "./Reactpdfrender";
import Reactpdfviewer from "./Reactpdfviewer";
import Employeeevent from "../pages/Employeeevent";
import Inventoryevent from "../pages/Inventoryevent";
import Users from "../pages/Users";
import Usersmartevent from "../pages/Usersmartevent";
import Userpermission from "../pages/Userpermission";
import Categorysetup from "../pages/Categorysetup";
import Inventory from "../pages/Inventory";
import Inventorybooking from "../pages/inventorybooking";
import Servicehistory from "../pages/Servicehistory";
import Companystate from "../pages/Companystate";
import Category from "../pages/Category";
import Statistics from "../pages/Statistics";
import Material from "../pages/Material";
import Stack from "../pages/Stack";
import Company from "../pages/Company";
import mylogo from "../assets/images/letscoollogowhite.png";
import { Empstatistics } from "../pages/Empstatistics";
import { Comstatistics } from "../pages/Comstatistics";
import { serverURL } from "../http-common";
import Inventoryhome from "../pages/Inventoryhome";
import Employeehome from "../pages/Employeehome";

const Dashboardlayout = () => {
  /* const userinfo = authsService.userinfo(); */
  const userinfo = authsService.userinfo();
  const { signOut } = useContext(AuthContext);
  const [toggleexpand, setToggleexpand] = useState(null);
  const [userpic, setUserpic] = useState("");

  //toggle menu
  const togglemenu = () => {
    let sidebar = document.querySelector(".sidebar");
    let sidebarBtn = document.querySelector(".sidebarBtn");
    sidebar.classList.toggle("active");
    if (sidebar.classList.contains("active")) {
      sidebarBtn.classList.replace("fa-bars", "fa-align-left");
    } else sidebarBtn.classList.replace("fa-align-left", "fa-bars");
  }; /* <i className="fa fa-bars" aria-hidden="true"></i> */
  //use effects
  useEffect(() => {
    getUser(userinfo.user_id);
  }, []);
  //get user
  const getUser = () => {
    authsService
      .getUserProfile(userinfo.user_id)
      .then((res) => {
        if (res.data.result) {
          let data = res.data.result;
          setUserpic(serverURL + "/" + data.user_profile_pic);
        } else {
        }
      })
      .catch((err) => {});
  };
  return (
    <div className="dashboard-layout-container">
      <div className="sidebar">
        <div className="logo-details ps-3">
          {/* <i className='bx bxl-c-plus-plus'></i> */}
          {/* <i className="fa fa-ravelry" aria-hidden="true"></i> */}
          <span className="rounded-circle shadow p-1">
          <img
            
            style={{ width: 40, height: 40 }}
            src={mylogo}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = { mylogo };
            }}
            alt=""
          />
          </span>
          <span className="logo_name text-dark ps-3">LETSCOOL</span>
          <br></br>
        </div>
        <div style={{marginTop:-25,marginLeft:55,fontSize:12,color:"#fff",minWidth:180}}>
          <span className="logo_name ps-4">Inventory Management</span>
        </div>
        <ul className="nav-links">
          <li>
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? "sidebar-nav-active" : "sidebar-nav-links"
              }
            >
              {/*  <i className='bx bx-grid-alt' ></i> */}
              <i className="fa fa-dashboard" aria-hidden="true"></i>
              <span className="links_name">Dashboard</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/inventory"
              className={({ isActive }) =>
                isActive ? "sidebar-nav-active" : "sidebar-nav-links"
              }
            >
              <i className="fa fa-database" aria-hidden="true"></i>
              <span className="links_name">Inventory</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/employeestate"
              className={({ isActive }) =>
                isActive ? "sidebar-nav-active" : "sidebar-nav-links"
              }
            >
              <i className="fa fa-line-chart" aria-hidden="true"></i>
              <span className="links_name">Employee Usage</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/companystate"
              className={({ isActive }) =>
                isActive ? "sidebar-nav-active" : "sidebar-nav-links"
              }
            >
              <i className="fa fa-area-chart" aria-hidden="true"></i>
              <span className="links_name">Company Usage</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Stack"
              className={({ isActive }) =>
                isActive ? "sidebar-nav-active" : "sidebar-nav-links"
              }
            >
              <i className="fa fa-stack-exchange" aria-hidden="true"></i>
              <span className="links_name">Stack</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/statistics"
              className={({ isActive }) =>
                isActive ? "sidebar-nav-active" : "sidebar-nav-links"
              }
            >
              <i className="fa fa-pie-chart" aria-hidden="true"></i>
              <span className="links_name">Statistics</span>
            </NavLink>
          </li>
          <div className="sidenav-submenu">
            <a
              className="sidebar-nav-links"
              data-bs-toggle="collapse"
              href="#sidenavcollapese1"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <i className="fa fa-cog" aria-hidden="true"></i>
              <span className="links_name">Setting </span>
              <span style={{ fontWeight: 700 }} className="ps-4 text-white">
                +{/*   <i className="fa fa-chevron-right"></i> */}
              </span>
            </a>
            <div className="collapse sidenavcollapese" id="sidenavcollapese1">
              <NavLink
                to="/users"
                className={({ isActive }) =>
                  isActive ? "sidebar-nav-active" : "sidebar-nav-links"
                }
              >
                <i className="fa fa-lock" aria-hidden="true"></i>
                <span className="links_name">User</span>
              </NavLink>
              <NavLink
                to="/employee"
                className={({ isActive }) =>
                  isActive ? "sidebar-nav-active" : "sidebar-nav-links"
                }
              >
                <i className="fa fa-users" aria-hidden="true"></i>
                <span className="links_name">Employee</span>
              </NavLink>
              <NavLink
                to="/company"
                className={({ isActive }) =>
                  isActive ? "sidebar-nav-active" : "sidebar-nav-links"
                }
              >
                <i className="fa fa-hand-grab-o" aria-hidden="true"></i>
                <span className="links_name">Company</span>
              </NavLink>
              <NavLink
                to="/category"
                className={({ isActive }) =>
                  isActive ? "sidebar-nav-active" : "sidebar-nav-links"
                }
              >
                <i className="fa fa-crosshairs" aria-hidden="true"></i>
                <span className="links_name">Category</span>
              </NavLink>
              <NavLink
                to="/material"
                className={({ isActive }) =>
                  isActive ? "sidebar-nav-active" : "sidebar-nav-links"
                }
              >
                <i className="fa fa-cubes" aria-hidden="true"></i>
                <span className="links_name">Material</span>
              </NavLink>
              {/*   <NavLink
                to="/categorysetup"
                className={({ isActive }) =>
                  isActive ? "sidebar-nav-active" : "sidebar-nav-links"
                }
              >
                <i className="fa fa-th-list" aria-hidden="true"></i>
                <span className="links_name">Category</span>
              </NavLink> */}
            </div>
          </div>
          <li>
            <NavLink
              to="/sd"
              className={({ isActive }) =>
                isActive ? "sidebar-nav-active" : "sidebar-nav-links d-none"
              }
            >
              {/*  <i className='bx bx-cog' ></i> */}
              <i className="fa fa-cog" aria-hidden="true"></i>
              <span className="links_name">Setting</span>
            </NavLink>
          </li>
          <li className="log_out d-none">
            <NavLink
              onClick={signOut}
              className={({ isActive }) =>
                isActive ? "sidebar-nav-links" : "sidebar-nav-links"
              }
            >
              {/* <i className='bx bx-log-out'></i> */}
              <i className="fa fa-sign-out" aria-hidden="true"></i>
              <span className="links_name">Log out</span>
            </NavLink>
          </li>
        </ul>
      </div>
      <section className="home-section">
        <nav>
          <div onClick={togglemenu} className="sidebar-button">
            {/*  <i className='bx bx-menu sidebarBtn'></i> */}
            <i className="fa fa-bars sidebarBtn" aria-hidden="true"></i>
            <span className="dashboard"></span>
          </div>
          <div className="notify-details">
            {/*  <a href='#'><i className="fa fa-bell" aria-hidden="true"></i></a> */}
            <a href="#" className="notification-container">
              <i className="fa fa-envelope" aria-hidden="true"></i>
              <span className="notification-counter">0</span>
            </a>
            <a href="#" className="notification-container">
              <i className="fa fa-bell" aria-hidden="true"></i>
              <span className="notification-counter">0</span>
            </a>
          </div>
          <div className="profile-details">
            {/* <img src="images/profile.jpg" alt="" /> */}
            <div className="dropdown">
              <a type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <img src={userpic ? userpic : profileLogo} alt="" />
                <span className="admin_name ">{userinfo.user_name}</span>
                <i className="fa fa-chevron-down"></i>
              </a>
              <ul className="dropdown-menu mt-1">
                <li>
                  <NavLink to="/profile" className="dropdown-item">
                    {/* <i className='bx bx-log-out'></i> */}
                    Profile
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/settings" className="dropdown-item">
                    {/* <i className='bx bx-log-out'></i> */}
                    Setting
                  </NavLink>
                </li>
                <li className="">
                  <a role="button" onClick={signOut} className="dropdown-item">
                    Logout
                    <span className="ps-3">
                      {" "}
                      <i className="fa fa-sign-out" aria-hidden="true"></i>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div className="home-content">
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route
              path="usersmarttableinlineform"
              element={<Usersmarttableinlineform />}
            />
            <Route
              path="usersmarttablewithform"
              element={<Usersmarttablewithform />}
            />
            <Route path="usersmarttable" element={<Usersmarttable />} />
            <Route path="usersmartevent" element={<Usersmartevent />} />

            <Route path="reactcropper" element={<Reactcropper />} />
            <Route path="reactquill" element={<Reactquill />} />
            <Route path="reactpdfrender" element={<Reactpdfrender />} />
            <Route path="reactpdfviewer" element={<Reactpdfviewer />} />

            <Route path="reacteasycrop" element={<Reacteasycrop />} />
            <Route path="reactadvcrop" element={<Reactadvcrop />} />
            <Route path="servicehistory" element={<Servicehistory />} />
            <Route path="statistics" element={<Statistics />} />
            <Route path="empstatistics" element={<Empstatistics />} />
            <Route path="comstatistics" element={<Comstatistics />} />

            <Route path="stack" element={<Stack />} />

            <Route path="users" element={<Users />} />
            <Route path="userevent" element={<Userevent />} />
            <Route path="employee" element={<Employeehome />}>
              <Route path="" element={<Employee />} />
              <Route path="employeeevent" element={<Employeeevent />} />
            </Route>

            <Route path="employeestate" element={<Employeestate />} />
            <Route path="category" element={<Category />} />
            <Route path="material" element={<Material />} />
            <Route path="company" element={<Company />} />

            <Route path="companystate" element={<Companystate />} />

            <Route path="inventory" element={<Inventoryhome />}>
              <Route path="" element={<Inventory />} />
              <Route path="inventorybooking" element={<Inventorybooking />} />
              <Route path="inventoryevent" element={<Inventoryevent />} />
            </Route>
            <Route path="googlechart" element={<Googlecharts />} />
            <Route path="/userpermission" element={<Userpermission />} />
            <Route path="/categorysetup" element={<Categorysetup />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/loader" element={<Loader />} />
            <Route path="/toastmessage" element={<Toastmessage />} />
            <Route path="/datatable" element={<Datatable />} />

            <Route path="*" element={<Notfound />} />
          </Routes>
        </div>
      </section>
    </div>
  );
};

export default Dashboardlayout;
