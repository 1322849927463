import React, { useState } from "react";
import { useEffect } from "react";

const Alertmsg = (props) => {
  useEffect(() => {
    console.log(props);
  });
  if (props.type === true) {
    return (
      <div role="alert" className="alert alert-success">
        <i className="fa fa-check-circle-o" aria-hidden="true"></i> &nbsp;&nbsp;
        {props.alertmsg}{props.type}
      </div>
    );
  } else if (props.type === false) {
    return (
      <>
        <div role="alert" className="alert alert-danger">
          <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
          &nbsp;&nbsp;{props.alertmsg}
        </div>
      </>
    );
  } else {
    return (
      <>
        <div role="alert" className="alert alert-transparent">
          &nbsp;&nbsp;{props.alertmsg}
        </div>
      </>
    );
  }
};

export default Alertmsg;
