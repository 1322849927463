import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import inventoryService from "../services/inventory.service";
import { Inventoryvalidationschema } from "../components/Validation";
import ReactPaginate from "react-paginate";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";
import Loader from "../components/Loader";
import Toastmessage from "../components/Toastmessage";
import { useNavigate, useSearchParams } from "react-router-dom";
import Alertmsg from "../components/Alertmsg";
import categoryService from "../services/category.service";
import employeeService from "../services/employee.service";
import imagename from "../assets/images/user.png";
import Servicehistory from "./Servicehistory";
import authsService from "../services/auths.service";
import materialService from "../services/material.service";
import companyService from "../services/company.service";
import servicehistoryService from "../services/servicehistory.service";
import { serverURL } from "../http-common";

const Inventorybooking = () => {
  const userinfo = authsService.userinfo();
  const ref = useRef();
  let [searchParams, setSearchParams] = useSearchParams();

  let navigate = useNavigate();
  const [iserror, setIserror] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [ismessage, setIsmessage] = useState(false);

  const [eventname, setEventname] = useState("create");
  const [receipt_id, setReceiptid] = useState("");

  const [inventorys, setInventorys] = useState([]);
  const [result, setResult] = useState([]);
  //smart table data
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [filter, setFilter] = useState("");
  const [search, setSearch] = useState("");
  const [between, setBetween] = useState("");
  const [sort, setSort] = useState("");
  const [group, setGroup] = useState("");

  const [pagecount, setPagecount] = useState("");
  const [resultCount, setResultcount] = useState("");
  //appt list
  const [apptlist, setApptlist] = useState([]);

  //appt connect list
  const [filterapptconnect, setFilterapptconnect] = useState([]);
  //appt connect list filter_appt_connect
  const [apptconnectlist, setApptconnectlist] = useState([]);
  //company arr
  const [companylist, setCompanylist] = useState([]);
  const [companyvendorlist, setCompanyvendorlist] = useState([]);

  //appt category arr
  const [categorylist, setCategorylist] = useState([]);
  //emp arr
  const [emplist, setEmplist] = useState([]);
  //emp arr
  const [servicinglist, setServicinglist] = useState([]);
  //selected user
  const [selecteduser, setSelecteduser] = useState([]);
  //selected user
  const [appteventhandle, setAppteventhandle] = useState([]);
  //filter arrays
  const statusfilterarr = [
    { status_id: "01", status_name: "pending" },
    { status_id: "02", status_name: "active" },
    { status_id: "03", status_name: "inactive" },
  ];
  const [filterarray, setFilterarray] = useState(statusfilterarr);
  //date range
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  const handleSelectdate = async (date) => {
    await setStartDate(date.selection.startDate);
    await setEndDate(date.selection.endDate);
    setBetween(
      format(date.selection.startDate, "yyyy-MM-dd") +
        " , " +
        format(date.selection.endDate, "yyyy-MM-dd")
    );
  };
  const resetdaterange = () => {
    setBetween("");
    setStartDate(new Date());
    setEndDate(new Date());
  };
  const [sortarr, setSortarr] = useState([]);
  //form initial value
  const formik = useFormik({
    initialValues: {
      receipt_id: "",
      com_id: "",
      team_name: "",
      team_membera: "",
      team_memberb: "",
      team_memberc: "",
      team_memberd: "",
      team_membere: "",
      receipt_date: "",
      receipt_type: "inventoryout",
      mat_sender: "",
      mat_receiver: "",
      mat_sender_address: "",
      mat_receiver_address: "",
      receipt_status: "active",
      createdBy: userinfo.user_name,
    },
    validationSchema: Inventoryvalidationschema,
    enableReinitialize: true,

    /*  validateOnChange: false,
         validateOnBlur: false, */
    onSubmit: (values, { resetForm }) => {
      //alert(JSON.stringify(values, null, 1));
      // resetForm(values, '');
      console.log("hello");
    },
  });
  //use effects
  useEffect(() => {
    formik.setFieldValue("receipt_date", new Date().toISOString().slice(0, 10));
    console.log("username", userinfo.user_name);
    getallcompany();
    getallcompanyvendor();
    getallemployee(); /*  getallservicing(); */
    if (searchParams.get("receipt_id")) {
      setEventname("view", "");
    } else {
      setEventname("create", "");
    }
    /* selectuser(userinfo.user_name); */
  }, []);

  /*   useLayoutEffect(() => {
          getallservicing(formik.values.appt_category);
          console.log(formik.values.appt_category);
      }, [formik.values.appt_category]) */
  /*   useLayoutEffect(() => {
    allinventorybydateandteam();
  }, [selecteduser]); */
  useLayoutEffect(() => {
    companyonchange(formik.values.com_id);
  }, [formik.values.com_id]);

  useLayoutEffect(() => {
    setEventname("view");

    if (searchParams.get("receipt_id")) {
      console.log(searchParams.get("receipt_id"));
      getInventory(searchParams.get("receipt_id"));
    } else {
      console.log("nodata available");
    }
  }, [searchParams.get("receipt_id")]);

  const [apptarr, setApparr] = useState([]);
  const [search_customer, setSearch_customer] = useState([]);
  //current event
  const inventoryEvent = (eventname, value) => {
    setIserror(true);
    /*     setIsmessage("Access denied");
    ref.current.log(); */
    /*  alert('Currently disabled'); */
    if (value != "") {
      getInventory(value);
      setEventname(eventname);
    }
    setEventname(eventname);
  };
  //get allinventory

  //pagination
  const paginationfn = (event) => {
    setPage(event.selected + 1);
    console.log(event.selected + 1);
  };
  //search
  const searchfn = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };
  //filter
  const [filterArr, setfilterArr] = useState([]);
  const filterfn = (value) => {
    setPage(1);
    var index = filterArr.indexOf(value);
    if (index > -1) {
      filterArr.splice(index, 1);
      setFilter(filterArr.toString());
    } else {
      filterArr.push(value);
      setFilter(filterArr.toString());
    }
  };
  //sort
  const sortfn = (value) => {
    /*    setSort(value); */
    if (sort == value + " desc") {
      setSort(value + " asc");
      console.log(value + " asc");
    } else if (sort == value + " asc") {
      setSort("");
      console.log(value + "");
    } else {
      setSort(value + " desc");
      console.log(value + " desc");
    }
  };
  //between
  const [betweenstart, setBetweenstart] = useState("");
  const [betweenend, setBetweenend] = useState("");
  const betweenfn = (value, e) => {
    setPage(1);
    if (e === null || e === "") {
      setBetween("");
      setBetweenstart("");
      setBetweenend("");
    } else {
      if (value == "start") {
        setBetween(e + "," + e);
        console.log(e + "," + e);
        setBetweenstart(e);
        setBetweenend(e);
      } else if (value == "end") {
        setBetweenend(e);
        const dd = between.split(",");
        setBetween(dd[0] + "," + e);
        console.log(dd[0] + "," + e);
        setBetweenstart(dd[0]);
        setBetweenend(e);
      } else {
        setBetween("");
        setBetweenstart("");
        setBetweenend("");
      }
    }
  };
  //get inventory
  const getInventory = (value) => {
    /*   setIserror(null)
          setIsmessage(null) */
    setIsLoaded(true);
    inventoryService
      .getInventory(value)
      .then((res) => {
        if (res.data.result) {
          console.log(res.data);
          setIsLoaded(false);
          let data = res.data.result;
          const myArray = data.team_name.split(",");
          setSelecteduser(myArray);
          setSortarr(myArray);
          setReceiptid(data.receipt_id);
          var dddd = companyvendorlist
            .filter((x) => x.com_username === data.mat_sender)
            .map((x) => x.com_id);
          /* setVendorname(dddd[0]); */
          console.log(dddd);
          setVendorname(dddd[0]);
          formik.setValues({
            receipt_id: data.receipt_id,
            com_id: data.com_id,
            team_name: data.team_name,
            team_membera: data.team_membera,
            team_memberb: data.team_memberb,
            team_memberc: data.team_memberc,
            team_memberd: data.team_memberd,
            team_membere: data.team_membere,
            receipt_date: data.receipt_date,
            receipt_type: data.receipt_type,
            mat_sender: data.mat_sender,
            mat_receiver: data.mat_receiver,
            mat_sender_address: data.mat_sender_address,
            mat_receiver_address: data.mat_receiver_address,
            receipt_status: data.receipt_status,
            createdBy: userinfo.user_name,
          });
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
      });
  };
  //create inventory
  const createInventory = () => {
    teamformvalue();
    setIserror(null);
    setIsmessage(null);
    setIsLoaded(true);
    inventoryService
      .createInventory(formik.values)
      .then((res) => {
        if (res.data.status) {
          console.log("hhhghghg" + res.data.result);
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setReceiptid(res.data.result[0]);
          navigate(
            "/inventory/inventorybooking?receipt_id=" + res.data.result[0]
          );
          ref.current.log();
        } else {
          console.log(res.data);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
          ref.current.log();
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
        ref.current.log();
      });
  };

  //update inventory
  const updateInventory = (value) => {
    teamformvalue();
    /* formik.setFieldValue('createdBy', 'hello'); */
    setIserror(null);
    setIsmessage(null);
    setIsLoaded(true);
    inventoryService
      .updateInventory(formik.values)
      .then((res) => {
        console.log(res.data);
        if (res.data.status) {
          console.log(res.data.message);
          updateServicehistorybyapptid();
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          ref.current.log();
          setReceiptid(searchParams.get("receipt_id"));
          getInventory(searchParams.get("receipt_id"));
          setEventname("view");
          setAppteventhandle("update");
          formik.handleReset();
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
          ref.current.log();
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
        ref.current.log();
      });
  };
  //delete inventory
  const deleteInventory = (value) => {
    teamformvalue();
    console.log(value);
    inventoryService
      .deleteInventory(String(value))
      .then((res) => {
        if (res.data.status) {
          console.log("succes");
          deleteServicehistorybyapptid(String(value));
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setEventname("create");
          setAppteventhandle("delete");
          navigate("/inventory/inventorybooking");
          formik.handleReset();
          ref.current.log();
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
          ref.current.log();
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
        ref.current.log();
      });
  };
  //category list
  //vendor list
  const getallcompanyvendor = () => {
    var data = {
      limit: 30,
      page: 1,
      filter: "",
      filterrole: "vendor",
      search: "",
      between: "",
      sort: "",
      group: "",
    };
    categoryService
      .getallCompany(data)
      .then((res) => {
        console.log(res.data);
        setCompanyvendorlist(res.data.result.data);
      })
      .catch((err) => {
        setCompanyvendorlist([]);
        console.log(err.message);
      });
  };
  //company list
  const getallcompany = () => {
    var data = {
      limit: 30,
      page: 1,
      filter: "",
      filterrole: "main",
      search: "",
      between: "",
      sort: "",
      group: "",
    };
    categoryService
      .getallCompany(data)
      .then((res) => {
        console.log(res.data);
        setCompanylist(res.data.result.data);
      })
      .catch((err) => {
        setCompanylist([]);

        console.log(err.message);
      });
  };
  //employee list
  const getallemployee = () => {
    var data = {
      limit: 30,
      page: 1,
      filter: "",
      search: "",
      between: "",
      sort: "emp_fullname ASC",
      group: "",
    };
    employeeService
      .getallEmployee(data)
      .then((res) => {
        if (res.data.status) {
          console.log(res.data);
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setEmplist(res.data.result.data);
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
          setEmplist([]);
          ref.current.log();
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
        ref.current.log();
        setEmplist([]);
      });
  };
  /* update material by appt id */
  const updateServicehistorybyapptid = (value) => {
    console.log(formik.values);
    servicehistoryService
      .updateServicehistorybyapptid(formik.values)
      .then((res) => {
        if (res.data.status) {
          console.log("success", res.data);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  /* delete material by appt id */

  const deleteServicehistorybyapptid = (value) => {
    console.log(value);
    servicehistoryService
      .deleteServicehistorybyapptid(String(value))
      .then((res) => {
        if (res.data.status) {
          console.log("success");
          setEventname("create");
        } else {
          console.log("failed");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //servicing list
  const allinventorybydateandteam = () => {
    console.log(selecteduser, "selected team");
    const sort = selecteduser.sort();
    const teamname = sort.toString();
    console.log(teamname);
    inventoryService
      .filterInventorybydateandteam(teamname, formik.values.receipt_date)
      .then((res) => {
        console.log(res.data);
        setApptlist(res.data.result);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  //category  list
  const getallapptcategory = () => {
    var data = {
      limit: 30,
      page: 1,
      filter: "",
      filtercategory: "main",
      search: "",
      between: "",
      sort: "",
      group: "",
    };
    categoryService
      .getallCategory(data)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          setCategorylist(res.data.result.data);
        } else {
          setCategorylist([]);
        }
      })
      .catch((err) => {
        setCategorylist([]);
      });
  };
  const teamformvalue = () => {
    formik.setFieldValue("team_name", sortarr.toString());
    formik.setFieldValue(
      "team_membera",
      sortarr[0] != undefined ? sortarr[1] : ""
    );
    formik.setFieldValue(
      "team_memberb",
      sortarr[1] != undefined ? sortarr[1] : ""
    );
    formik.setFieldValue(
      "team_memberc",
      sortarr[2] != undefined ? sortarr[2] : ""
    );
    formik.setFieldValue(
      "team_memberd",
      sortarr[3] != undefined ? sortarr[3] : ""
    );
    formik.setFieldValue(
      "team_membere",
      sortarr[4] != undefined ? sortarr[4] : ""
    );
  };
  //select user
  const selectuser = (value) => {
    if (
      (eventname === "edit" && searchParams.get("receipt_id")) ||
      eventname === "create"
    ) {
      if (value != "") {
        const index = selecteduser.indexOf(value);
        if (index > -1) {
          console.log("remove");
          setSelecteduser(selecteduser.filter((a) => a !== value));
          const sortarr = selecteduser.filter((a) => a !== value).sort();
          setSortarr(sortarr);
          formik.setFieldValue("team_name", sortarr.toString());
          formik.setFieldValue(
            "team_membera",
            sortarr[0] != undefined ? sortarr[0] : ""
          );
          formik.setFieldValue(
            "team_memberb",
            sortarr[1] != undefined ? sortarr[1] : ""
          );
          formik.setFieldValue(
            "team_memberc",
            sortarr[2] != undefined ? sortarr[2] : ""
          );
          formik.setFieldValue(
            "team_memberd",
            sortarr[3] != undefined ? sortarr[3] : ""
          );
          formik.setFieldValue(
            "team_membere",
            sortarr[4] != undefined ? sortarr[4] : ""
          );
        } else {
          console.log("add");
          if (selecteduser.length < 5) {
            setSelecteduser([...selecteduser, value]);
            const sortarr = [...selecteduser, value].sort();
            setSortarr(sortarr);
            formik.setFieldValue("team_name", sortarr.toString());
            formik.setFieldValue(
              "team_membera",
              sortarr[0] != undefined ? sortarr[0] : ""
            );
            formik.setFieldValue(
              "team_memberb",
              sortarr[1] != undefined ? sortarr[1] : ""
            );
            formik.setFieldValue(
              "team_memberc",
              sortarr[2] != undefined ? sortarr[2] : ""
            );
            formik.setFieldValue(
              "team_memberd",
              sortarr[3] != undefined ? sortarr[3] : ""
            );
            formik.setFieldValue(
              "team_membere",
              sortarr[4] != undefined ? sortarr[4] : ""
            );
            console.log(sortarr);
          } else {
            setIsmessage("Maximum team member limit is 5");
            ref.current.log();
          }
        }
      } else {
        formik.setFieldValue("team_name", sortarr.toString());
        formik.setFieldValue(
          "team_membera",
          sortarr[0] != undefined ? sortarr[0] : ""
        );
        formik.setFieldValue(
          "team_memberb",
          sortarr[1] != undefined ? sortarr[1] : ""
        );
        formik.setFieldValue(
          "team_memberc",
          sortarr[2] != undefined ? sortarr[2] : ""
        );
        formik.setFieldValue(
          "team_memberd",
          sortarr[3] != undefined ? sortarr[3] : ""
        );
        formik.setFieldValue(
          "team_membere",
          sortarr[4] != undefined ? sortarr[4] : ""
        );
      }
    } else {
      setIsmessage("Click edit to add/change team");
    }
  };
  const selectedcolor = (value) => {
    const index = selecteduser.indexOf(value);
    if (index > -1) {
      return "bg-primary";
    } else {
      return "bg-light";
    }
  };
  const selectedcolorss = (value) => {
    const index = selecteduser.indexOf(value);
    if (index > -1) {
      return "text-primary";
    } else {
      return "d-none";
    }
  };
  const getTeamFunc = (value) => {
    console.log(value);
  };

  const addappt = () => {
    console.log("helllo" + apptlist.length);
    navigate("/inventory/inventorybooking");
    setEventname("create", "");
    setSelecteduser([]);
    formik.resetForm();
    formik.setFieldValue("receipt_date", new Date().toISOString().slice(0, 10));
    selectuser("");
  };
  const companyonchange = (value) => {
    companyService
      .getCompany(value)
      .then((res) => {
        if (res.data.result) {
          formik.setFieldValue("mat_sender", res.data.result.com_username);
          formik.setFieldValue(
            "mat_sender_address",
            res.data.result.com_address
          );
          formik.setFieldValue("mat_receiver", res.data.result.com_username);
          formik.setFieldValue(
            "mat_receiver_address",
            res.data.result.com_address
          );
          console.log(res.data);
        } else {
        }
      })
      .catch((err) => {});
  };
  const [vendorname, setVendorname] = useState();
  const companyvendoronchange = (value) => {
    companyService
      .getCompany(value)
      .then((res) => {
        if (res.data.result) {
          setVendorname(res.data.result.com_id);
          formik.setFieldValue("mat_sender", res.data.result.com_username);
          formik.setFieldValue(
            "mat_sender_address",
            res.data.result.com_address
          );
          console.log(res.data);
        } else {
        }
      })
      .catch((err) => {});
  };
  // Call inventory clicks to request another page.
  if (ismessage == "Network Error") {
    return <Alertmsg alertmsg={ismessage} type={iserror} />;
  }
  return (
    <div className="">
      <div className="">
        {/*  loader and error handler */}
        {<Toastmessage ref={ref} type={iserror} errormsg={ismessage} />}
        {isLoaded ? <Loader /> : <></>}
      </div>
      {/*  <a
        onClick={() => {
          console.log(formik.values);
        }}
      >
        click me
      </a> */}
      {/*  container */}
      <div className="inventory-container ">
        <div className="d-flex p-2 shadow-sm bg-white">
          <div className="flex-fill pt-2">
            <strong>Material</strong>
          </div>
          <div>
            <a className="btn btn-primary me-1" onClick={() => addappt()}>
              {/*  <i className="fa fa-plus"></i> */}New
            </a>
          </div>
        </div>
        <div className="card border-0 mt-2 ">
          <div style={{ overflowY: "auto" }} className="d-flex">
            {emplist.map((data, index) => (
              <div key={index} className="p-2">
                <div
                  style={{
                    width: 45,
                    height: 45,
                    borderRadius: "50%",
                    padding: 2,
                    position: "relative",
                  }}
                  className={selectedcolor(data.emp_username)}
                  onClick={() => selectuser(data.emp_username)}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                    }}
                    src={
                      data.emp_pic ? serverURL + "/" + data.emp_pic : imagename
                    }
                  />
                  <span
                    style={{
                      position: "absolute",
                      top: 15,
                      right: 15,
                    }}
                    className={selectedcolorss(data.emp_username)}
                  >
                    <i className="fa fa-check "></i>{" "}
                  </span>
                </div>
                <div style={{ fontSize: 10 }}>{data.emp_fullname}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="">
          <div className="card border-0 rounded-0 p-1 mt-2">
            <div className="card-body p-1">
              <div className="d-flex bg-white p-1 border-bottom border-light border-3">
                <div className="flex-fill">
                  <div className="text-secondary">Details</div>
                </div>
                <div className="me-4">
                  {searchParams.get("receipt_id") ? (
                    <div>
                      <div className="dropdown">
                        <a
                          className="btn  btn-link"
                          href="#"
                          role="button"
                          id="usereventdropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i
                            className="fa fa-ellipsis-v"
                            aria-hidden="true"
                          ></i>
                        </a>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="usereventdropdownMenuLink"
                        >
                          <li>
                            <a
                              onClick={() => {
                                inventoryEvent("edit", "");
                              }}
                              className="dropdown-item"
                            >
                              Edit
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => inventoryEvent("delete", "")}
                              className="dropdown-item"
                            >
                              Delete
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <form onSubmit={formik.handleSubmit} className="p-2">
                <div className="row">
                  <div className="col-md-4">
                    <label htmlFor="com_id" className="form-label">
                      company
                    </label>
                    <div className="input-group col-md-6">
                      <select
                        id="com_id"
                        {...formik.getFieldProps("com_id")}
                        className="form-select"
                        disabled={
                          eventname == "view"
                            ? true
                            : eventname == "delete"
                            ? true
                            : false
                        }
                        aria-label="com_id"
                      >
                        <option value="" disabled>
                          Choose..
                        </option>
                        {companylist.map((data, index) => (
                          <option key={data.com_id} value={data.com_id}>
                            {data.com_fullname}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      {formik.touched.com_id && formik.errors.com_id ? (
                        <div className="error">{formik.errors.com_id}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="receipt_type" className="form-label">
                      Receipt type
                    </label>
                    <div className="input-group col-md-3">
                      <select
                        id="appt_category"
                        {...formik.getFieldProps("receipt_type")}
                        className="form-select "
                        disabled={
                          eventname == "view"
                            ? true
                            : eventname == "delete"
                            ? true
                            : false
                        }
                        aria-label="receipt_type"
                      >
                        <option value="" disabled>
                          Choose..
                        </option>
                        <option value="inventoryin">Inventory In</option>
                        <option value="inventoryout">Inventory Out</option>
                      </select>
                    </div>
                    <div>
                      {formik.touched.receipt_type &&
                      formik.errors.receipt_type ? (
                        <div className="error">
                          {formik.errors.receipt_type}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="receipt_date" className="form-label">
                      Receipt date
                    </label>
                    <div className="input-group col-md-6">
                      <input
                        className="form-control"
                        id="receipt_date"
                        type="date"
                        disabled={
                          eventname == "view"
                            ? true
                            : eventname == "delete"
                            ? true
                            : false
                        }
                        {...formik.getFieldProps("receipt_date")}
                      />
                    </div>
                    <div>
                      {formik.touched.receipt_date &&
                      formik.errors.receipt_date ? (
                        <div className="error">
                          {formik.errors.receipt_date}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <label htmlFor="receipt_status" className="form-label">
                      Status
                    </label>
                    <div className="input-group col-md-6">
                      <select
                        id="receipt_status"
                        {...formik.getFieldProps("receipt_status")}
                        className="form-select"
                        disabled={
                          eventname == "view"
                            ? true
                            : eventname == "delete"
                            ? true
                            : false
                        }
                        aria-label="receipt_status"
                      >
                        <option value="" disabled>
                          Choose..
                        </option>
                        <option value="pending">Pending</option>
                        <option value="active">Active</option>
                      </select>
                    </div>
                    <div>
                      {formik.touched.receipt_status &&
                      formik.errors.receipt_status ? (
                        <div className="error">
                          {formik.errors.receipt_status}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {formik.values.receipt_type === "inventoryin" ? (
                    <>
                      <div className="col-md-4">
                        <label htmlFor="com_isdsdd" className="form-label">
                          Company vendor
                        </label>
                        <div className="input-group col-md-6">
                          <select
                            id="com_isdsdd"
                            value={vendorname}
                            onChange={(e) => {
                              companyvendoronchange(e.target.value);
                            }}
                            className="form-select"
                            disabled={
                              eventname == "view"
                                ? true
                                : eventname == "delete"
                                ? true
                                : false
                            }
                            aria-label="com_sdsdid"
                          >
                            <option value="" disabled>
                              Choose..
                            </option>
                            {companyvendorlist.map((data, index) => (
                              <option key={index} value={data.com_id}>
                                {data.com_fullname}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <div style={{ paddingTop: 37 }} className="col-md-5 ">
                    {eventname === "create" ? (
                      <>
                        <button
                          disabled={!formik.isValid}
                          onClick={() => createInventory()}
                          className="btn btn-primary me-2"
                        >
                          Create
                        </button>
                        {/* <button
                          onClick={formik.handleReset}
                          className="btn btn-secondary"
                        >
                          Clear
                        </button> */}
                      </>
                    ) : eventname === "edit" ? (
                      <>
                        {" "}
                        <button
                          onClick={() => updateInventory()}
                          className="btn btn-primary me-2"
                        >
                          Update
                        </button>
                        <button
                          onClick={() => inventoryEvent("view", "")}
                          className="btn btn-secondary"
                        >
                          Cancel
                        </button>
                      </>
                    ) : eventname === "delete" ? (
                      <>
                        <button
                          onClick={() =>
                            deleteInventory(formik.values.receipt_id)
                          }
                          className="btn btn-danger me-2 "
                        >
                          Delete
                        </button>
                        <button
                          onClick={() => inventoryEvent("view", "")}
                          className="btn btn-secondary"
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <>
                        {/* <button onClick={() => navigate('/inventory')} className='btn btn-primary' >Back</button> */}
                      </>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="mt-2">
            {searchParams.get("receipt_id") ? (
              <Servicehistory
                receipt_event={appteventhandle}
                receipt_type={formik.values.receipt_type}
                receipt_date={formik.values.receipt_date}
                com_id={formik.values.com_id}
                receipt_id={receipt_id}
                team_membera={formik.values.team_membera}
                team_memberb={formik.values.team_memberb}
                team_memberc={formik.values.team_memberc}
                team_memberd={formik.values.team_memberd}
                team_membere={formik.values.team_membere}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inventorybooking;
