import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import userService from "../services/user.service";
import { Usersvalidationschema } from "../components/Validation";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import Loader from "../components/Loader";
import Toastmessage from "../components/Toastmessage";
import Reactcropper from "../components/Reactcropper";
import { useNavigate, useSearchParams } from "react-router-dom";
import authsService from "../services/auths.service";
import axios from "axios";
import imagename from "../assets/images/user.png";
import { serverURL } from "../http-common";

const Profile = () => {
  const ref = useRef();
  const userinfo = authsService.userinfo();

  let navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [userpic, setUserpic] = useState();

  const [user_id, setUserid] = useState();

  const [iserror, setIserror] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [ismessage, setIsmessage] = useState(false);
  const [eventname, setEventname] = useState("view");

  //form initial value
  const formik = useFormik({
    initialValues: {
      user_id: "",
      user_fullname: "",
      user_name: "",
      user_email: "",
      user_password: "",
      user_phone: "",
      user_address: "",
      user_role: "",
      user_status: "",
    },
    validationSchema: Usersvalidationschema,
    /*  validateOnChange: false,
     validateOnBlur: false, */
    onSubmit: (values, { resetForm }) => {
      //alert(JSON.stringify(values, null, 1));
      /*  resetForm({ values: '' }) */
    },
  });
  //use effects
  useEffect(() => {
    setUserid(userinfo.user_id);
    getUser(userinfo.user_id);
    if (userinfo.user_id) {
      setEventname("view");
      getUser(userinfo.user_id);
    } else {
      setEventname("view");
      getUser(userinfo.user_id);
    }
  }, []);

  //current event
  const userEvent = (eventname, value) => {
    if (value) {
      getUser(value);
      setEventname(eventname);
    }
    setEventname(eventname);
  };
  const getUploadFunc = (value) => {
    console.log(value);
    const formData = new FormData();
    formData.append("file", value);

    axios
      .post(
        "http://localhost/smart_dashboard/smart_dashboard_php_backend/uploads-api?req=singlefile",
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
          mode: "no-cors",
        }
      )
      .then((res) => {
        console.log("success:" + res.data);
      })
      .catch((err) => {
        console.log("failed:" + err);
      });
    /*  authsService.uploadUser(141, value).then((res) => {
      console.log('success', res.data);
    }).catch((err) => {
      console.log('error:' + err);
    }) */
  };
  const uploadFn = (value) => {
    const formData = new FormData();
    formData.append("file", value);
    userService
      .upload(formik.values.user_id,formData)
      .then((data) => {
        console.log(data.data);
        getUser(formik.values.user_id)
      })
      .catch((err) => {
        console.log("failed:" + err);
      });
  };
  //get user
  const getUser = (value) => {
    setIserror(null);
    setIsmessage(null);
    setIsLoaded(true);
    authsService
      .getUserProfile(userinfo.user_id)
      .then((res) => {
        console.log(res.data);

        if (res.data.result) {
          setIsLoaded(false);
          let data = res.data.result;
          setUserpic(serverURL+'/'+data.user_profile_pic);
          formik.setValues({
            user_id: data.user_id,
            user_fullname: data.user_fullname,
            user_name: data.user_name,
            user_email: data.user_email,
            user_phone: data.user_phone,
            user_address: data.user_address,
            user_password: data.user_password,
            user_role: data.user_role,
            user_status: data.user_status,
          });
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
      });
  };
  //create user
  const createUser = () => {
    setIserror(null);
    setIsmessage(null);
    setIsLoaded(true);
    userService
      .createUser(formik.values)
      .then((res) => {
        if (res.data.status) {
          console.log(res.data);
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          navigate("/usersmarttable");
          ref.current.log();
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
          ref.current.log();
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
        ref.current.log();
      });
  };

  //update user
  const updateUser = () => {
    console.log(JSON.stringify(formik.values, null, 2));
    userService
      .updateUser(formik.values)
      .then((res) => {
        if (res.data.result) {
          console.log(res.data);
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          userEvent("view");
          ref.current.log();
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
          ref.current.log();
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
        ref.current.log();
      });
  };
  //delete user
  const deleteUser = (value) => {
    console.log(value);
    userService
      .deleteUser(String(value))
      .then((res) => {
        if (res.data.status) {
          console.log("succes");
          navigate("/usersmarttable");
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          /*  ref.current.log() */
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
          ref.current.log();
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
        ref.current.log();
      });
  };
  // Call user clicks to request another page.
  if (isLoaded) {
    <Loader />;
  } else {
    return (
      <div className="container">
        {/*  container */}
        <div className="user-container">
          {/* form container */}
          <div className="card shadow-sm border-0 ">
            <div className="card-body ">
              <div className="d-flex">
                <h5 className="card-title p-0 m-0 me-auto"></h5>
                {eventname == "create" ? (
                  <></>
                ) : (
                  <>
                    {/* <div>
                    <div className="dropdown">
                      <a
                        className="btn  btn-link"
                        href="#"
                        role="button"
                        id="usereventdropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </a>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="usereventdropdownMenuLink"
                      >
                        <li>
                          <a
                            onClick={() => {
                              userEvent("edit");
                            }}
                            className="dropdown-item"
                          >
                            Edit
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => userEvent("delete")}
                            className="dropdown-item"
                          >
                            Delete
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                  </>
                )}
              </div>
              <div className="row">
                {/*  profile section */}
                <div className="col-md-3 d-flex justify-content-center">
                  <div>
                    <img
                      style={{ width: 150, height: 150, borderRadius: "50%" }}
                      src={userpic ? userpic : imagename}
                    />
                  </div>
                  <div style={{ paddingTop: 130, marginLeft: -50 }}>
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#uploadmodal"
                      style={{}}
                      className="btn btn-sm btn-secondary opacity-50"
                    >
                      <i className="fa fa-pencil-square-o"></i>
                    </button>
                  </div>
                </div>

                {/* form section */}
                <div className="col-md-9">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-md-6 ">
                        <label htmlFor="user_fullname" className="form-label">
                          Fullname
                        </label>
                        <div className="input-group">
                          <input
                            className="form-control"
                            placeholder="Enter fullname"
                            id="user_fullname"
                            type="text"
                            disabled={
                              eventname == "view"
                                ? true
                                : eventname == "delete"
                                ? true
                                : false
                            }
                            {...formik.getFieldProps("user_fullname")}
                          />
                        </div>
                        <div>
                          {formik.touched.user_fullname &&
                          formik.errors.user_fullname ? (
                            <div className="error">
                              {formik.errors.user_fullname}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="user_name" className="form-label">
                          Username
                        </label>
                        <div className="input-group col-md-6">
                          <input
                            className="form-control"
                            placeholder="Enter Username"
                            id="user_name"
                            type="text"
                            disabled={
                              eventname == "view"
                                ? true
                                : eventname == "delete"
                                ? true
                                : false
                            }
                            {...formik.getFieldProps("user_name")}
                          />
                        </div>
                        <div>
                          {formik.touched.user_name &&
                          formik.errors.user_name ? (
                            <div className="error">
                              {formik.errors.user_name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="user_email" className="form-label ">
                          Email
                        </label>
                        <div className="input-group col-md-6">
                          <input
                            className="form-control"
                            placeholder="Enter Email"
                            id="user_email"
                            type="text"
                            disabled={
                              eventname == "view"
                                ? true
                                : eventname == "delete"
                                ? true
                                : false
                            }
                            {...formik.getFieldProps("user_email")}
                          />
                        </div>
                        <div>
                          {formik.touched.user_email &&
                          formik.errors.user_email ? (
                            <div className="error">
                              {formik.errors.user_email}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="user_status" className="form-label">
                          status
                        </label>
                        <div className="input-group col-md-6">
                          <select
                            id="user_status"
                            {...formik.getFieldProps("user_status")}
                            className="form-select"
                            aria-label="user_status"
                            disabled={
                              eventname == "view"
                                ? true
                                : eventname == "delete"
                                ? true
                                : false
                            }
                          >
                            <option value="" disabled>
                              Select status
                            </option>
                            <option value="pending">Pending</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </select>
                        </div>
                        <div>
                          {formik.touched.user_status &&
                          formik.errors.user_status ? (
                            <div className="error">
                              {formik.errors.user_status}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-12 pt-2 pb-2">
                        {eventname === "create" ? (
                          <>
                            <button
                              disabled={!formik.isValid}
                              onClick={() => createUser()}
                              className="btn btn-primary me-2"
                            >
                              Create
                            </button>
                            <button
                              onClick={formik.handleReset}
                              className="btn btn-secondary"
                            >
                              Clear
                            </button>
                          </>
                        ) : eventname === "edit" ? (
                          <>
                            {" "}
                            <button
                              onClick={() => updateUser()}
                              className="btn btn-primary me-2"
                            >
                              Update
                            </button>
                            <button
                              onClick={() => userEvent("view")}
                              className="btn btn-secondary"
                            >
                              Cancel
                            </button>
                          </>
                        ) : eventname === "delete" ? (
                          <>
                            <button
                              onClick={() => deleteUser(formik.values.user_id)}
                              className="btn btn-danger me-2 "
                            >
                              Delete
                            </button>
                            <button
                              onClick={() => userEvent("view")}
                              className="btn btn-secondary"
                            >
                              Cancel
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              onClick={() =>window.history.back()}
                              className="btn btn-primary"
                            >
                              Back
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/*  loader and error handler */}
          {/*  {iserror === false || iserror === true ? <Alertmsg alertmsg={ismessage} type={iserror} /> : <></>} */}
          {<Toastmessage ref={ref} type={iserror} errormsg={ismessage} />}

          {/*  upload modal */}
          <div
            className="modal modal-lg"
            id="uploadmodal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content p-2">
                <div className="modal-header p-0 ps-2 pe-2 border-0">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Upload
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body p-0">
                  <Reactcropper newFunc={uploadFn} />
                </div>
                {/*  <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="button" className="btn btn-primary">Understood</button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
export default Profile;
