import React, { useState, useContext, useRef } from "react";
import { Signinvalidationschema } from "../components/Validation";
import { useFormik } from "formik";
import { AuthContext } from "../context/Authcontext";
import authsService from "../services/auths.service";
import { useNavigate } from "react-router-dom";
import Alertmsg from "../components/Alertmsg";
import Loader from "../components/Loader";
import Toastmessage from "../components/Toastmessage";

const Signin = () => {
  const ref = useRef();
  let navigate = useNavigate();
  const [iserror, setIserror] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [ismessage, setIsmessage] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const { signIn } = useContext(AuthContext);
  const formik = useFormik({
    initialValues: {
      user_email: "",
      user_password: "",
    },
    validationSchema: Signinvalidationschema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const signin = () => {
    setIserror(null);
    setIsmessage(null);
    setIsLoaded(true);
    authsService
      .signin(formik.values)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          localStorage.setItem("isloggedin", true);
          localStorage.setItem("userinfo", JSON.stringify(res.data.result));
          signIn();
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
        }
      })
      .catch((err) => {
        //console.log('error' + err);
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
        localStorage.setItem("isloggedin", false);
      });
  };

  return (
    <div className="container signin-container">
      <div className=" p-5">
        <div className="row row-cols-1 row-cols-lg-2">
          <div className="col"></div>
          <div className="col">
            <h1 className="">Welcome Back!</h1>
            <p className="description ">Please Sign In to your account</p>
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <label className="form-label me-4" htmlFor="user_email">
                    Email
                  </label>
                  <div className="input-group ">
                    <input
                      className="form-control"
                      id="user_email"
                      type="text"
                      {...formik.getFieldProps("user_email")}
                    />
                  </div>
                  {formik.touched.user_email && formik.errors.user_email ? (
                    <div className="error">{formik.errors.user_email}</div>
                  ) : null}
                </div>
                <div className="col-md-12">
                  <label className="form-label me-4" htmlFor="user_password">
                    Password
                  </label>
                  <div className="input-group ">
                    <input
                      className="form-control"
                      id="user_password"
                      type={showPass ? "text" : "password"}
                      autoComplete="off"
                      {...formik.getFieldProps("user_password")}
                    />
                    <span
                      onClick={() => setShowPass(!showPass)}
                      className="input-group-text bg-white"
                    >
                      <i
                        className={showPass ? "fa fa-eye-slash" : "fa fa-eye"}
                      ></i>{" "}
                    </span>
                  </div>
                  {formik.touched.user_password &&
                  formik.errors.user_password ? (
                    <div className="error">{formik.errors.user_password}</div>
                  ) : null}
                </div>
                <div className="col-md-12 text-end">
                  <a
                    className="btn btn-link text-decoration-none"
                    onClick={() => navigate("/resetlink")}
                  >
                    Forget Password ?
                  </a>
                </div>
              </div>
              {/* <button type="submit" className="btn btn-primary mt-3" >Login</button> */}
            </form>
            <button
              onClick={signin}
              disabled={!(formik.isValid && formik.dirty)}
              className="btn btn-primary mt-3"
            >
              Login
            </button>
            {/*  <div className='mt-3'>
              Don't have an account yet? <button className='btn btn-link text-decoration-none m-0 p-0' onClick={() => navigate('/signup')}>SignUp</button> here.
            </div> */}
            <div className="mt-2">
              {/*  loader and error handler */}
              {iserror === false || iserror === true ? (
                <Alertmsg alertmsg={ismessage} type={iserror} />
              ) : (
                <></>
              )}
              {/*  {<Toastmessage ref={ref} type={error} errormsg={ismessage} />} */}
              {isLoaded ? <Loader /> : <></>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
