import axios from "axios";
import { serverURL, headeroption, headeroptionupload } from "../http-common";

class EmpbookingService {
  getallAppoinment(data) {
    return axios.get(serverURL + "/empbooking-api?req=smarttableAppoinment", {
      params: data,
      headers: headeroption,
    });
  }
  getallAppoinmentjoin(data) {
    return axios.get(
      serverURL + "/empbooking-api?req=smarttablejoinAppoinment",
      {
        params: data,
        headers: headeroption,
      }
    );
  }
  getallAppoinmentemp(data) {
    return axios.get(
      serverURL + "/empbooking-api?req=smarttableAppoinmentemp",
      {
        params: data,
        headers: headeroption,
      }
    );
  }
  getallAppoinmentempdata(data) {
    return axios.get(
      serverURL + "/empbooking-api?req=smarttableAppoinmentdata",
      {
        params: data,
        headers: headeroption,
      }
    );
  }

  getAppoinment(receipt_id) {
    return axios.get(serverURL + "/empbooking-api?req=getAppoinment", {
      params: { receipt_id: receipt_id },
      headers: headeroption,
    });
  }
  searchAppoinment(search_customer, filter_appt_category) {
    return axios.get(serverURL + "/empbooking-api?req=searchAppoinment", {
      params: {
        search_customer: search_customer,
        filter_appt_category: filter_appt_category,
      },
      headers: headeroption,
    });
  }
  createAppoinment(data) {
    return axios.post(
      serverURL + "/empbooking-api?req=createAppoinment",
      data,
      {
        headers: headeroption,
      }
    );
  }
  updateAppoinment(data) {
    return axios.put(
      serverURL + "/empbooking-api?req=updateAppoinment",
      data,
      {
        headers: headeroption,
      }
    );
  }
  deleteAppoinment(receipt_id) {
    return axios.delete(serverURL + "/empbooking-api?req=deleteAppoinment", {
      params: { receipt_id: receipt_id },
      headers: headeroption,
    });
  }
  deleteAll() {
    return axios.delete(serverURL + "/empbooking-api?req=deleteAppoinment");
  }
  filterAppoinmentbydateandteam(filter_receipt_team, filter_receipt_date) {
    return axios.get(
      serverURL + "/empbooking-api?req=filterAppoinmentbydateandteam",
      {
        params: {
          filter_receipt_team: filter_receipt_team,
          filter_receipt_date: filter_receipt_date,
        },
        headers: headeroption,
      }
    );
  }
  allAppoinmentconnectlist(filter_appt_connect) {
    return axios.get(
      serverURL + "/empbooking-api?req=allAppoinmentconnectlist",
      {
        params: {
          filter_appt_connect: filter_appt_connect,
        },
        headers: headeroption,
      }
    );
  }

  findByTitle(emp_name) {
    return axios.get(serverURL + "/employee/empbooking-api?name=" + emp_name);
  }
  upload(appt_id, data) {
    return axios.post(
      serverURL + "/empbooking-api/uploadsinglefile?appt_id=" + appt_id,
      data,
      { headers: headeroptionupload }
    );
  }
  uploads(appt_id, data) {
    return axios.post(
      serverURL + "/empbooking-api/uploadmultifile?appt_id=" + appt_id,
      data,
      { headers: headeroptionupload }
    );
  }
}
export default new EmpbookingService();
