import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import employeeService from "../services/employee.service";
import { Employeevalidationschema } from "../components/Validation";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";
import Loader from "../components/Loader";
import Toastmessage from "../components/Toastmessage";
import { useNavigate, useSearchParams } from "react-router-dom";
import Alertmsg from "../components/Alertmsg";
import Reactcropper from "../components/Reactcropper";
import axios from "axios";
import imagename from "../assets/images/user.png";
import "react-circular-progressbar/dist/styles.css";
import categoryService from "../services/category.service";
import { Empstatistics } from "./Empstatistics";
import { serverURL } from "../http-common";
const Employeeevent = (props) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [showPass, setShowPass] = useState(false);
  const [userpic, setUserpic] = useState();

  const ref = useRef();
  let navigate = useNavigate();
  const [emp_id, setEmployeeid] = useState();

  //expiry dates

  const [categorylist, setCategorylist] = useState([]);

  const [iserror, setIserror] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [ismessage, setIsmessage] = useState(false);
  const [eventname, setEventname] = useState("view");

  //form initial value
  const formik = useFormik({
    initialValues: {
      emp_id: "",
      emp_fullname: "",
      emp_username: "",
      emp_password: "",
      emp_email: "",
      emp_phone: "",
      emp_address: "",
      emp_fin: "",
      emp_passport: "",
      emp_role: "",
      emp_status: "",
    },
    validationSchema: Employeevalidationschema,
    /*  validateOnChange: false,
         validateOnBlur: false, */
    onSubmit: (values, { resetForm }) => {
      //alert(JSON.stringify(values, null, 1));
      /*  resetForm({ values: '' }) */
    },
  });
  //use effects
  useEffect(() => {
    setEmployeeid(searchParams.get("emp_id"));
    if (searchParams.get("emp_id")) {
      getEmployee(searchParams.get("emp_id"));
    } else {
      setEventname("create");
    }
  }, []);

  //current event
  const employeeEvent = (eventname, value) => {
    if (value) {
      getEmployee(value);
      setEventname(eventname);
    }
    setEventname(eventname);
  };
  const getUploadFunc = (value) => {
    const formData = new FormData();
    formData.append("file", value);
    employeeService
      .upload(formik.values.emp_id, formData)
      .then((data) => {
        console.log(data.data);
        getEmployee(formik.values.emp_id);
      })
      .catch((err) => {
        console.log("failed:" + err);
      });
  };
  //get user
  const getEmployee = (value) => {
    setIserror(null);
    setIsmessage(null);
    setIsLoaded(true);
    employeeService
      .getEmployee(value)

      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          console.log(res.data);
          setIsLoaded(false);
          let data = res.data.result;
          setUserpic(serverURL + "/" + data.emp_pic);
          formik.setValues({
            emp_id: data.emp_id,
            emp_fullname: data.emp_fullname,
            emp_username: data.emp_username,
            emp_password: data.emp_password,
            emp_status: data.emp_status,
            emp_email: data.emp_email,
            emp_phone: data.emp_phone,
            emp_address: data.emp_address,
            emp_fin: data.emp_fin,
            emp_passport: data.emp_passport,
            emp_role: data.emp_role,
          });
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
        }
      })
      .catch((err) => {
        setIserror(false);
        setIsmessage(err.message);
        setIsLoaded(false);
      });
  };
  //create user
  const createEmployee = () => {
    setIserror(null);
    setIsmessage(null);
    setIsLoaded(true);
    employeeService
      .createEmployee(formik.values)
      .then((res) => {
        if (res.data.status) {
          console.log(res.data);
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          navigate("/employee");
          ref.current.log();
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
          ref.current.log();
        }
      })
      .catch((err) => {
        setIserror(false);
        setIsmessage(err.message);
        setIsLoaded(false);
        ref.current.log();
      });
  };

  //update user
  const updateEmployee = () => {
    console.log(JSON.stringify(formik.values, null, 2));
    employeeService
      .updateEmployee(formik.values)
      .then((res) => {
        if (res.data.status) {
          console.log(res.data);
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          employeeEvent("view");
          ref.current.log();
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
          ref.current.log();
          console.log(res.data);
        }
      })
      .catch((err) => {
        setIserror(false);
        setIsmessage(err.message);
        setIsLoaded(false);
        ref.current.log();
      });
  };
  //delete user
  const deleteEmployee = (value) => {
    console.log(value);
    employeeService
      .deleteEmployee(String(value))
      .then((res) => {
        if (res.data.status) {
          console.log("succes");
          navigate("/employee");
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          /*  ref.current.log() */
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
          ref.current.log();
        }
      })
      .catch((err) => {
        setIserror(false);
        setIsmessage(err.message);
        setIsLoaded(false);
        ref.current.log();
      });
  };
  // Call user clicks to request another page.
  if (ismessage == "Network Error") {
    return <Alertmsg alertmsg={ismessage} type={iserror} />;
  }
  return (
    <div className="user-container">
      <div className="text-center">
        {/*  loader and error handler */}
        {/*  {<Toastmessage ref={ref} type={error} errormsg={ismessage} />} */}
        {isLoaded ? <Loader /> : <></>}
      </div>
      {/*  container */}
      <div className="container">
        {/* form container */}
        <div className="card shadow-sm border-0 ">
          <div className="card-body ">
            <div className="d-flex p-2 ">
              <h5 className="card-title p-0 m-0 me-auto"></h5>
              {eventname == "create" ? (
                <></>
              ) : (
                <div>
                  <div className="dropdown">
                    <a
                      className="btn  btn-link"
                      href="#"
                      role="button"
                      id="usereventdropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="usereventdropdownMenuLink"
                    >
                      <li>
                        <a
                          onClick={() => {
                            employeeEvent("edit");
                          }}
                          className="dropdown-item"
                        >
                          Edit
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => employeeEvent("delete")}
                          className="dropdown-item"
                        >
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                {/*  profile section */}
                <div className="col-md-3 ">
                  <div
                    className="d-flex justify-content-center "
                    style={{ position: "relative" }}
                  >
                    <div>
                      <img
                        style={{ width: 150, height: 150, borderRadius: "50%" }}
                        src={userpic ? userpic : imagename}
                      />
                    </div>
                    <div style={{ position: "absolute", bottom: 0, right: 90 }}>
                      <button
                        data-bs-toggle="modal"
                        data-bs-target="#uploadmodal"
                        className="btn btn-sm btn-secondary "
                      >
                        <i className="fa fa-pencil-square-o"></i>
                      </button>
                    </div>
                  </div>
                  <div>
                    <div className="col-md-12">
                      <label htmlFor="emp_username" className="form-label">
                        Username
                      </label>
                      <div className="input-group col-md-6">
                        <input
                          autoComplete="off"
                          className="form-control"
                          placeholder="Enter username"
                          id="emp_username"
                          type="text"
                          disabled={
                            eventname == "view"
                              ? true
                              : eventname == "delete"
                              ? true
                              : false
                          }
                          {...formik.getFieldProps("emp_username")}
                        />
                      </div>
                      <div>
                        {formik.touched.emp_username &&
                        formik.errors.emp_username ? (
                          <div className="error">
                            {formik.errors.emp_username}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="form-label me-4" htmlFor="emp_password">
                        Password
                      </label>
                      <div className="input-group ">
                        <input
                          className="form-control"
                          id="emp_password"
                          type={showPass ? "text" : "password"}
                          autoComplete="new-password"
                          disabled={
                            eventname == "view"
                              ? true
                              : eventname == "delete"
                              ? true
                              : false
                          }
                          {...formik.getFieldProps("emp_password")}
                        />
                        <span
                          onClick={() => setShowPass(!showPass)}
                          className="input-group-text bg-white"
                        >
                          <i
                            className={
                              showPass ? "fa fa-eye-slash" : "fa fa-eye"
                            }
                          ></i>{" "}
                        </span>
                      </div>
                      {formik.touched.emp_password &&
                      formik.errors.emp_password ? (
                        <div className="error">
                          {formik.errors.emp_password}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="emp_email" className="form-label">
                        Email
                      </label>
                      <div className="input-group col-md-6">
                        <input
                          className="form-control"
                          placeholder="Enter Email"
                          id="emp_email"
                          type="text"
                          disabled={
                            eventname == "view"
                              ? true
                              : eventname == "delete"
                              ? true
                              : false
                          }
                          {...formik.getFieldProps("emp_email")}
                        />
                      </div>
                      <div>
                        {formik.touched.emp_email && formik.errors.emp_email ? (
                          <div className="error">{formik.errors.emp_email}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                {/* form section */}
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-12 ">
                      <label htmlFor="emp_fullname" className="form-label">
                        Fullname
                      </label>
                      <div className="input-group">
                        <input
                          className="form-control"
                          placeholder="Enter Fullname"
                          id="emp_fullname"
                          type="text"
                          disabled={
                            eventname == "view"
                              ? true
                              : eventname == "delete"
                              ? true
                              : false
                          }
                          {...formik.getFieldProps("emp_fullname")}
                        />
                      </div>
                      <div>
                        {formik.touched.emp_fullname &&
                        formik.errors.emp_fullname ? (
                          <div className="error">
                            {formik.errors.emp_fullname}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="emp_phone" className="form-label">
                        Phone
                      </label>
                      <div className="input-group col-md-6">
                        <input
                          className="form-control"
                          placeholder="Enter Phone"
                          id="emp_phone"
                          type="text"
                          disabled={
                            eventname == "view"
                              ? true
                              : eventname == "delete"
                              ? true
                              : false
                          }
                          {...formik.getFieldProps("emp_phone")}
                        />
                      </div>
                      <div>
                        {formik.touched.emp_phone && formik.errors.emp_phone ? (
                          <div className="error">{formik.errors.emp_phone}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      <label htmlFor="emp_fin" className="form-label">
                        FIN
                      </label>
                      <div className="input-group">
                        <input
                          className="form-control"
                          placeholder="Enter FIN"
                          id="emp_fin"
                          type="text"
                          disabled={
                            eventname == "view"
                              ? true
                              : eventname == "delete"
                              ? true
                              : false
                          }
                          {...formik.getFieldProps("emp_fin")}
                        />
                      </div>
                      <div>
                        {formik.touched.emp_fin && formik.errors.emp_fin ? (
                          <div className="error">{formik.errors.emp_fin}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      <label htmlFor="emp_passport" className="form-label">
                        Passport
                      </label>
                      <div className="input-group">
                        <input
                          className="form-control"
                          placeholder="Enter Passport"
                          id="emp_passport"
                          type="text"
                          disabled={
                            eventname == "view"
                              ? true
                              : eventname == "delete"
                              ? true
                              : false
                          }
                          {...formik.getFieldProps("emp_passport")}
                        />
                      </div>
                      <div>
                        {formik.touched.emp_passport &&
                        formik.errors.emp_passport ? (
                          <div className="error">
                            {formik.errors.emp_passport}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-12 ">
                      <label htmlFor="emp_address" className="form-label">
                        Address
                      </label>
                      <div className="input-group">
                        <textarea
                          className="form-control"
                          placeholder="Enter Address"
                          id="emp_address"
                          type="text"
                          disabled={
                            eventname == "view"
                              ? true
                              : eventname == "delete"
                              ? true
                              : false
                          }
                          {...formik.getFieldProps("emp_address")}
                        ></textarea>
                      </div>
                      <div>
                        {formik.touched.emp_address &&
                        formik.errors.emp_address ? (
                          <div className="error">
                            {formik.errors.emp_address}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="emp_role" className="form-label">
                        Role
                      </label>
                      <div className="input-group col-md-6">
                        <select
                          id="emp_role"
                          {...formik.getFieldProps("emp_role")}
                          className="form-select"
                          disabled={
                            eventname == "view"
                              ? true
                              : eventname == "delete"
                              ? true
                              : false
                          }
                          aria-label="emp_role"
                        >
                          <option value="" disabled>
                            Select Role
                          </option>
                          <option value="admin">Admin</option>
                          <option value="editor">Editor</option>
                          <option value="member">Member</option>
                        </select>
                      </div>
                      <div>
                        {formik.touched.emp_role && formik.errors.emp_role ? (
                          <div className="error">{formik.errors.emp_role}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="emp_status" className="form-label">
                        status
                      </label>
                      <div className="input-group col-md-6">
                        <select
                          id="emp_status"
                          {...formik.getFieldProps("emp_status")}
                          className="form-select"
                          disabled={
                            eventname == "view"
                              ? true
                              : eventname == "delete"
                              ? true
                              : false
                          }
                          aria-label="emp_status"
                        >
                          <option value="" disabled>
                            Select status
                          </option>
                          <option value="pending">Pending</option>
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                        </select>
                      </div>
                      <div>
                        {formik.touched.emp_status &&
                        formik.errors.emp_status ? (
                          <div className="error">
                            {formik.errors.emp_status}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-12 pt-2 pb-2">
                      {eventname === "create" ? (
                        <>
                          <button
                            disabled={!formik.isValid}
                            onClick={() => createEmployee()}
                            className="btn btn-primary me-2"
                          >
                            Create
                          </button>
                          <button
                            onClick={formik.handleReset}
                            className="btn btn-secondary"
                          >
                            Clear
                          </button>
                        </>
                      ) : eventname === "edit" ? (
                        <>
                          {" "}
                          <button
                            onClick={() => updateEmployee()}
                            className="btn btn-primary me-2"
                          >
                            Update
                          </button>
                          <button
                            onClick={() => employeeEvent("view")}
                            className="btn btn-secondary"
                          >
                            Cancel
                          </button>
                        </>
                      ) : eventname === "delete" ? (
                        <>
                          <button
                            onClick={() => deleteEmployee(formik.values.emp_id)}
                            className="btn btn-danger me-2 "
                          >
                            Delete
                          </button>
                          <button
                            onClick={() => employeeEvent("view")}
                            className="btn btn-secondary"
                          >
                            Cancel
                          </button>
                        </>
                      ) : (
                        <>
                          {/* <button onClick={() => navigate('/employee')} className='btn btn-primary' >Back</button> */}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/*  section 2 */}
        {/*  loader and error handler */}
        {/*  {iserror === false || iserror === true ? <Alertmsg alertmsg={ismessage} type={iserror} /> : <></>} */}
        {<Toastmessage ref={ref} type={iserror} errormsg={ismessage} />}

        {/*  upload modal */}
        <div
          className="modal fade modal-lg"
          id="uploadmodal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Upload
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body pb-5">
                <Reactcropper newFunc={getUploadFunc} />
              </div>
              {/*  <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="button" className="btn btn-primary">Understood</button>
                </div> */}
            </div>
          </div>
        </div>
        <div className="pt-3">
        {searchParams.get("emp_id") ? (
         <Empstatistics emp_name={formik.values.emp_username} />
        ) : (
          <></>
        )}
      </div>
      </div>
      
    </div>
  );
};

export default Employeeevent;
