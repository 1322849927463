import axios from "axios";
import { serverURL, headeroption, headeroptionupload } from "../http-common";

class MaterialService {
    getallCompany(data) {
        return axios.get(serverURL + "/company-api?req=smarttableCompany", {
            params: data,
            headers: headeroption,
        })
    }
    getallMaterial(data) {
        return axios.get(serverURL + "/materials-api?req=smarttableMaterials", {
          params: data,
          headers: headeroption,
        })
      }
      getMaterial(mat_id) {
        return axios.get(serverURL + "/materials-api?req=getMaterials", {
          params: { mat_id: mat_id }, headers: headeroption,
        });
      }
      createMaterial(data) {
        return axios.post(serverURL + "/materials-api?req=createMaterials", data, {
          headers: headeroption,
        });
      }
      updateMaterial(data) {
        return axios.put(serverURL + "/materials-api?req=updateMaterials", data, {
          headers: headeroption,
        });
      }
      deleteMaterial(mat_id) {
        return axios.delete(serverURL + "/materials-api?req=deleteMaterials", { params: { mat_id: mat_id }, headers: headeroption, });
      }
      deleteAll() {
        return axios.delete(serverURL + "/materials-api?req=deleteMaterials");
      }
      findByTitle(mat_name) {
        return axios.get(serverURL + "/user/materials-api?name=" + mat_name);
      }
      upload(mat_id, data) {
        return axios.post(serverURL + "/materials-api/uploadsinglefile?mat_id=" + mat_id, data, { headers: headeroptionupload });
      }
      uploads(mat_id, data) {
        return axios.post(serverURL + "/materials-api/uploadmultifile?mat_id=" + mat_id, data, { headers: headeroptionupload });
      }
}
export default new MaterialService();
