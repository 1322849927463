import React, { useState, useEffect, useRef } from 'react'
import { Signupvalidationschema } from '../components/Validation'
import { useFormik } from 'formik';
import authsService from '../services/auths.service';
import { useNavigate } from "react-router-dom";
import Alertmsg from '../components/Alertmsg';
import Toastmessage from '../components/Toastmessage';
import Loader from '../components/Loader';

const Signup = () => {
  const ref = useRef();
  const [iserror, setIserror] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [ismessage, setIsmessage] = useState(false);
  let navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const formik = useFormik({
    initialValues: {
      user_fullname: '',
      user_name: '',
      user_email: '',
      user_password: '',
      confirmPassword: '',
      acceptTerms: 'false'
    },
    validationSchema: Signupvalidationschema,
    onSubmit: values => {
      /* alert(JSON.stringify(values, null, 2)); */

    }
  });
  useEffect(() => {

  }, []);
  const signup = (() => {
    setIserror(null)
    setIsmessage(null)
    setIsLoaded(true);
    authsService.signup(formik.values).then((res) => {
      if (res.data.result) {
        setIsLoaded(false);
        setIserror(res.data.status);
        setIsmessage(res.data.message);
        navigate('/signin')
      } else {
        setIserror(res.data.status);
        setIsmessage(res.data.message);
        setIsLoaded(false);
        // ref.current.log()
      }
      /* localStorage.setItem('isloggedin', true);
      signIn(); */
    }).catch((err) => {
      setIserror(true);
      setIsmessage(err.message);
      setIsLoaded(false);
      console.log('error' + err);
      /*       localStorage.setItem('isloggedin', false); */
    })
  })
  return (
    <div className='container signup-container'>
      {/* toast message */}
      {/*  <Toastmessage ref={ref} type='success' errormsg={error}/> */}
      <div className='bg-white p-5'>
        <div className="row row-cols-1 row-cols-lg-2">
          <div className="col"></div>
          <div className="col">
            <h1 className=''>Welcome</h1>
            <p className="description ">Let's Get Started!</p>
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <label className='form-label me-4 ' htmlFor="user_fullname">Fullname</label>
                  <div className='input-group'>
                    <input
                      className='form-control'
                      id="user_fullname"
                      type="text"
                      autoComplete='off'
                      {...formik.getFieldProps('user_fullname')}
                    />
                  </div>
                  {formik.touched.user_fullname && formik.errors.user_fullname ? (
                    <div className='error'>{formik.errors.user_fullname}</div>
                  ) : null}
                </div>
                <div className="col-md-12">
                  <label className='form-label me-4' htmlFor="user_name">Username</label>
                  <div className='input-group '>
                    <input
                      className='form-control'
                      id="user_name"
                      type="text"
                      autoComplete='off'
                      {...formik.getFieldProps('user_name')}
                    />
                  </div>
                  {formik.touched.user_name && formik.errors.user_name ? (
                    <div className='error'>{formik.errors.user_name}</div>
                  ) : null}
                </div>
                <div className="col-md-12">
                  <label className='form-label me-4' htmlFor="user_email">Email</label>
                  <div className='input-group '>
                    <input
                      className='form-control'
                      id="user_email"
                      type="text"
                      autoComplete='off'
                      {...formik.getFieldProps('user_email')}
                    />
                  </div>
                  {formik.touched.user_email && formik.errors.user_email ? (
                    <div className='error'>{formik.errors.user_email}</div>
                  ) : null}
                </div>
                <div className="col-md-12">
                  <label className='form-label me-4' htmlFor="user_password">Password</label>
                  <div className='input-group '>
                    <input
                      className='form-control'
                      id="user_password"
                      type={showPass ? 'text' : 'password'}
                      autoComplete="off"
                      {...formik.getFieldProps('user_password')}
                    />
                    <span onClick={() => setShowPass(!showPass)} className="input-group-text bg-white"><i className={showPass ? 'fa fa-eye-slash' : 'fa fa-eye'}></i> </span>
                  </div>
                  {formik.touched.user_password && formik.errors.user_password ? (
                    <div className='error'>{formik.errors.user_password}</div>
                  ) : null}
                </div>
                <div className="col-md-12">
                  <label className='form-label me-4' htmlFor="confirmPassword">confirmPassword</label>
                  <div className='input-group '>
                    <input
                      className='form-control'
                      id="confirmPassword"
                      type="text"
                      autoComplete="off"
                      {...formik.getFieldProps('confirmPassword')}
                    />
                  </div>
                  {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                    <div className='error'>{formik.errors.confirmPassword}</div>
                  ) : null}
                </div>
                <div className="col-md-12">
                  <div className="form-group form-check">
                    <input
                      name="acceptTerms"
                      type="checkbox"
                      {...formik.getFieldProps('acceptTerms')}
                      id="acceptTerms"
                      className="form-check-input "
                    />
                    <label htmlFor="acceptTerms" className="form-check-label">
                      Accept Terms & Conditions
                    </label>
                    {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                      <div className='error'>{formik.errors.acceptTerms}</div>
                    ) : null}
                  </div>

                </div>
              </div>
            </form>
            <button disabled={!(formik.isValid && formik.dirty)} onClick={signup} className='btn btn-primary mt-3' type="submit">Submit</button>
            <div className='mt-3'>
              Already have an account? <button className='btn btn-link text-decoration-none m-0 p-0' onClick={() => navigate('/signin')}>SignIn</button> here.
            </div>
            {/*  loader and error handler */}
            {iserror === false || iserror === true ? <Alertmsg alertmsg={ismessage} type={iserror} /> : <></>}
            {/*  {<Toastmessage ref={ref} type={error} errormsg={ismessage} />} */}
            {isLoaded ? <Loader /> : <></>}
          </div>
        </div>
      </div>
    </div>

  );
}

export default Signup