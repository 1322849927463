import React, { useRef, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
/* const defaultSrc =
    "https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg"; */
const defaultSrc = "";
const Reactcropper = (props) => {
  const [image, setImage] = useState("");
  const [file, setFile] = useState("");
  const [croppedimage, setCroppedimage] = useState(defaultSrc);
  const [croppedfile, setCroppedfile] = useState(defaultSrc);

  const cropperRef = useRef(null);
  const onCrop = async () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    const dataURL = cropper.getCroppedCanvas().toDataURL();
    setCroppedimage(dataURL);
    const file = dataURLtoFile(dataURL, "profilepic.png");
    setCroppedfile(file);
  };
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  function blobToFile(theBlob, fileName) {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }
  function dataURItoBlob(dataURI) {
    //base64 to blob
    var arr = dataURI.split(","),
      mime = arr[0].match(/:(.*?);/)[1];
    var blob = new Blob([atob(arr[1])], { type: mime });
    //file to form
    return blob;
  }

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    setFile(files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };
  return (
    <>
      <div className="container">
        <div className="row shadow-sm p-2">
          <div style={{ background: "#DFDFDF" }} className="col-md-8 p-0 m-0">
            <div style={{ minHeight: 250, textAlign: "center" }}>
              {file != null ? (
                <Cropper
                  src={image}
                  style={{ minHeight: 270, width: "100%" }}
                  /*   initialAspectRatio={1} */
                  aspectRatio={4 / 4}
                  crop={onCrop}
                  preview=".img-preview"
                  ref={cropperRef}
                  viewMode={3}
                  movable={false}
                  zoomable={false}
                  guides={true}
                  minCropBoxHeight={100}
                  minCropBoxWidth={100}
                  background={false}
                  responsive={true}
                  checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                />
              ) : (
                <>
                  <span style={{ fontSize: 100, color: "#fafbfc" }}>
                    <i className="fa fa-file-image-o"></i>
                  </span>
                </>
              )}
            </div>
            <div className="border">
              <input
                id="fileupload"
                type="file"
                accept="image/png, image/jpg, image/jpeg"
                onChange={onChange}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex flex-column h-100">
              <div className="flex-fill">
                <img
                  id="imagepreview"
                  src={croppedimage}
                  style={{ width: "100%" }}
                />
              </div>
              <div className="d-flex justify-content-between">
                <button
                  className="btn bt-light"
                  onClick={() => {
                    setImage(null);
                    setCroppedimage(null);
                    setCroppedfile(null);
                    setFile(null);
                    document.getElementById("fileupload").value = null;
                    document.getElementById("imagepreview").value = null;
                  }}
                >
                  Cancel
                </button>
                {/* <button
                onClick={() => props.newFunc(file)}
                className="btn btn-primary"
              >
                Save
              </button> */}
                <button
                  onClick={() => props.newFunc(croppedfile)}
                  className="btn btn-primary"
                  disabled={file === null ? true : false}
                  data-bs-dismiss="modal"
                >
                  save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reactcropper;
