import axios from "axios";
import { serverURL, headeroption, headeroptionupload } from "../http-common";

class EmployeeService {
  getallEmployee(data) {
    return axios.get(serverURL + "/employees-api?req=smarttableEmployee", {
      params: data,
      headers: headeroption,
    })
  }
  getallEmployeejoin(data) {
    return axios.get(serverURL + "/employees-api?req=smarttablejoinEmployee", {
      params: data,
      headers: headeroption,
    })
  }
  getallEmployeejoin(data) {
    return axios.get(serverURL + "/employees-api?req=smarttablejoinEmployee", {
      params: data,
      headers: headeroption,
    })
  }
  getEmployee(emp_id) {
    return axios.get(serverURL + "/employees-api?req=getEmployee", {
      params: { emp_id: emp_id }, headers: headeroption,
    });
  }
  createEmployee(data) {
    return axios.post(serverURL + "/employees-api?req=createEmployee", data, {
      headers: headeroption,
    });
  }
  updateEmployee(data) {
    return axios.put(serverURL + "/employees-api?req=updateEmployee", data, {
      headers: headeroption,
    });
  }
  deleteEmployee(emp_id) {
    return axios.delete(serverURL + "/employees-api?req=deleteEmployee", { params: { emp_id: emp_id }, headers: headeroption, });
  }
  deleteAll() {
    return axios.delete(serverURL + "/employees-api?req=deleteEmployee");
  }
  findByTitle(emp_name) {
    return axios.get(serverURL + "/employee/employees-api?name=" + emp_name);
  }
  upload(emp_id, data) {
    return axios.post(serverURL + "/uploads-api?req=singlefileemp&emp_id=" + emp_id, data, { headers: headeroptionupload });
  }
  uploads(emp_id, data) {
    return axios.post(serverURL + "/employees-api/uploadmultifile?emp_id=" + emp_id, data, { headers: headeroptionupload });
  }
}
export default new EmployeeService();
