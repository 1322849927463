import React from "react";

const Loader = () => {
  return (
    <div
      style={{
        position: "fixed",
        zIndex: 9999,
        width: "100%",
        padding: 10,
        top: 95,
        left: 0,
        
        textAlign: "center",
      }}
      className=""
    >
      <span style={{ background: "white",padding:30 }}>
        <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
        {/*  <i className="fa fa-spinner fa-pulse"></i> */}
        <span className="ps-2 ">Loading..</span>
      </span>
    </div>
  );
};

export default Loader;
