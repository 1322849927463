import React, { useState, useContext } from 'react'
import { Resetlinkvalidationschema } from '../components/Validation'
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import authsService from '../services/auths.service';
import Alertmsg from '../components/Alertmsg';
import Loader from '../components/Loader';

const Resetpasswordlink = () => {
  let navigate = useNavigate();
  const [iserror, setIserror] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [ismessage, setIsmessage] = useState(false);

  const formik = useFormik({
    initialValues: {
      user_email: '',
      user_name: '',
      resetlink: ''
    },
    validationSchema: Resetlinkvalidationschema,
    onSubmit: values => {
      // alert(JSON.stringify(values, null, 2));
    },
  });
  //verify user availability
  const checkuseravailability = (() => {
    setIserror(null)
    setIsmessage(null)
    setIsLoaded(true);
    authsService.passwordresetverify(formik.values).then((res) => {
      console.log(res.data);
      let data = res.data.result;
      if (res.data.result) {
        formik.setValues({
          user_email: data.user_email,
          user_name: data.user_name,
          resetlink: window.location.href.replace("resetlink", "resetpassword?u_token=") + '' + data.token,
        });

        setTimeout(() => {
          sentresetlink();
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
        }, 1000)
        // setIsLoaded(false);
      } else {
        setIsLoaded(false);
        setIserror(res.data.status);
        setIsmessage(res.data.message);
      }
    }).catch((err) => {
      //console.log('iserror' + err);
      setIserror(true);
      setIsmessage(err.message);
      setIsLoaded(false);
      localStorage.setItem('isloggedin', false);
    })
  })
  //sent password reset link
  const sentresetlink = (() => {
    setIserror(null)
    setIsmessage(null)
    setIsLoaded(true);
    authsService.sentpasswordresetlink(formik.values).then((res) => {
      console.log(res.data);
      if (res.data.result) {
        setIsLoaded(false);
        setIserror(res.data.status);
        setIsmessage(res.data.message);
      } else {
        setIserror(res.data.status);
        setIsmessage(res.data.message);
        setIsLoaded(false);
      }
    }).catch((err) => {
      //console.log('iserror' + err);
      setIserror(true);
      setIsmessage(err.message);
      setIsLoaded(false);
      localStorage.setItem('isloggedin', false);
    })
  })
  return (
    <div className='container resetlink-container'>
      <div className=' p-5'>
        <div className="row ">
          <div className="col">
            <h1 className=''>Forgot your password</h1>
            <p className="description ">Please enter the email address you'd like your password reset information sent to</p>
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <label className='form-label me-4' htmlFor="user_email">Enter email address</label>
                  <div className='input-group '>
                    <input
                      className='form-control'
                      id="user_email"
                      type="text"
                      readOnly={iserror}
                      {...formik.getFieldProps('user_email')}
                    />
                  </div>
                  {formik.touched.user_email && formik.errors.user_email ? (
                    <div className='error'>{formik.errors.user_email}</div>
                  ) : null}
                </div>
              </div>
              {iserror ?
                <button type="submit" onClick={checkuseravailability}
                  disabled={!(formik.isValid && formik.dirty)} className="btn btn-primary mt-3" >Resent reset link</button> :
                <button type="submit" onClick={checkuseravailability} disabled={!(formik.isValid && formik.dirty)} className="btn btn-primary mt-3" >Request reset link</button>}
            </form>
          </div>
        </div>
        <div className='mt-2'>
          {/*  loader and iserror handler */}
          {iserror === false || iserror === true ? <Alertmsg alertmsg={ismessage} type={iserror} /> : <></>}
          {isLoaded ? <Loader /> : <></>}
        </div>
      </div>
    </div>
  )
}

export default Resetpasswordlink