import axios from "axios";
export default axios.create({
  baseURL:   "https://www.letscoolsupport.com/lcinventory/letscoolinventory_php_backend",
  headers: {
    "Content-Type": "application/json",
    //"Content-Type": "multipart/form-data",
    withCredentials: false,
  },
});
export const serverURL =
"https://www.letscoolsupport.com/lcinventory/letscoolinventory_php_backend";
export const headeroption = { "content-type": "application/json" };
export const headeroptionupload = { "content-type": "multipart/form-data" };


/* baseURL: "http://localhost/letscool_inventory/letscoolinventory_php_backend",
   serverURL = "https://www.letscoolsupport.com/lcinventory/letscoolinventory_php_backend";
    serverURL = "https://tengah.letscoolsupport.com//tengahinventory/tengahinventory_php_backend";
 */
