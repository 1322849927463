import axios from "axios";
import { serverURL, headeroption, headeroptionupload } from "../http-common";

class CategoryService {
    getallCompany(data) {
        return axios.get(serverURL + "/company-api?req=smarttableCompany", {
            params: data,
            headers: headeroption,
        })
    }
    getallCategory(data) {
        return axios.get(serverURL + "/category-api?req=smarttableCategory", {
          params: data,
          headers: headeroption,
        })
      }
      getCategory(category_id) {
        return axios.get(serverURL + "/category-api?req=getCategory", {
          params: { category_id: category_id }, headers: headeroption,
        });
      }
      createCategory(data) {
        return axios.post(serverURL + "/category-api?req=createCategory", data, {
          headers: headeroption,
        });
      }
      updateCategory(data) {
        return axios.put(serverURL + "/category-api?req=updateCategory", data, {
          headers: headeroption,
        });
      }
      deleteCategory(category_id) {
        return axios.delete(serverURL + "/category-api?req=deleteCategory", { params: { category_id: category_id }, headers: headeroption, });
      }
      deleteAll() {
        return axios.delete(serverURL + "/category-api?req=deleteCategory");
      }
      findByTitle(category_name) {
        return axios.get(serverURL + "/user/category-api?name=" + category_name);
      }
      upload(category_id, data) {
        return axios.post(serverURL + "/category-api/uploadsinglefile?category_id=" + category_id, data, { headers: headeroptionupload });
      }
      uploads(category_id, data) {
        return axios.post(serverURL + "/category-api/uploadmultifile?category_id=" + category_id, data, { headers: headeroptionupload });
      }
}
export default new CategoryService();
