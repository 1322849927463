import React, { useEffect, useLayoutEffect, useState } from "react";
import analyticsService from "../services/analytics.service";
import authsService from "../services/auths.service";
import categoryService from "../services/category.service";
import { useNavigate, useSearchParams } from "react-router-dom";
import Googlecharts from "../components/Googlecharts";
import { DateRangePicker, Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";
export const Comstatistics = (props) => {
  const userinfo = authsService.userinfo();
  let navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  //smart table data
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState("");
  const [filtercategory, setFiltercategory] = useState("compressor");
  const [search, setSearch] = useState("");
  const [between, setBetween] = useState("");
  const [sort, setSort] = useState("");
  const [group, setGroup] = useState("mat_name");
  const [employeestacklist, setEmployeestacklist] = useState([]);
  const [categorylist, setCategorylist] = useState([]);
  const [materialemployeeusage, setMaterialemployeeusage] = useState([]);

  //date range
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };
  const handleSelectdate = async (date) => {
    await setStartDate(date.selection.startDate);
    await setEndDate(date.selection.endDate);
    setBetween(
      format(date.selection.startDate, "yyyy-MM-dd") +
        " , " +
        format(date.selection.endDate, "yyyy-MM-dd")
    );
  };
  const resetdaterange = () => {
    setBetween("");
    setStartDate(new Date());
    setEndDate(new Date());
  };
  //use effects
  useEffect(() => {
    getallCompanyoverview();
    getallCategory();
  }, []);
  useLayoutEffect(() => {
    getallCompanyoverview();
  }, [filtercategory]);

  useLayoutEffect(() => {
    if (props.com_id == null || props.com_id == undefined) {
      getallCompanyoverview();
    } else {
      getallCompanyoverview();
    }
  }, [props.com_id]);

  useLayoutEffect(() => {
    getallCompanyoverview();
  }, [between]);

  const getallCompanyoverview = async () => {
    var data = {
      limit: 100,
      page: page,
      filter: "",
      filtercategory: filtercategory
        ? filtercategory
        : "compressor" /* filtercategory */,
      /*    filtercompany: props.com_id ? props.com_id : "", */
      filtercompany: props.com_id ? props.com_id : "",
      search: "",
      between: between,
      sort: "mat_name ASC",
      group: "mat_id",
    };
    return await analyticsService
      .getallcompanyStacklist(data)
      .then((res) => {
        console.log(res.data.result.data);
        if (res.data.result) {
          setEmployeestacklist(res.data.result.data);
          const data = [
            ["Name", "Material In", "Material Out"],
            ...res.data.result.data.map((d) => [
              d.mat_name,
              Number(d.mat_in_qty),
              Number(d.mat_out_qty),
            ]),
          ];
          setMaterialemployeeusage(data);
        } else {
          setEmployeestacklist([]);
        }
      })
      .catch((err) => {
        setEmployeestacklist([]);
      });
  };
  //get allcategory
  const getallCategory = async () => {
    var data = {
      limit: 10,
      page: 1,
      filter: "",
      filtercategory: "main",
      search: "",
      between: "",
      sort: "category_name ASC",
      group: "",
    };
    return await categoryService
      .getallCategory(data)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          setCategorylist(res.data.result.data);
        } else {
          setCategorylist([]);
        }
      })
      .catch((err) => {
        setCategorylist([]);
      });
  };
  return (
    <div>
      {/* categoryList */}
      <div className="d-flex flex-wrap pb-4 ">
        {categorylist.map((category, index) => (
          <div className="text-capitalize" key={index}>
            <a
              onClick={() => {
                setFiltercategory(category.category_name);
              }}
              className={
                filtercategory === "compressor" && filtercategory === null
                  ? "btn btn-outline-primary"
                  : filtercategory === category.category_name
                  ? "btn btn-outline-primary"
                  : "btn"
              }
            >
              {category.category_name}
            </a>
          </div>
        ))}
      </div>
      <div>
        <div className="row">
          <div className="col-md-9">
            <div>
              <div className="bg-white  d-flex ">
                <div className="flex-fill p-2">
                  <strong> Material usage</strong>
                </div>
                <button
                  className="btn bg-light m-1 dropdown-toggle"
                  id="dropdownMenuClickableInside"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  <i className="fa fa-calendar" aria-hidden="true"></i>{" "}
                  {between}
                  {between ? (
                    <a
                      className="ms-2 me-2 p-1 text-secondary"
                      onClick={resetdaterange}
                    >
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </a>
                  ) : (
                    <></>
                  )}
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuClickableInside"
                >
                  <DateRangePicker
                    ranges={[selectionRange]}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    direction="horizontal"
                    onChange={(date) => handleSelectdate(date)}
                  />
                </ul>
              </div>
            </div>
            <div className="bg-white">
              <Googlecharts
                com_data={materialemployeeusage}
                chart_type="BarChart"
                haxis="Qty/nos"
                vaxis="Name"
              />
            </div>
          </div>
          <div className="col-md-3 ">
            <div className="teble-responsive bg-white h-100 p-2">
              <table className="table  ">
                <thead>
                  <tr>
                    <th>Material</th>
                    <th>In</th>
                    <th>Out</th>
                  </tr>
                </thead>
                <tbody>
                  {employeestacklist.map((employeestack, index) => (
                    <tr key={index}>
                      <td>{employeestack.mat_name}</td>
                      <td>{employeestack.mat_in_qty}</td>
                      <td>{employeestack.mat_out_qty}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};
