import axios from "axios";
import { serverURL,headeroption,headeroptionupload } from "../http-common";

class UserService {
  getallUser(data) {
    return axios.get(serverURL + "/users-api?req=smarttableUser", {
      params: data,
      headers: headeroption,
    })
  }
  getUser(user_id) {
    return axios.get(serverURL + "/users-api?req=getUser", {
      params: { user_id: user_id }, headers: headeroption,
    });
  }
  createUser(data) {
    return axios.post(serverURL + "/users-api?req=createUser", data, {
      headers: headeroption,
    });
  }
  updateUser(data) {
    return axios.put(serverURL + "/users-api?req=updateUser", data, {
      headers: headeroption,
    });
  }
  deleteUser(user_id) {
    return axios.delete(serverURL + "/users-api?req=deleteUser", { params: { user_id: user_id }, headers: headeroption, });
  }
  deleteAll() {
    return axios.delete(serverURL + "/users-api?req=deleteUser");
  }
  findByTitle(user_name) {
    return axios.get(serverURL + "/user/users-api?name=" + user_name);
  }
  upload(user_id, data) {
    return axios.post(serverURL + "/uploads-api?req=singlefile&user_id=" + user_id, data, { headers: headeroptionupload });
  }
  uploads(user_id, data) {
    return axios.post(serverURL + "/uploads-api/uploadmultifile?user_id=" + user_id, data, { headers: headeroptionupload });
  }
}
export default new UserService();
