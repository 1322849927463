import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
export const data1 = [
  { year: "2013", sales: 1000, expanses: 400 },
  { year: "2014", sales: 232, expanses: 4600 },
  { year: "2015", sales: 3342, expanses: 655 },
  { year: "2016", sales: 5400, expanses: 654 },
];
export const data = [
  ["Year", "Sales", "Expenses"],
  ["2013", 1000, 400],
  ["2014", 1170, 460],
  ["2015", 660, 1120],
  ["2016", 1030, 540],
];

const Googlecharts = (props) => {
  const [chartdata, setChartdata] = useState([]);
  const options = {
    //tooltip
    tooltip: {
      isHtml: true
    },
    //line
    title: props.title,
    titlePosition: "center",
    isStacked: false, //true,false,relative
    //legend: { position: "bottom", maxLines: 3 },
    legend: { position: "top", alignment: "center" },
    hAxis: { title: props.haxis, titleTextStyle: { color: "#191919" } },
    vAxis: {
      title: props.vaxis,
      titleTextStyle: { color: "#191919" },
      minValue: 0,
    },
    /*  chartArea: { width: "100%", height: "70%" }, */
    colors: ["#1F66F3", "#2ECC71", "#3498DB", "#BA4A00", "#7D3C98", "#E11515"],
    backgroundColor: 'transparent',
    focusTarget: "category",
    //bar
    bar: { groupWidth: "30%" },
    axes: {
      y: {
        0: { side: "left" },
      },
      x: {
        0: { side: "bottom" },
      },
    },
    //pie
    pieHole: 0.4,
    is3D: false,
  };

  useEffect(() => {
    console.log(props.com_data);
    /* getdata(); */
  }, []);
  const getdata = () => {
    const data = [
      ["year", "sales", "expanses"],
      ...data1.map((d) => [d.year, Number(d.sales), Number(d.expanses)]),
    ];
    setChartdata(data);
  };

  return (
    <Chart
      chartType={props.chart_type}
      width="100%"
      height="700px"
      data={props.com_data}
      options={options}
    />
  );
};

export default Googlecharts;
