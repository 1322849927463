import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  useToggle,
} from "react";
import { Link, Route, Routes, NavLink } from "react-router-dom";
import { AuthContext } from "../context/Authcontext";
import Home from "../pages/Home";
import Profile from "../pages/Profile";
import Resetpassword from "../pages/Resetpassword";
import Resetpasswordlink from "../pages/Resetpasswordlink";
import Signin from "../pages/Signin";
import Signina from "../pages/Signina";
import Signup from "../pages/Signup";
import Notfound from "./Notfound";

const Homelayout = () => {
  const { signOut } = useContext(AuthContext);
  //signout part
  const signOuts = () => signOut;
  useEffect(() => {
    /*  setHeaderheight(ref.current.clientHeight) */
  });
  return (
    <div className="home-layout-container">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signina" element={<Signina />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/resetpassword" element={<Resetpassword />} />
        <Route path="/resetlink" element={<Resetpasswordlink />} />
        <Route path="*" element={<Notfound />} />
        
      </Routes>
    </div>
  );
};

export default Homelayout;
