import logo from "./logo.svg";
import "./App.css";
import React, { useEffect, useState, useMemo, useLayoutEffect } from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { AuthContext } from "./context/Authcontext";
import Homelayout from "./components/Homelayout";
import Dashboardlayout from "./components/Dashboardlayout";
import authsService from "./services/auths.service";
import Employeelayout from "./components/Employeelayout";

function App() {
  const userinfo = authsService.userdata();

  const [userToken, setUserToken] = useState("");
  const [userRole, setUserRole] = useState("");
  const [logintype, setLogintype] = useState("");

  useEffect(() => {
    setUserToken(userinfo.token);
    setUserRole(userinfo.user_role);
    setLogintype(userinfo.login_type);
  }, []);
  useLayoutEffect(() => {
    setUserToken(userinfo.token);
    setUserRole(userinfo.user_role);
    setLogintype(userinfo.login_type);
  }, [userinfo]);
  const authContext = useMemo(() => ({
    signIn: () => {
      //localStorage.setItem("isloggedin", "token");
      setUserToken(userinfo.token);
      setUserRole(userinfo.user_role);
      setLogintype(userinfo.login_type);
      window.location.href = "/";
     /*  window.location.href = "/lcinventory"; */

    },
    signOut: () => {
      localStorage.clear();
      setUserToken(null);
      setUserRole(null);
      setLogintype(null);
      window.location.href = "/";
    },
    signUp: () => {
      //localStorage.setItem("isloggedin", "token");
      localStorage.clear();
      window.location.href = "/";
    },
  }));
  return (
    <AuthContext.Provider value={authContext}>
      <Router>
        {userToken === null ? (
          <Homelayout />
        ) : userToken === false ? (
          <Homelayout />
        ) : logintype === "employee" ? (
          <Employeelayout />
        ) : logintype === "dashboard" ? (
          <Dashboardlayout />
        ) : (
          <Homelayout />
        )}
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
