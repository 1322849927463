import React, { useEffect, useLayoutEffect, useState } from "react";
import { useFormik } from "formik";
import appoinmentService from "../services/inventory.service";
import { Appoinmentvalidationschema } from "../components/Validation";
import ReactPaginate from "react-paginate";
import { DateRangePicker, Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";
import Loader from "../components/Loader";
import Toastmessage from "../components/Toastmessage";
import { Link, useNavigate, useSearchParams /*  */ } from "react-router-dom";
import Alertmsg from "../components/Alertmsg";
import imagename from "../assets/images/user.png";
import analyticsService from "../services/analytics.service";
import categoryService from "../services/category.service";
import employeeService from "../services/employee.service";
import axios from "axios";
import materialService from "../services/material.service";
import Googlecharts from "../components/Googlecharts";

const Statistics = ({ navigation }) => {
  let navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const [iserror, setIserror] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [ismessage, setIsmessage] = useState(false);
  const [materials, setMaterials] = useState([]);
  const [materialsusage, setMaterialsusage] = useState([]);
  const [materialsusagearr, setMaterialsusagearr] = useState([]);
  const [materialcompanyusage, setMaterialcompanyusage] = useState([]);
  const [materialcompanyusagearr, setMaterialcompanyusagearr] = useState([]);

  const [employeedata, setEmployeedata] = useState([]);
  const [result, setResult] = useState([]);
  //smart table data
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState("");
  const [filtercategory, setFiltercategory] = useState("");
  const [search, setSearch] = useState("");
  const [between, setBetween] = useState("");
  const [sort, setSort] = useState("");
  const [group, setGroup] = useState("mat_id");

  const [pagecount, setPagecount] = useState("");
  const [resultCount, setResultcount] = useState("");
  const [categorylist, setCategorylist] = useState([]);
  const [employees, setEmployees] = useState([]);

  //filter arrays
  const statusfilterarr = [
    { status_id: "01", status_name: "pending" },
    { status_id: "02", status_name: "active" },
    { status_id: "03", status_name: "inactive" },
  ];
  const [statusfilterarray, setStatusfilterarray] = useState(statusfilterarr);
  //date range
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };
  const handleSelectdate = async (date) => {
    await setStartDate(date.selection.startDate);
    await setEndDate(date.selection.endDate);
    setBetween(
      format(date.selection.startDate, "yyyy-MM-dd") +
        " , " +
        format(date.selection.endDate, "yyyy-MM-dd")
    );
  };
  const resetdaterange = () => {
    setBetween("");
    setStartDate(new Date());
    setEndDate(new Date());
  };

  //use effects
  useEffect(() => {
    getallCategory();
    getallemployeeMaterialUsage(searchParams.get("mat_id"));
    getallcompanyMaterialUsage(searchParams.get("mat_id"));
  }, []);

  /* useLayoutEffect(() => {
    getallEmployee();
  }, [search, filter]); */

  useLayoutEffect(() => {
    if (
      searchParams.get("category") == null ||
      searchParams.get("category") == undefined
    ) {
      getallMaterial("compressor");
    } else {
      getallMaterial(searchParams.get("category"));
    }
  }, [searchParams.get("category")]);
  useLayoutEffect(() => {
    if (
      searchParams.get("mat_id") == null ||
      searchParams.get("mat_id") == undefined
    ) {
      getallemployeeMaterialUsage("sdfsdsdsdsds");
      getallcompanyMaterialUsage("dhfdusfuhsudf");
    } else {
      getallemployeeMaterialUsage(searchParams.get("mat_id"));
      getallcompanyMaterialUsage(searchParams.get("mat_id"));
    }
  }, [searchParams.get("mat_id"), between]);
  const resfresh = () => {
    if (
      searchParams.get("mat_id") == null ||
      searchParams.get("mat_id") == undefined
    ) {
      getallemployeeMaterialUsage("sdfsdsdsdsds");
      getallcompanyMaterialUsage("dhfdusfuhsudf");
    } else {
      getallemployeeMaterialUsage(searchParams.get("mat_id"));
      getallcompanyMaterialUsage(searchParams.get("mat_id"));
    }
  };
  //get allcategory
  const getallemployeeMaterialUsage = async (value) => {
    var data = {
      limit: 50,
      page: 1,
      filter: filter,
      filtercategory: "",
      filtermaterial: value ? value : "fhgjfjgsd",
      search: "",
      between: between,
      sort: "",
      group: "emp_username",
    };
    return await analyticsService
      .getallemployeeMaterialUsage(data)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          // setIserror(res.data.status);
          //setIsmessage(res.data.message);
          setMaterialsusagearr(res.data.result.data);
          if (res.data.result.data.length > 0) {
            const data = [
              ["Name", "Material In", "Material Out"],
              ...res.data.result.data.map((d) => [
                d.emp_fullname,
                Number(d.mat_in_qty),
                Number(d.mat_out_qty),
              ]),
            ];
            setMaterialsusage(data);
          } else {
            setMaterialsusage([]);
          }
        } else {
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
      });
  };
  const getallcompanyMaterialUsage = async (value) => {
    var data = {
      limit: 50,
      page: 1,
      filter: filter,
      filtercategory: "",
      filtermaterial: value ? value : "fhgjfjgsd",
      search: "",
      between: between,
      sort: "",
      group: "com_id",
    };
    return await analyticsService
      .getallcompanyMaterialUsage(data)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          // setIserror(res.data.status);
          //setIsmessage(res.data.message);
          setMaterialcompanyusagearr(res.data.result.data);
          if (res.data.result.data.length > 0) {
            const data = [
              ["Name", "Material In", "Material Out"],
              ...res.data.result.data.map((d) => [
                d.com_fullname,
                Number(d.mat_in_qty),
                Number(d.mat_out_qty),
              ]),
            ];
            setMaterialcompanyusage(data);
          } else {
            setMaterialcompanyusage([]);
          }
        } else {
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
      });
  };
  //get allcategory
  const getallMaterial = async (value) => {
    setIsLoaded(true);
    var data = {
      limit: 30,
      page: 1,
      filter: "",
      filtercategory: value ? value : "",
      search: "",
      between: "",
      sort: "mat_name ASC",
      group: "",
    };
    return await materialService
      .getallMaterial(data)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          setIsLoaded(false);
          // setIserror(res.data.status);
          //setIsmessage(res.data.message);
          setMaterials(res.data.result.data);
          setResult(res.data);
          setPage(res.data.result.page);
          setLimit(res.data.result.limit);
          setPagecount(res.data.result.pagecount);
          setResultcount(res.data.result.resultcount);
          console.log(res.data);
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
      });
  };
  //get allemployee
  const getallEmployee = async () => {
    setIserror(null);
    setIsmessage(null);
    setIsLoaded(true);
    var data = {
      limit: limit,
      page: page,
      filter: filter,
      search: search,
      between: "",
      sort: "emp_fullname ASC",
      group: "",
    };
    return await employeeService
      .getallEmployee(data)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);

          setEmployees(res.data.result.data);
          setResult(res.data);
          setPage(res.data.result.page);
          setLimit(res.data.result.limit);
          setPagecount(res.data.result.pagecount);
          setResultcount(res.data.result.resultcount);
          console.log(res.data);
        } else {
          setIserror(false);
          setIsmessage(res.data.message);
          setIsLoaded(false);
        }
      })
      .catch((err) => {
        setIserror(false);
        setIsmessage(err.message);
        setIsLoaded(false);
      });
  };

  //pagination
  const paginationfn = (event) => {
    setPage(event.selected + 1);
    console.log(event.selected + 1);
  };
  //search
  const searchfn = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };
  //filter
  const [filterArr, setfilterArr] = useState([]);
  const filterfn = (value) => {
    setPage(1);
    var index = filterArr.indexOf(value);
    if (index > -1) {
      filterArr.splice(index, 1);
      setFilter(filterArr.toString());
    } else {
      filterArr.push(value);
      setFilter(filterArr.toString());
    }
  };
  //sort
  const sortfn = (value) => {
    /*    setSort(value); */
    if (sort === value + " desc") {
      setSort(value + " asc");
      console.log(value + " asc");
    } else if (sort === value + " asc") {
      setSort("");
      console.log(value + "");
    } else {
      setSort(value + " desc");
      console.log(value + " desc");
    }
  };
  //get allcategory
  const getallCategory = async () => {
    var data = {
      limit: 30,
      page: 1,
      filter: "",
      filtercategory: "main",
      search: "",
      between: "",
      sort: "category_name ASC",
      group: "",
    };
    return await categoryService
      .getallCategory(data)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          setCategorylist(res.data.result.data);
        } else {
          setCategorylist([]);
        }
      })
      .catch((err) => {
        setCategorylist([]);
      });
  };
  const selectedcolor = (value) => {
    console.log(value);
    if (value === "installation") {
      return "badge rounded-pill bg-success";
    } else if (value === "servicing") {
      return "badge rounded-pill bg-warning ";
    } else if (value === "contract") {
      return "badge rounded-pill bg-danger";
    } else if (value === "rectification") {
      return "badge rounded-pill bg-danger";
    } else if (value === null) {
      return "badge rounded-pill bg-success";
    } else {
      return "text-primary mr-2 p-2";
    }
  };

  // Call appoinment clicks to request another page.
  if (ismessage == "Network Error") {
    return <Alertmsg alertmsg={ismessage} type={iserror} />;
  }
  /* if (isLoaded == true) {
    return <Loader />
  } */

  return (
    <div className="">
      {/*  container */}
      <div className="appoinment-container">
        <div className="d-flex flex-wrap">
          <div className="flex-fill">
            <h2>Statistics</h2>
          </div>

          <div className="">
            <button
              className="btn bg-white dropdown-toggle"
              id="dropdownMenuClickableInside"
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
              aria-expanded="false"
            >
              <i className="fa fa-calendar" aria-hidden="true"></i> {between}
              {between ? (
                <a
                  className="ms-2 me-2 p-1 text-secondary"
                  onClick={resetdaterange}
                >
                  <i className="fa fa-times" aria-hidden="true"></i>
                </a>
              ) : (
                <></>
              )}
            </button>
            <ul
              className="dropdown-menu"
              aria-labelledby="dropdownMenuClickableInside"
            >
              <DateRangePicker
                ranges={[selectionRange]}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                direction="horizontal"
                onChange={(date) => handleSelectdate(date)}
              />
            </ul>
          </div>
          <div className="ms-2">
            <a
              onClick={() => {
                resfresh();
              }}
              className="btn btn-light"
            >
              <i className="fa fa-refresh"></i>
            </a>
          </div>
        </div>
        <div className="pb-3 d-flex  flex-wrap">
          <ul className="nav nav-pills " id="pills-tab" role="tablist">
            {categorylist.map((categorylist, index) => (
              <li key={index} className="nav-item" role="presentation">
                <button
                  className={
                    categorylist.category_name === "compressor" &&
                    searchParams.get("category") === null
                      ? "nav-link active text-capitalize"
                      : searchParams.get("category") ===
                        categorylist.category_name
                      ? "nav-link active text-capitalize"
                      : "nav-link text-capitalize"
                  }
                  onClick={() =>
                    navigate(
                      "/statistics?category=" + categorylist.category_name
                    )
                  }
                  type="button"
                  role="tab"
                  aria-selected="true"
                >
                  {categorylist.category_name}
                </button>
              </li>
            ))}
          </ul>
        </div>
        {/*  <div>
          Filter by Material
        </div> */}
        <div className=" d-flex  flex-wrap  p-3">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            {/*   <span className="p-0 me-2"> <i className="fa fa-filter"></i></span> */}
            {materials.map((materials, index) => (
              <li key={index} className="nav-item" role="presentation">
                <span
                  className={
                    materials.category === "compressor" &&
                    searchParams.get("mat_id") === null
                      ? "badge bg-secondary me-1 active "
                      : searchParams.get("mat_id") === materials.mat_id
                      ? "badge bg-success me-1"
                      : "badge bg-secondary me-1"
                  }
                  onClick={() =>
                    /* console.log() */
                    navigate(
                      "/statistics?category=" +
                        searchParams.get("category") +
                        "&mat_id=" +
                        materials.mat_id
                    )
                  }
                  type="button"
                  role="tab"
                  aria-selected="true"
                >
                  {materials.mat_name}
                </span>
              </li>
            ))}
          </ul>
        </div>
        {/* tebale header part search,filter,sort */}
        <div className="d-md-flex  mb-2 ">
          {/* create appoinment */}
          {/*  <div className="m-1">
            <button
              onClick={() => {
                navigate("/appoinmentbooking");
              }}
              type="submit"
              className="btn btn-primary  w-100"
            >
              <i className="fa fa-plus-circle"></i> Create
            </button>
          </div> */}
          {/* filter status*/}
          <div className="dropdown d-none m-1">
            <button
              className="btn btn-primary w-100 dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fa fa-filter" aria-hidden="true"></i> Filter
            </button>
            <ul
              className="dropdown-menu p-2"
              aria-labelledby="dropdownMenuButton1"
            >
              <li className="text-secondary pb-1 pt-1">Status</li>
              {statusfilterarr.map((statusfilterarr, index) => (
                <li key={index}>
                  <div className="form-check">
                    <input
                      onChange={(e) => filterfn(statusfilterarr.status_name)}
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    ></input>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      {statusfilterarr.status_name}
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          {/* search */}
          <div className="d-none m-1">
            <input
              className="form-control"
              onChange={searchfn}
              placeholder="search"
              type="search"
            ></input>
          </div>
          {/*  date range  */}
          <div className="m-1"></div>
          {/* between */}

          {/*  sort */}
          <div className="m-1 ms-auto">
            <div className="input-group d-none">
              <span
                className="input-group-text bg-white border-end-0"
                id="basic-addon1"
              >
                Sort :{" "}
              </span>
              <select
                value={sort}
                onChange={(e) => {
                  setSort(e.target.value);
                }}
                className="form-select border-start-0"
                aria-label="emp_status"
              >
                <option value="" disabled>
                  Select
                </option>
                <option value="appt_date">Appoinment date</option>
                <option value="appt_category">Category</option>
                <option value="">Clear</option>
              </select>
            </div>
          </div>
        </div>
        {/* table section*/}
        <div className="row g-2">
          <div className="col-md-8">
            <div className="">
              <h2 className="p-2">Employee Material Usage Chart</h2>
              {materialsusage.length > 0 ? (
                <>
                  <Googlecharts
                    com_data={materialsusage}
                    chart_type="BarChart"
                    haxis="Qty/nos"
                    vaxis="Name"
                  />
                </>
              ) : (
                <div className="p-2">No data Found</div>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="  h-100">
              <h5 className="p-2">Employee Material Usage</h5>
              <div className="table-responsive  border-0  ">
                {/*  all */}
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>name</th>
                      <th style={{ width: 100 }}>in</th>
                      <th style={{ width: 100 }}>out</th>
                    </tr>
                  </thead>
                  <tbody>
                    {materialsusagearr.map((data, index) => (
                      <tr key={index}>
                        <td>
                          <div className="text-capitalize">
                            {data.emp_fullname}
                          </div>
                        </td>
                        <td>
                          <div>{data.mat_in_qty}</div>
                        </td>
                        <td>
                          <div>{data.mat_out_qty}</div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-2 mt-2 border-top border-5 border-white">
          <div className="col-md-8">
            <div className="">
              <h2 className="p-2">Company Material Usage Chart</h2>
              <div className="p-2"></div>
              {materialcompanyusage.length > 0 ? (
                <>
                  <Googlecharts
                    com_data={materialcompanyusage}
                    chart_type="BarChart"
                    haxis="Qty/nos"
                    vaxis="Name"
                  />
                </>
              ) : (
                <div className="p-2">No data Found</div>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className=" h-100">
              <h5 className="p-2">Company Material Usage</h5>
              <div className="table-responsive  border-0 ">
                {/*  all */}
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>name</th>
                      <th style={{ width: 100 }}>in</th>
                      <th style={{ width: 100 }}>out</th>
                    </tr>
                  </thead>
                  <tbody>
                    {materialcompanyusagearr.map((data, index) => (
                      <tr key={index}>
                        <td>
                          <div className="text-capitalize">
                            {data.com_fullname}
                          </div>
                        </td>
                        <td>
                          <div>{data.mat_in_qty}</div>
                        </td>
                        <td>
                          <div>{data.mat_out_qty}</div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className=" card border-0 bg-white mb-2">
          {/*  all */}

          <div className="text-center">
            {/*  loader and error handler */}
            {/*  {<Toastmessage ref={ref} type={error} errormsg={ismessage} />} */}
            {isLoaded ? <Loader /> : <></>}
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Statistics;
