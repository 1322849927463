import React, { useEffect, useLayoutEffect, useState } from "react";
import { useFormik } from "formik";
import { materialvalidationschema } from "../components/Validation";
import ReactPaginate from "react-paginate";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";
import Loader from "../components/Loader";
import Toastmessage from "../components/Toastmessage";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Alertmsg from "../components/Alertmsg";
import analyticsService from "../services/analytics.service";
import categoryService from "../services/category.service";
const Stack = ({ navigation }) => {
  let navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const [iserror, setIserror] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [ismessage, setIsmessage] = useState(false);

  const [eventname, setEventname] = useState("create");

  const [materials, setmaterials] = useState([]);
  const [categorylist, setCategorylist] = useState([]);

  const [result, setResult] = useState([]);
  //smart table data
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [filter, setFilter] = useState("");
  const [filtercategory, setFiltercategory] = useState(
    searchParams.get("category") == null
      ? ""
      : searchParams.get("category") === undefined
      ? ""
      : searchParams.get("category")
  );

  const [search, setSearch] = useState("");
  const [between, setBetween] = useState("");
  const [sort, setSort] = useState("");
  const [group, setGroup] = useState("mat_id");

  const [pagecount, setPagecount] = useState("");
  const [resultCount, setResultcount] = useState("");

  //filter arrays
  const statusfilterarr = [
    { status_id: "01", status_name: "pending" },
    { status_id: "02", status_name: "active" },
    { status_id: "03", status_name: "inactive" },
  ];
  const [filterarray, setFilterarray] = useState(statusfilterarr);
  //date range
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };
  const handleSelectdate = async (date) => {
    await setStartDate(date.selection.startDate);
    await setEndDate(date.selection.endDate);
    setBetween(
      format(date.selection.startDate, "yyyy-MM-dd") +
        " , " +
        format(date.selection.endDate, "yyyy-MM-dd")
    );
  };
  const resetdaterange = () => {
    setBetween("");
    setStartDate(new Date());
    setEndDate(new Date());
  };

  //use effects
  useEffect(() => {
    getallStacklist();
    getallCategory();
  }, []);

  useLayoutEffect(() => {
    getallStacklist();
  }, [searchParams.get("category")]);

  useLayoutEffect(() => {
    getallStacklist();
  }, [page, limit, filter, filtercategory, search, between, sort]);
  //get allcategory
  const getallCategory = async () => {
    var data = {
      limit: 30,
      page: 1,
      filter: "",
      filtercategory: "main",
      search: "",
      between: "",
      sort: "",
      group: "",
    };
    return await categoryService
      .getallCategory(data)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          setCategorylist(res.data.result.data);
        } else {
          setCategorylist([]);
        }
      })
      .catch((err) => {
        setCategorylist([]);
      });
  };

  //get allmaterial
  const getallStacklist = async () => {
    setIsLoaded(true);
    var data = {
      limit: limit,
      page: page,
      filter: filter,
      filtercategory: filtercategory,
      search: search,
      between: between,
      sort: sort,
      group: group,
    };
    return await analyticsService
      .getallStacklist(data)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          setIsLoaded(false);
          /*             setIserror(res.data.status);
                        setIsmessage(res.data.message); */
          setmaterials(res.data.result.data);
          setResult(res.data);
          setPage(res.data.result.page);
          setLimit(res.data.result.limit);
          setPagecount(res.data.result.pagecount);
          setResultcount(res.data.result.resultcount);
          console.log(res.data);
        } else {
          setIserror(false);
          setIsmessage(res.data.message);
          setIsLoaded(false);
        }
      })
      .catch((err) => {
        setIserror(false);
        setIsmessage(err.message);
        setIsLoaded(false);
      });
  };
  //pagination
  const paginationfn = (event) => {
    setPage(event.selected + 1);
    console.log(event.selected + 1);
  };
  //search
  const searchfn = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };
  //filter
  const [filterArr, setfilterArr] = useState([]);
  const filterfn = (value) => {
    setPage(1);
    var index = filterArr.indexOf(value);
    if (index > -1) {
      filterArr.splice(index, 1);
      setFilter(filterArr.toString());
    } else {
      filterArr.push(value);
      setFilter(filterArr.toString());
    }
  };
  //filter
  const [filtercategoryArr, setFiltercategoryArr] = useState([]);
  const filtercategoryfn = (value) => {
    setPage(1);
    var index = filtercategoryArr.indexOf(value);
    if (index > -1) {
      filtercategoryArr.splice(index, 1);
      setFiltercategory(filtercategoryArr.toString());
    } else {
      filtercategoryArr.push(value);
      setFiltercategory(filtercategoryArr.toString());
    }
  };
  //sort
  const sortfn = (value) => {
    /*    setSort(value); */
    if (sort === value + " desc") {
      setSort(value + " asc");
      console.log(value + " asc");
    } else if (sort === value + " asc") {
      setSort("");
      console.log(value + "");
    } else {
      setSort(value + " desc");
      console.log(value + " desc");
    }
  };
  //between
  const [betweenstart, setBetweenstart] = useState("");
  const [betweenend, setBetweenend] = useState("");
  const betweenfn = (value, e) => {
    setPage(1);
    if (e === null || e === "") {
      setBetween("");
      setBetweenstart("");
      setBetweenend("");
    } else {
      if (value === "start") {
        setBetween(e + "," + e);
        console.log(e + "," + e);
        setBetweenstart(e);
        setBetweenend(e);
      } else if (value === "end") {
        setBetweenend(e);
        const dd = between.split(",");
        setBetween(dd[0] + "," + e);
        console.log(dd[0] + "," + e);
        setBetweenstart(dd[0]);
        setBetweenend(e);
      } else {
        setBetween("");
        setBetweenstart("");
        setBetweenend("");
      }
    }
  };
  const selectedcolor = (value) => {
    if (value > 10) {
      return "badge rounded-pill p-2 ps-4 pe-4 bg-success";
    } else if (value > 3) {
      return "badge rounded-pill p-2 ps-4 pe-4 bg-warning ";
    } else if (value > 0) {
      return "badge rounded-pill p-2 ps-4 pe-4 bg-danger";
    } else {
      return "badge rounded-pill p-2 ps-4 pe-4 bg-dark";
    }
  };
  // Call material clicks to request another page.
  if (ismessage == "Network Error") {
    return <Alertmsg alertmsg={ismessage} type={iserror} />;
  }
  /* if (isLoaded == true) {
    return <Loader />
  } */

  return (
    <div className="">
      <div className="flex-fill mb-3">
        <h2>Stack</h2>
      </div>
      <div className="text-center">
        {/*  loader and error handler */}
        {/*  {<Toastmessage ref={ref} type={error} errormsg={ismessage} />} */}
        {isLoaded ? <Loader /> : <></>}
      </div>
      {/*  container */}
      <div className="material-container">
        <div className="pb-4 d-flex  flex-wrap">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={
                  searchParams.get("category") === undefined ||
                  searchParams.get("category") === null
                    ? "nav-link active text-capitalize"
                    : "nav-link text-capitalize"
                }
                onClick={() => {
                  setFiltercategory("");
                  navigate("/stack");
                }}
                type="button"
                role="tab"
                aria-selected="true"
              >
                All
              </button>
            </li>
            {categorylist.map((categorylist, index) => (
              <li key={index} className="nav-item" role="presentation">
                <button
                  className={
                    categorylist.category_name === "" &&
                    searchParams.get("category") === null
                      ? "nav-link active text-capitalize"
                      : searchParams.get("category") ===
                        categorylist.category_name
                      ? "nav-link active text-capitalize"
                      : "nav-link text-capitalize"
                  }
                  onClick={() => {
                    setFiltercategory(categorylist.category_name);
                    navigate("/stack?category=" + categorylist.category_name);
                  }}
                  type="button"
                  role="tab"
                  aria-selected="true"
                >
                  {categorylist.category_name}
                </button>
              </li>
            ))}
          </ul>
        </div>
        {/* tebale header part search,filter,sort */}
        <div className="d-md-flex  mb-2 ">
          {/* create material */}
          {/* <div className="m-1">
            <button
              onClick={() => {
                navigate("/materialevent");
              }}
              type="submit"
              className="btn btn-primary  w-100"
            >
              <i className="fa fa-plus-circle"></i> Create
            </button>
          </div> */}
          {/* filter category*/}
          <div className="dropdown m-1 d-none">
            <button
              className="btn btn-primary w-100 dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fa fa-filter" aria-hidden="true"></i> Category
            </button>
            <ul
              className="dropdown-menu p-2"
              aria-labelledby="dropdownMenuButton1"
            >
              <li className="text-secondary">Material category</li>
              {categorylist.map((categorylist, index) => (
                <li key={index}>
                  <div className="form-check">
                    <input
                      onChange={(e) =>
                        filtercategoryfn(categorylist.category_name)
                      }
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    ></input>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      {categorylist.category_name}
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          {/* filter status*/}
          <div className="dropdown m-1">
            <button
              className="btn btn-primary w-100 dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fa fa-filter" aria-hidden="true"></i> Filter
            </button>
            <ul
              className="dropdown-menu p-2"
              aria-labelledby="dropdownMenuButton1"
            >
              <li className="text-secondary">Material Status</li>
              {filterarray.map((filterdata, index) => (
                <li key={index}>
                  <div className="form-check">
                    <input
                      onChange={(e) => filterfn(filterdata.status_name)}
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    ></input>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      {filterdata.status_name}
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          {/* search */}
          <div className=" m-1">
            <input
              className="form-control"
              onChange={searchfn}
              placeholder="search"
              type="search"
            ></input>
          </div>
          {/*  date range  */}
          <div className="m-1"></div>
          {/* between */}
          {/*  <div className="m-1">
                        <input
                            id="betweenstart"
                            className="form-control"
                            value={betweenstart}
                            onChange={(e) => betweenfn("start", e.target.value ? e.target.value : '')}
                            placeholder="start"
                            type="date"
                        ></input>
                    </div>
                    <div className="m-1">
                        <input
                            id="betweenend"
                            className="form-control"
                            value={betweenend}
                            onChange={(e) => betweenfn("end", e.target.value)}
                            placeholder="start"
                            type="date"
                        ></input>
                    </div> */}
          {/*  sort */}
          <div className="m-1 ms-auto">
            <div className="">
              <button
                className="btn bg-white dropdown-toggle"
                id="dropdownMenuClickableInside"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false"
              >
                <i className="fa fa-calendar" aria-hidden="true"></i> {between}
                {between ? (
                  <a
                    className="ms-2 me-2 p-1 text-secondary"
                    onClick={resetdaterange}
                  >
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </a>
                ) : (
                  <></>
                )}
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuClickableInside"
              >
                <DateRangePicker
                  ranges={[selectionRange]}
                  onChange={(date) => handleSelectdate(date)}
                />
              </ul>
            </div>
            <div className="input-group d-none">
              <span
                className="input-group-text bg-white border-end-0"
                id="basic-addon1"
              >
                Sort :{" "}
              </span>
              <select
                value={sort}
                onChange={(e) => {
                  setSort(e.target.value);
                }}
                className="form-select border-start-0"
                aria-label="material_status"
              >
                <option value="" disabled>
                  Select
                </option>
                <option value="mat_name">Material name</option>
                {/* <option value="material_lastname">Lastname</option> */}
                <option value="">Clear</option>
              </select>
            </div>
          </div>
        </div>
        {/* table section*/}
        <div className="table-responsive  border-0 p-1 pb-0 mb-2">
          <table className="table table-hover ">
            <thead>
              <tr>
                <th style={{ width: 200 }} scope="col">Matrial name</th>
                {/*   <th scope="col">Total Amount</th> */}
                <th style={{ width: 100, textAlign: "center" }} scope="col">
                  Received
                </th>
                <th style={{ width: 100, textAlign: "center" }} scope="col">
                  Used
                </th>
                <th style={{ width: 150, textAlign: "center" }} scope="col">
                  Available
                </th>
                {/*  <th style={{ width: 100, textAlign: "center" }} scope="col">
                  Status
                </th> */}

                {/*  <th style={{ width: 100 }} scope="col">
                  Action
                </th> */}
              </tr>
            </thead>
            <tbody>
              {/* table data */}
              {materials ? (
                <>
                  {materials.map((data, index) => (
                    <tr key={index}>
                      <td className="text-capitalize">
                        <i className="fa fa-bookmark-o"></i>&nbsp;&nbsp;
                        {data.mat_name}
                      </td>
                      {/* <td>{data.mat_in_cost}</td> */}
                      <td className="" style={{ textAlign: "center" }}>
                        <span className="badge bg-light shadow-sm text-dark   p-2 ps-4 pe-4">
                          {data.mat_in_qty}
                        </span>
                      </td>
                      <td className="" style={{ textAlign: "center" }}>
                        <span className="badge bg-light shadow-sm text-dark    p-2 ps-4 pe-4">
                          {" "}
                          {data.mat_out_qty}
                        </span>
                      </td>
                      <td className="" style={{ textAlign: "center" }}>
                        <span className={selectedcolor(data.mat_total_bal_qty)}>
                          {" "}
                          {data.mat_total_bal_qty}
                        </span>
                      </td>
                      {/*  <td style={{ textAlign: "center" }}>{data.mat_status}</td> */}

                      {/* <td className="p-1 m-0">
                        <Link
                          className="btn btn-light me-2"
                          to={"/materialsmartevent?material_id=" + data.mat_id}
                        >
                          <i className="fa fa-eye" aria-hidden="true"></i>
                        </Link>
                      </td> */}
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={6} className="text-center">
                    0 data found
                  </td>
                </tr>
              )}
            </tbody>
            <caption className="">
              <div className="d-flex">
                <div className="flex-fill"></div>
                <div className="p-1 "> Items per page </div>
                <div>
                  {" "}
                  <select
                    value={limit}
                    onChange={(e) => {
                      setLimit(e.target.value);
                      setPage(1);
                    }}
                    className="form-select form-select-sm"
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                  </select>
                </div>
                <div className="p-1 me-4">
                  {page * limit - limit}-{page * limit} of {resultCount}
                </div>
                <ReactPaginate
                  previousLabel="< Prev"
                  nextLabel="Next >"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  pageCount={pagecount}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={3}
                  onPageChange={paginationfn}
                  containerClassName="pagination"
                  activeClassName="actives"
                  disabledLinkClassName="text-secondary"
                  forcePage={page - 1}
                />
              </div>
            </caption>
          </table>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Stack;
