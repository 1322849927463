import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  useToggle,
} from "react";
import { Link, Route, Routes, NavLink, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/Authcontext";
import profileLogo from "../assets/images/user.png";
import mylogo from "../assets/images/mylogo.png";

import authsService from "../services/auths.service";

import Empmaterial from "../pages/Empmaterial";
import Empmaterialbooking from "../pages/Empmaterialbooking";
import Empmaterialhistory from "../pages/Empmaterialhistory";
import Empprofile from "../pages/Empprofile";
import Notfound from "./Notfound";
import employeeService from "../services/employee.service";
import { serverURL } from "../http-common";

const Employeelayout = () => {
  let navigate = useNavigate();

  const userinfo = authsService.userinfo();
  const { signOut } = useContext(AuthContext);
  const [userpic, setUserpic] = useState("");

  //signout part console.log(this.href.substring(this.href.lastIndexOf('/') + 1));
  //use effects
  useEffect(() => {
    getEmployee();
    console.log(userinfo);
  }, []);
  //get user
  const getEmployee = () => {
    employeeService
      .getEmployee(userinfo.user_id)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          console.log(res.data);
          let data = res.data.result;
          setUserpic(serverURL + "/" + data.emp_pic);
          console.log(userpic);
        } else {
        }
      })
      .catch((err) => {});
  };
  return (
    <div className="home-layout-container">
      <div
        style={{ height: 63, position: "fixed", zIndex: 999, width: "100%" }}
        className="d-flex bg-white shadow-sm p-2 mb-2"
      >
        <div className="flex-fill p-1">
          {window.location.href.substring(
            window.location.href.lastIndexOf("/lcinventory") + 1
          ) != "lcinventory/" ? (
            <a onClick={() => window.history.back()} className="btn ps-1 pe-3 ">
              <i className="fa fa-chevron-left"></i>&nbsp;&nbsp;Back
            </a>
          ) : (
            <img
              className="rounded rounded-circle"
              style={{ width: 35, height: 35 }}
              src={mylogo}
              alt=""
            />
          )}
        </div>
        <div className="pt-1">
          <button className="btn ps-1 pe-1">
            <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
            <span className="badge bg-secondary">0</span>
          </button>
          <button className="btn ps-1 pe-1">
            <i className="fa fa-bell" aria-hidden="true"></i>
            <span className="badge bg-secondary">0</span>
          </button>
        </div>
        <div className="pt-1">
          <a
            className="btn"
            style={{
              maxWidth: 180,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            data-bs-toggle="dropdown"
          >
            <img
              className="rounded rounded-circle"
              style={{ width: 25, height: 25 }}
              src={userpic ? userpic : profileLogo}
              alt=""
            />
            <span className="ps-2 ">{userinfo.user_name}</span>
            <i className="fa fa-chevron-down"></i>
          </a>
          <ul className="dropdown-menu mt-1">
            <li>
              <a onClick={() => navigate("/profile")} className="dropdown-item">
                {/* <i className='bx bx-log-out'></i> */}
                Profile
              </a>
            </li>
            <li>
              <a className="dropdown-item">
                {/* <i className='bx bx-log-out'></i> */}
                Setting
              </a>
            </li>
            <li className="">
              <a role="button" onClick={signOut} className="dropdown-item">
                Logout
                <span className="ps-3">
                  {" "}
                  <i className="fa fa-sign-out" aria-hidden="true"></i>
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div style={{ height: 65 }}></div>
      <div></div>
      <div className="p-2">
        <Routes>
          {/*  <Route path="/" element={<Employeehome />} /> */}
          <Route path="/" element={<Empmaterial />} />
          <Route
            path="employeematerialbooking"
            element={<Empmaterialbooking />}
          />
          <Route path="employeehistory" element={<Empmaterialhistory />} />
          <Route path="profile" element={<Empprofile />} />
          <Route path="*" element={<Notfound />} />
        </Routes>
      </div>
    </div>
  );
};

export default Employeelayout;
