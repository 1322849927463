import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import servicehistoryService from "../services/servicehistory.service";
import { Servicehistoryvalidationschema } from "../components/Validation";
import ReactPaginate from "react-paginate";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";
import Loader from "../components/Loader";
import Toastmessage from "../components/Toastmessage";
import { useNavigate } from "react-router-dom";
import Alertmsg from "../components/Alertmsg";
import authsService from "../services/auths.service";
import categoryService from "../services/category.service";
import materialService from "../services/material.service";

const Empmaterialhistory = (props) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [categoryarray, setCategoryarray] = useState([]);
  const [bottomswitch, setBottomswitch] = useState(false);

  const userinfo = authsService.userinfo();
  const [categorylist, setCategorylist] = useState([]);
  const [materialarr, setMaterialarr] = useState([]);
  const [loadOptions, setLoadOptions] = useState([
    { value: "111", label: "3 x one" },
    { value: "222", label: "3 x two" },
  ]);

  const ref = useRef();
  let navigate = useNavigate();
  const [iserror, setIserror] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [ismessage, setIsmessage] = useState(false);
  const [matqty, setMatqty] = useState(1);

  const [selectedmaterialObj, setSelectedmaterialObj] = useState(false);

  const [eventnameser, setEventname] = useState("create");
  const [servicinglist, setServicinglist] = useState([]);

  const [servicehistorys, setServicehistorys] = useState([]);
  const [result, setResult] = useState([]);
  //smart table data
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [filter, setFilter] = useState("hello empty");
  const [filtermatcategory, setFiltermatcategory] = useState("");

  const [search, setSearch] = useState("");
  const [between, setBetween] = useState("");
  const [sort, setSort] = useState("");
  const [group, setGroup] = useState("");

  const [pagecount, setPagecount] = useState("");
  const [resultCount, setResultcount] = useState("");

  //filter arrays
  const statusfilterarr = [
    { status_id: "01", status_name: "pending" },
    { status_id: "02", status_name: "active" },
    { status_id: "03", status_name: "inactive" },
  ];
  const [filterarray, setFilterarray] = useState(statusfilterarr);
  const [receipt_id, setReceipt_id] = useState("");

  //date range
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };
  const handleSelectdate = async (date) => {
    await setStartDate(date.selection.startDate);
    await setEndDate(date.selection.endDate);
    setBetween(
      format(date.selection.startDate, "yyyy-MM-dd") +
        " , " +
        format(date.selection.endDate, "yyyy-MM-dd")
    );
  };
  const resetdaterange = () => {
    setBetween("");
    setStartDate(new Date());
    setEndDate(new Date());
  };
  //form initial value
  const formikser = useFormik({
    initialValues: {
      mat_his_id: "",
      receipt_id: "",
      receipt_type: "",
      com_id: "",
      mat_id: "",
      mat_name: "",
      mat_category: "",
      mat_qty: "",
      mat_cost: "",
      mat_date: "",
      team_membera: "",
      team_memberb: "",
      team_memberc: "",
      team_memberd: "",
      team_membere: "",
      mat_status: "completed",
      createdBy: userinfo.user_name,
    },
    validationSchema: Servicehistoryvalidationschema,
    /*  validateOnChange: false,
         validateOnBlur: false, */
    onSubmit: (values, { resetForm }) => {
      //alert(JSON.stringify(values, null, 1));
      //resetForm()
    },
  });

  //use effects
  useEffect(() => {
    setFilter(props.receipt_id);
    getallServicehistory();
    getallmaterial();
    getallCategory();
    formikser.setFieldValue("createdBy", userinfo.user_name);
    formikser.setFieldValue("com_id", props.com_id);
    formikser.setFieldValue("receipt_id", props.receipt_id);
    formikser.setFieldValue("receipt_type", props.receipt_type);
    formikser.setFieldValue("mat_date", props.receipt_date);
    formikser.setFieldValue(
      "team_membera",
      props.team_membera != "" ? props.team_membera : ""
    );
    formikser.setFieldValue(
      "team_memberb",
      props.team_memberb != "" ? props.team_memberb : ""
    );
    formikser.setFieldValue(
      "team_memberc",
      props.team_memberc != "" ? props.team_memberc : ""
    );
    formikser.setFieldValue(
      "team_memberd",
      props.team_memberd != "" ? props.team_memberd : ""
    );
    formikser.setFieldValue(
      "team_membere",
      props.team_membere != "" ? props.team_membere : ""
    );
  }, []);
  useLayoutEffect(() => {
    setFilter(props.receipt_id);
    setReceipt_id(props.receipt_id);
  }, [props.receipt_id]);
  useLayoutEffect(() => {
    getallServicehistory();
  }, [page, limit, filter, search, between, sort]);

  useLayoutEffect(() => {
    getallmaterial();
  }, [page, limit, filter, filtermatcategory, search, between, sort]);

  useLayoutEffect(() => {
    formikser.setFieldValue("mat_qty", matqty);
  }, [matqty]);

  useLayoutEffect(() => {
    console.log(formikser.values);
    if (props.receipt_event === "delete") {
      getallServicehistory();
    } else if (props.receipt_event === "update") {
      getallServicehistory();
    } else {
      getallServicehistory();
    }
  }, [props.receipt_event]);
  useLayoutEffect(() => {
    console.log(props);
    console.log(formikser.values);

    formikser.setFieldValue("com_id", props.com_id);
    formikser.setFieldValue("receipt_id", props.receipt_id);
    formikser.setFieldValue("receipt_type", props.receipt_type);
    formikser.setFieldValue("mat_date", props.receipt_date);
    formikser.setFieldValue(
      "team_membera",
      props.team_membera != "" ? props.team_membera : ""
    );
    formikser.setFieldValue(
      "team_memberb",
      props.team_memberb != "" ? props.team_memberb : ""
    );
    formikser.setFieldValue(
      "team_memberc",
      props.team_memberc != "" ? props.team_memberc : ""
    );
    formikser.setFieldValue(
      "team_memberd",
      props.team_memberd != "" ? props.team_memberd : ""
    );
    formikser.setFieldValue(
      "team_membere",
      props.team_membere != "" ? props.team_membere : ""
    );
  }, [
    props.receipt_id,
    props.com_id,
    props.receipt_date,
    props.team_membera,
    props.team_memberb,
    props.team_memberc,
    props.team_memberd,
    props.team_membere,
  ]);
  //current event
  const servicehistoryEvent = (eventnameser, value) => {
    if (value != "") {
      getServicehistory(value);
      setEventname(eventnameser);
    }
    setEventname(eventnameser);
  };
  //get allservicehistory
  const getallServicehistory = async () => {
    setIsLoaded(true);
    var data = {
      limit: 100,
      page: 1,
      filter: filter?filter:"fgnjfgijfijgif",
      search: "",
      between: "",
      sort: "",
      group: "",
    };
    return await servicehistoryService
      .getallServicehistory(data)
      .then((res) => {
        console.log(res.data.result);
        if (res.data.result) {
          setIsLoaded(false);
          // setIserror(res.data.status);
          //setIsmessage(res.data.message);
          setServicehistorys(res.data.result.data ? res.data.result.data : []);
          setResult(res.data);
          setResultcount(res.data.result.resultcount);
          console.log(res.data);
        } else {
          setServicehistorys([]);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
        }
      })
      .catch((err) => {
        setServicehistorys([]);
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
      });
  };
  //pagination
  const paginationfn = (event) => {
    setPage(event.selected + 1);
    console.log(event.selected + 1);
  };
  //search
  const searchfn = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };
  //filter
  const [filterArr, setfilterArr] = useState([]);
  const filterfn = (value) => {
    setPage(1);
    var index = filterArr.indexOf(value);
    if (index > -1) {
      filterArr.splice(index, 1);
      setFilter(filterArr.toString());
    } else {
      filterArr.push(value);
      setFilter(filterArr.toString());
    }
  };
  //filter
  const [filtercatArr, setfiltercatArr] = useState([]);
  const filtercatfn = (value) => {
    setPage(1);
    var index = filtercatArr.indexOf(value);
    if (index > -1) {
      filtercatArr.splice(index, 1);
      setFiltermatcategory(filtercatArr.toString());
    } else {
      filtercatArr.push(value);
      setFiltermatcategory(filtercatArr.toString());
    }
  };
  //sort
  const sortfn = (value) => {
    /*    setSort(value); */
    if (sort == value + " desc") {
      setSort(value + " asc");
      console.log(value + " asc");
    } else if (sort == value + " asc") {
      setSort("");
      console.log(value + "");
    } else {
      setSort(value + " desc");
      console.log(value + " desc");
    }
  };
  //between
  const [betweenstart, setBetweenstart] = useState("");
  const [betweenend, setBetweenend] = useState("");
  const betweenfn = (value, e) => {
    setPage(1);
    if (e === null || e === "") {
      setBetween("");
      setBetweenstart("");
      setBetweenend("");
    } else {
      if (value == "start") {
        setBetween(e + "," + e);
        console.log(e + "," + e);
        setBetweenstart(e);
        setBetweenend(e);
      } else if (value == "end") {
        setBetweenend(e);
        const dd = between.split(",");
        setBetween(dd[0] + "," + e);
        console.log(dd[0] + "," + e);
        setBetweenstart(dd[0]);
        setBetweenend(e);
      } else {
        setBetween("");
        setBetweenstart("");
        setBetweenend("");
      }
    }
  };
  //get servicehistory
  const getServicehistory = (value) => {
    setIserror(null);
    setIsmessage(null);
    setIsLoaded(true);
    servicehistoryService
      .getServicehistory(value)
      .then((res) => {
        console.log(res.data);

        if (res.data.result) {
          console.log(res.data);
          setIsLoaded(false);
          let data = res.data.result;
          formikser.setValues({
            mat_his_id: data.mat_his_id,
            receipt_id: data.receipt_id,
            receipt_type: data.receipt_type,
            com_id: data.com_id,
            mat_name: data.mat_name,
            mat_category: data.mat_category,
            mat_qty: data.mat_qty,
            mat_cost: data.mat_cost,
            mat_date: data.mat_date,
            mat_status: data.mat_status,
            createdBy: userinfo.user_name,
          });
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
      });
  };
  const cleardata = () => {
    formikser.setFieldValue("mat_name", "");
    formikser.setFieldValue("mat_category", "");

    formikser.setFieldValue("mat_qty", 0);
    formikser.setFieldValue("mat_cost", 0);
  };
  //get existing appoinment
  const getexistingappoinment = (data) => {
    props.newFunc(data);
    /* console.log(data); */
  };
  //create servicehistory
  const createServicehistory = () => {
    console.log(formikser.values);
    formikser.setFieldValue("createdBy", userinfo.user_name);
    setIserror(null);
    setIsmessage(null);
    setIsLoaded(true);
    servicehistoryService
      .createServicehistory(formikser.values)
      .then((res) => {
        if (res.data.status) {
          console.log(res.data);
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          getallServicehistory();
          ref.current.log();
          cleardata();
        } else {
          console.log(res.data);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
          ref.current.log();
        }
      })
      .catch((err) => {
        console.log(err);

        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
        ref.current.log();
      });
  };

  //update servicehistory
  const updateServicehistory = (value) => {
    formikser.setFieldValue("createdBy", userinfo.user_name);
    console.log(JSON.stringify(formikser.values, null, 2));
    servicehistoryService
      .updateServicehistory(formikser.values)
      .then((res) => {
        console.log(res.data);
        if (res.data.status) {
          console.log(res.data);
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          ref.current.log();
          getallServicehistory();
          setEventname("create");
          cleardata();
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
          ref.current.log();
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
        ref.current.log();
      });
  };
  //update servicehistory
  const updateServicehistorybyapptid = (value) => {
    console.log(formikser.values);
    formikser.setFieldValue("createdBy", userinfo.user_name);
    console.log(JSON.stringify(formikser.values, null, 2));
    servicehistoryService
      .updateServicehistorybyapptid(formikser.values)
      .then((res) => {
        if (res.data.status) {
          console.log(res.data);
          getallServicehistory();
          setEventname("create");
          cleardata();
        } else {
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
        ref.current.log();
      });
  };
  //delete servicehistory
  const deleteServicehistory = (value) => {
    console.log(value);
    servicehistoryService
      .deleteServicehistory(String(value))
      .then((res) => {
        if (res.data.status) {
          console.log("succes");
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          getallServicehistory();
          setEventname("create");
          ref.current.log();
          cleardata();
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
          ref.current.log();
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
        ref.current.log();
      });
  };
  const deleteServicehistorybyapptid = (value) => {
    console.log(value);
    servicehistoryService
      .deleteServicehistorybyapptid(String(value))
      .then((res) => {
        if (res.data.status) {
          console.log("success");
          setReceipt_id("");
          getallServicehistory();
          setEventname("create");
          cleardata();
        } else {
        }
      })
      .catch((err) => {});
  };
  const [apptarr, setApparr] = useState([]);
  const [search_customer, setSearch_customer] = useState([]);

  //servicing list
  const getallservicing = (value) => {
    var data = {
      limit: 10,
      page: 1,
      filter: "",
      filtercategory: value,
      search: "",
      between: "",
      sort: "",
      group: "",
    };
    categoryService
      .getallCategory(data)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          setServicinglist(res.data.result.data);
        } else {
          setServicinglist([]);
        }
      })
      .catch((err) => {
        setServicinglist([]);
      });
  };
  //category  list
  const getallapptcategory = () => {
    var data = {
      limit: 10,
      page: 1,
      filter: "",
      filtercategory: "main",
      search: "",
      between: "",
      sort: "",
      group: "",
    };
    categoryService
      .getallCategory(data)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          setCategorylist(res.data.result.data);
        } else {
          setCategorylist([]);
        }
      })
      .catch((err) => {
        setCategorylist([]);
      });
  };
  //select onchange
  const selectonchange = (e) => {
    console.log(e.label);
    formikser.setFieldValue("mat_name", e.label);
    /* setSelectedOption(e.label); */
  };
  //material  list
  const getallmaterial = () => {
    var data = {
      limit: 40,
      page: page,
      filter: "",
      filtercategory: filtermatcategory,
      search: search,
      between: "",
      sort: "",
      group: "",
    };
    materialService
      .getallMaterial(data)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          setMaterialarr(res.data.result.data);
          setPage(res.data.result.page);
          setLimit(res.data.result.limit);
          setPagecount(res.data.result.pagecount);
          /*  var arra = res.data.result.data;
          var sdsd = arra.map(({ mat_id, mat_name }) => ({
            value: mat_id,
            label: mat_name,
          }));
          setLoadOptions(sdsd); */
        } else {
          setMaterialarr([]);
        }
      })
      .catch((err) => {
        setMaterialarr([]);
      });
  };
  //get allcategory
  const getallCategory = async () => {
    setIsLoaded(true);
    var data = {
      limit: 20,
      page: 1,
      filter: "",
      filtercategory: "",
      search: "",
      between: "",
      sort: "",
      group: "",
    };
    return await categoryService
      .getallCategory(data)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          setCategoryarray(res.data.result.data);
        } else {
          setCategoryarray([]);
        }
      })
      .catch((err) => {
        setCategoryarray([]);
      });
  };
  const selectedmaterialChange = (value) => {
    setSelectedmaterialObj(value);
    console.log(value);
    formikser.setFieldValue("mat_qty", 1);
    formikser.setFieldValue("mat_id", value.mat_id);
    formikser.setFieldValue("mat_name", value.mat_name);
    formikser.setFieldValue("mat_category", value.mat_category);
    formikser.setFieldValue(
      "mat_cost",
      value.mat_price === undefined || value.mat_price === null
        ? 0
        : value.mat_price
    );
    console.log(formikser.values);
  };

  // Call servicehistory clicks to request another page.
  if (ismessage == "Network Error") {
    return <Alertmsg alertmsg={ismessage} type={iserror} />;
  }
  return (
    <div className="">
      <div className="">
        {/*  loader and error handler */}
        {<Toastmessage ref={ref} type={iserror} errormsg={ismessage} />}
        {isLoaded ? <Loader /> : <></>}
      </div>

      {receipt_id ? (
        <>
          {/*  container */}
          <div className="servicehistory-container bg-white ">
            {/* servicehistory form */}

            <div className="table-responsive card border-0 bg-transparent mb-2">
              <table className="table ">
                <thead>
                  <tr>
                    <th scope="col">Material Name</th>
                    <th scope="col">Unit/Qty</th>
                    <th style={{ width: 120 }} scope="col">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* table data */}
                  {servicehistorys ? (
                    <>
                      {servicehistorys.map((data, index) => (
                        <tr key={index}>
                          <td>{data.mat_name}</td>
                          <td>{data.mat_qty}</td>
                          <td className="p-1 m-0">
                            {/* <button data-bs-toggle="modal" data-bs-target="#exampleModaledit"
                              onClick={() =>
                                servicehistoryEvent("view", data.mat_his_id)
                              }
                              className="btn btn-sm btn-light me-2 "
                            >
                              <i className="fa fa-eye" aria-hidden="true"></i>
                            </button> */}
                            <button
                              onClick={() => {
                                setIsmessage("Access disabled");
                                ref.current.log();
                              }}
                              /*  data-bs-toggle="modal"
                              data-bs-target="#staticBackdropedit"
                              onClick={() => {
                                servicehistoryEvent("edit", data.mat_his_id);
                              }} */
                              className="btn btn-sm btn-light me-2 "
                            >
                              <i
                                className="fa fa-pencil-square"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <button
                              onClick={() => {
                                setIsmessage("Access disabled");
                                ref.current.log();
                              }}
                              /*  data-bs-toggle="modal"
                              data-bs-target="#staticBackdropedit"
                              onClick={() =>
                                servicehistoryEvent("delete", data.mat_his_id)
                              } */
                              className="btn tn-sm btn-light me-2 "
                            >
                              <i
                                className="fa fa-trash-o"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        0 data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      {/* material add modal */}
      <div
        className="modal  "
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-scrollable modal-dialog-centered"
          /*  modal-fullscreen */
        >
          <div style={{ minHeight: "99%" }} className="modal-content">
            <div className="modal-header">
              <div className="w-100">
                <div className="d-flex">
                  {/* <h5 className="flex-fill" id="staticBackdropLabel">
                    Material
                  </h5> */}
                  <button
                    type="button"
                    className="btn-close mb-2"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="d-flex w-100 bg-light mb-1">
                  <div className="flex-fill pt-2 ps-2">
                    <strong> Material list</strong>&nbsp;&nbsp;
                    <span className="badge bg-secondary">{resultCount}</span>
                  </div>
                  <div className="">
                    <div className="dropdown m-1">
                      <button
                        className="btn border "
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span>
                          <i className="fa fa-filter" aria-hidden="true"></i>
                        </span>
                      </button>
                      <ul
                        className="dropdown-menu p-2"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        {/* <li className="text-secondary">Category</li> */}
                        {categoryarray.map((categoryarray, index) => (
                          <li key={index}>
                            <div className="form-check">
                              <input
                                onChange={(e) =>
                                  filtercatfn(categoryarray.category_name)
                                }
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id={categoryarray.category_name}
                              ></input>
                              <label
                                className="form-check-label text-capitalize"
                                htmlFor={categoryarray.category_name}
                              >
                                {categoryarray.category_name}
                              </label>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                {/* search */}
                <div className="">
                  <input
                    className="form-control form-control-lg"
                    onChange={searchfn}
                    placeholder="search"
                    type="search"
                  ></input>
                </div>
              </div>
            </div>
            <div className="modal-body">
              <div>
                <div>
                  {materialarr ? (
                    <>
                      {materialarr.map((materialarr, index) => (
                        <div
                          key={index}
                          className=" d-flex p-2  mb-1 shadow-sm"
                        >
                          <div className="flex-fill text-capitalize">
                            {materialarr.mat_name}
                          </div>
                          <div className="">
                            <a
                              className="btn  m-0"
                              onClick={() => {
                                selectedmaterialChange(materialarr);
                              }}
                            >
                              <strong>
                                <i className="fa fa-plus-circle"></i>
                              </strong>
                            </a>
                          </div>
                        </div>
                      ))}
                      <div className="justify-content-end ">
                        <div className=" d-flex justify-content-center pt-4">
                          <ReactPaginate
                            previousLabel="< Prev&nbsp;&nbsp;&nbsp;"
                            nextLabel="&nbsp;&nbsp;&nbsp;Next >"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={pagecount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={1}
                            onPageChange={paginationfn}
                            containerClassName="pagination"
                            activeClassName="actives"
                            disabledLinkClassName="text-secondary"
                            forcePage={page - 1}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="text-center p-2">0 data found</div>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer p-0 m-0">
              <div>
                {Object.keys(selectedmaterialObj).length ? (
                  <>
                    <div className="row">
                      <div className="text-capitalize col-7 pt-2">
                        <strong>{selectedmaterialObj?.mat_name}</strong>
                      </div>
                      <div className="col-5">
                        <div className="input-group input-group-sm  mb-3">
                          <span className="input-group-text" id="basic-addon1">
                            <a
                              className="p-2"
                              onClick={() =>
                                setMatqty(matqty > 1 ? matqty - 1 : matqty)
                              }
                            >
                              {" "}
                              <i className="fa fa-minus"></i>
                            </a>
                          </span>
                          <input
                            id="matqty"
                            className="form-control "
                            value={matqty}
                            onChange={(e)=>setMatqty(e.target.value)}
                            placeholder="nos"
                            type="number"
                          ></input>
                          <span className="input-group-text " id="basic-addon1">
                            <a
                              className="p-2"
                              onClick={() => setMatqty(matqty + 1)}
                            >
                              <i className="fa fa-plus"></i>
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="row w-100">
                <button
                  onClick={() => console.log(formikser.values)}
                  type="button"
                  className="btn btn-secondary col  btn-lg w-100 rounded-0"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  disabled={
                    Object.keys(selectedmaterialObj).length > 0 ? false : true
                  }
                  data-bs-dismiss="modal"
                  onClick={() => createServicehistory()}
                  type="button"
                  className="btn btn-primary col btn-lg w-100 rounded-0"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  <!-- Modal edit --> */}

      <div
        className="modal fade"
        id="staticBackdropedit"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropeditLabel"
        aria-hidden="true"
      >
        <div
          style={{}}
          className="modal-dialog modal-dialog-scrollable modal-dialog-centered"
          /*  modal-fullscreen */
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Material Action</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={formikser.handleSubmit}>
                <div className="row">
                  <div className="col-md-12"></div>
                  <div className="col-md-4 ">
                    <label htmlFor="mat_name" className="form-label">
                      Material name
                    </label>
                    <div className="input-group">
                      <input
                        className="form-control"
                        placeholder="material name"
                        id="mat_name"
                        type="text"
                        readOnly={true}
                        {...formikser.getFieldProps("mat_name")}
                      />
                    </div>
                    <div>
                      {formikser.touched.mat_name &&
                      formikser.errors.mat_name ? (
                        <div className="error">{formikser.errors.mat_name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-2 ">
                    <label htmlFor="mat_qty" className="form-label">
                      Unit/Qty
                    </label>
                    <div className="input-group">
                      <input
                        className="form-control"
                        placeholder="Qty"
                        id="mat_qty"
                        type="number"
                        disabled={
                          eventnameser == "view"
                            ? true
                            : eventnameser == "delete"
                            ? true
                            : false
                        }
                        {...formikser.getFieldProps("mat_qty")}
                      />
                    </div>
                    {/*  <div>
                                        {formikser.touched.ser_unit && formikser.errors.ser_unit ? (
                                            <div className='error'>{formikser.errors.ser_unit}</div>
                                        ) : null}
                                    </div> */}
                  </div>

                  {/* <div className="col-md-2 ">
                    <label htmlFor="mat_cost" className="form-label">
                      Cost
                    </label>
                    <div className="input-group">
                      <input
                        className="form-control"
                        placeholder="Cost"
                        id="mat_cost"
                        type="number"
                        disabled={
                          eventnameser == "view"
                            ? true
                            : eventnameser == "delete"
                            ? true
                            : false
                        }
                        {...formikser.getFieldProps("mat_cost")}
                      />
                    </div>
                    <div>
                      {formikser.touched.mat_cost &&
                      formikser.errors.mat_cost ? (
                        <div className="error">{formikser.errors.mat_cost}</div>
                      ) : null}
                    </div>
                  </div> */}
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <div style={{ paddingTop: 36 }} className="col-md-4">
                {eventnameser === "create" ? (
                  <>
                    {/* create button based on contract service */}

                    <button
                      data-bs-dismiss="modal"
                      onClick={() => createServicehistory()}
                      className="btn btn-primary me-2"
                    >
                      add
                    </button>
                  </>
                ) : eventnameser === "edit" ? (
                  <button
                    data-bs-dismiss="modal"
                    onClick={() =>
                      updateServicehistory(formikser.values.mat_his_id)
                    }
                    className="btn btn-primary me-2"
                  >
                    Update
                  </button>
                ) : eventnameser === "delete" ? (
                  <button
                    data-bs-dismiss="modal"
                    onClick={() =>
                      deleteServicehistory(formikser.values.mat_his_id)
                    }
                    className="btn btn-danger me-2 "
                  >
                    Delete
                  </button>
                ) : (
                  <></>
                )}
                <button
                  data-bs-dismiss="modal"
                  type="submit"
                  disabled={!formikser.dirty}
                  onClick={() => {
                    servicehistoryEvent("create", "");
                    cleardata();
                  }}
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  bottom sheet */}
      {props.receipt_id ? (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              zIndex: 999,
              bottom: 10,
            }}
            className="text-center  p-3 "
          >
            <button
           /*  disabled={new Date('2-03-2023')===new Date()?false:true} */
              onClick={() => {
                setSelectedmaterialObj({});
                setMatqty(1);
              }}
              
              className="btn btn-primary btn-lg rounded-circle"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              <i className="fa fa-plus"></i>
            </button>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Empmaterialhistory;
