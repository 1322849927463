import React, { useEffect, useLayoutEffect, useState, useRef } from 'react'
import { useFormik } from 'formik';
import appoinmentService from '../services/inventory.service';
import { Inventoryvalidationschema } from '../components/Validation';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { format } from "date-fns";
import Loader from '../components/Loader';
import Toastmessage from '../components/Toastmessage';
import { useNavigate,useSearchParams } from 'react-router-dom';
import Alertmsg from '../components/Alertmsg';

const Appoinmentevent = (props) => {
    const ref = useRef();
    let navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const [appt_id, setAppoinmentid] = useState();

    const [iserror, setIserror] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [ismessage, setIsmessage] = useState(false);
    const [eventname, setEventname] = useState('view')

    //form initial value
    const formik = useFormik({
        initialValues: {
            appt_id: '',
            emp_id: '',
            com_id: '',
            appt_category: '',
            appt_name: '',
            appt_total_unit: '',
            appt_price: '',
            appt_cus_name: '',
            appt_cus_address: '',
            appt_cus_phone: '',
            appt_status: ''
        },
        validationSchema: Inventoryvalidationschema,
        /*  validateOnChange: false,
         validateOnBlur: false, */
        onSubmit: (values, { resetForm }) => {
            //alert(JSON.stringify(values, null, 1));
            /*  resetForm({ values: '' }) */
        }
    });
    //use effects
    useEffect(() => {
        setAppoinmentid(searchParams.get('appt_id'))
        if (searchParams.get('appt_id')) {
            getAppoinment(searchParams.get('appt_id'))
        } else {
            setEventname('create')
        }
    }, []);

    //current event                                 
    const appoinmentEvent = (eventname, value) => {
        if (value) {
            getAppoinment(value);
            setEventname(eventname)
        }
        setEventname(eventname)
    }

    //get user
    const getAppoinment = (value) => {
        setIserror(null)
        setIsmessage(null)
        setIsLoaded(true);
        appoinmentService.getAppoinment(value).
            then(
                (res) => {
                    if (res.data.result) {
                        console.log(res.data);
                        setIsLoaded(false);
                        let data = res.data.result;
                        formik.setValues({
                            appt_id: data.appt_id,
                            emp_id: data.emp_id,
                            com_id: data.com_id,
                            appt_category: data.appt_category,
                            appt_name: data.appt_name,
                            appt_total_unit: data.appt_total_unit,
                            appt_price: data.appt_price,
                            appt_subtotal: data.appt_subtotal,
                            appt_cus_name: data.appt_cus_name,
                            appt_cus_address: data.appt_cus_address,
                            appt_phone: data.appt_phone,
                            appt_status: data.appt_status,
                        });
                    } else {
                        setIserror(res.data.status);
                        setIsmessage(res.data.message);
                        setIsLoaded(false);
                    }
                }

            ).catch((err) => {
                setIserror(true);
                setIsmessage(err.message);
                setIsLoaded(false);
            });
    }
    //create user
    const createAppoinment = () => {
        setIserror(null)
        setIsmessage(null)
        setIsLoaded(true);
        appoinmentService.createAppoinment(formik.values).
            then(
                (res) => {
                    if (res.data.status) {
                        console.log(res.data);
                        setIsLoaded(false);
                        setIserror(res.data.status);
                        setIsmessage(res.data.message);
                        ref.current.log()
                    } else {
                        setIserror(res.data.status);
                        setIsmessage(res.data.message);
                        setIsLoaded(false);
                        ref.current.log()
                    }
                }
            ).catch((err) => {
                setIserror(true);
                setIsmessage(err.message);
                setIsLoaded(false);
               /*  ref.current.log() */
            });
    }

    //update user
    const updateAppoinment = () => {
        console.log(JSON.stringify(formik.values, null, 2));
        appoinmentService.updateAppoinment(formik.values).
            then(
                (res) => {
                    if (res.data.status) {
                        console.log(res.data);
                        setIsLoaded(false);
                        setIserror(res.data.status);
                        setIsmessage(res.data.message);
                        appoinmentEvent('view');
                        ref.current.log()
                    } else {
                        setIserror(res.data.status);
                        setIsmessage(res.data.message);
                        setIsLoaded(false);
                        ref.current.log()
                    }
                }

            ).catch((err) => {
                setIserror(true);
                setIsmessage(err.message);
                setIsLoaded(false);
                ref.current.log()
            });
    }
    //delete user
    const deleteAppoinment = (value) => {
        console.log(value);
        appoinmentService.deleteAppoinment(String(value)).
            then(
                (res) => {
                    if (res.data.status) {
                        console.log('succes');
                        setIsLoaded(false);
                        setIserror(res.data.status);
                        setIsmessage(res.data.message);
                         ref.current.log()
                    } else {
                        setIserror(res.data.status);
                        setIsmessage(res.data.message);
                        setIsLoaded(false);
                        ref.current.log();
                    }
                }

            ).catch((err) => {
                setIserror(true);
                setIsmessage(err.message);
                setIsLoaded(false);
                ref.current.log()
            });
    }
    // Call user clicks to request another page.  
    if (isLoaded) {
        <Loader />
    } else {
        return (
            <div className="">
                  {/*  loader and error handler */}
                    {/*  {iserror === false || iserror === true ? <Alertmsg alertmsg={ismessage} type={iserror} /> : <></>} */}
                    {<Toastmessage ref={ref} type={iserror} errormsg={ismessage} />}
                {/*  container */}
                <div className="user-container">
                    {/* form container */}
                    <div className='card bg-transparent mb-2'>
                        <div className='card-body '>

                            <div className='d-flex p-2 '>
                                <h5 className="card-title p-0 m-0 me-auto">Appoinments</h5>
                                {eventname == 'create' ? <></> :
                                    <div>
                                        <div className="dropdown">
                                            <a className="btn  btn-link" href="#" role="button" id="usereventdropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="usereventdropdownMenuLink">
                                                <li><a onClick={() => { appoinmentEvent('edit') }} className="dropdown-item" >Edit</a></li>
                                                <li><a onClick={() => appoinmentEvent('delete')}
                                                    className="dropdown-item" >Delete</a></li>
                                            </ul>
                                        </div>
                                    </div>}
                            </div>
                            <form onSubmit={formik.handleSubmit}  >
                                <div className='row'>

                                    <div className='col-md-12'>
                                        <label htmlFor="com_id" className="form-label">company</label>
                                        <div className='input-group col-md-6'>
                                            <select id="com_id"  {...formik.getFieldProps('com_id')} className="form-select"
                                                disabled={eventname == 'view' ? true : eventname == 'delete' ? true : false} aria-label="com_id">
                                                <option value='' disabled>Select company</option>
                                                <option value="letscool">Letscool</option>
                                                <option value="coolcare">Coolcare</option>
                                            </select>
                                        </div>
                                        <div>
                                            {formik.touched.com_id && formik.errors.com_id ? (
                                                <div className='error'>{formik.errors.com_id}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <label htmlFor="appt_category" className="form-label">Category</label>
                                        <div className='input-group col-md-6'>
                                            <select id="appt_category"  {...formik.getFieldProps('appt_category')} className="form-select"
                                                disabled={eventname == 'view' ? true : eventname == 'delete' ? true : false} aria-label="com_id">
                                                <option value='' disabled>Select Services</option>
                                                <option value="installation">Installation</option>
                                                <option value="servicing">Servicing</option>
                                            </select>
                                        </div>
                                        <div>
                                            {formik.touched.appt_category && formik.errors.appt_category ? (
                                                <div className='error'>{formik.errors.appt_category}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6 '>
                                        <label htmlFor="appt_name" className="form-label">Appoinment name</label>
                                        <div className='input-group'>
                                            <input
                                                className='form-control'
                                                placeholder='Enter Appoinment name'
                                                id="appt_name"
                                                type="text"
                                                disabled={eventname == 'view' ? true : eventname == 'delete' ? true : false}
                                                {...formik.getFieldProps('appt_name')}
                                            />
                                        </div>
                                        <div>
                                            {formik.touched.appt_name && formik.errors.appt_name ? (
                                                <div className='error'>{formik.errors.appt_name}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6 '>
                                        <label htmlFor="appt_total_unit" className="form-label">Appoinment total unit</label>
                                        <div className='input-group'>
                                            <input
                                                className='form-control'
                                                placeholder='Enter total unit'
                                                id="appt_total_unit"
                                                type="text"
                                                disabled={eventname == 'view' ? true : eventname == 'delete' ? true : false}
                                                {...formik.getFieldProps('appt_total_unit')}
                                            />
                                        </div>
                                        <div>
                                            {formik.touched.appt_total_unit && formik.errors.appt_total_unit ? (
                                                <div className='error'>{formik.errors.appt_total_unit}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6 '>
                                        <label htmlFor="appt_price" className="form-label">Appoinment price</label>
                                        <div className='input-group'>
                                            <input
                                                className='form-control'
                                                placeholder='Enter Appoinment price'
                                                id="appt_price"
                                                type="text"
                                                disabled={eventname == 'view' ? true : eventname == 'delete' ? true : false}
                                                {...formik.getFieldProps('appt_price')}
                                            />
                                        </div>
                                        <div>
                                            {formik.touched.appt_price && formik.errors.appt_price ? (
                                                <div className='error'>{formik.errors.appt_price}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6 '>
                                        <label htmlFor="appt_cus_name" className="form-label">customer name</label>
                                        <div className='input-group'>
                                            <input
                                                className='form-control'
                                                placeholder='Enter customer name'
                                                id="appt_cus_name"
                                                type="text"
                                                disabled={eventname == 'view' ? true : eventname == 'delete' ? true : false}
                                                {...formik.getFieldProps('appt_cus_name')}
                                            />
                                        </div>
                                        <div>
                                            {formik.touched.appt_cus_name && formik.errors.appt_cus_name ? (
                                                <div className='error'>{formik.errors.appt_cus_name}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6 '>
                                        <label htmlFor="appt_cus_phone" className="form-label">customer phone</label>
                                        <div className='input-group'>
                                            <input
                                                className='form-control'
                                                placeholder='Enter customer phone'
                                                id="appt_cus_phone"
                                                type="text"
                                                disabled={eventname == 'view' ? true : eventname == 'delete' ? true : false}
                                                {...formik.getFieldProps('appt_cus_phone')}
                                            />
                                        </div>
                                        <div>
                                            {formik.touched.appt_cus_phone && formik.errors.appt_cus_phone ? (
                                                <div className='error'>{formik.errors.appt_cus_phone}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6 '>
                                        <label htmlFor="appt_cus_address" className="form-label">customer address</label>
                                        <div className='input-group'>
                                            <input
                                                className='form-control'
                                                placeholder='Enter customer address'
                                                id="appt_cus_address"
                                                type="text"
                                                disabled={eventname == 'view' ? true : eventname == 'delete' ? true : false}
                                                {...formik.getFieldProps('appt_cus_address')}
                                            />
                                        </div>
                                        <div>
                                            {formik.touched.appt_cus_address && formik.errors.appt_cus_address ? (
                                                <div className='error'>{formik.errors.appt_cus_address}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <label htmlFor="appt_status" className="form-label">Status</label>
                                        <div className='input-group col-md-6'>
                                            <select id="appt_status"  {...formik.getFieldProps('appt_status')} className="form-select"
                                                disabled={eventname == 'view' ? true : eventname == 'delete' ? true : false} aria-label="appt_status">
                                                <option value='' disabled>Select status</option>
                                                <option value="pending">Pending</option>
                                                <option value="completed">Completed</option>
                                            </select>
                                        </div>
                                        <div>
                                            {formik.touched.appt_status && formik.errors.appt_status ? (
                                                <div className='error'>{formik.errors.appt_status}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className='col-md-12 pt-2 pb-2'>
                                        {
                                            eventname === 'create' ? <><button disabled={!formik.isValid}  onClick={() => createAppoinment()} className='btn btn-primary me-2' >Create</button>
                                                <button onClick={formik.handleReset} className='btn btn-secondary' >Clear</button></> :
                                                eventname === 'edit' ? <> <button onClick={() => updateAppoinment()} className='btn btn-primary me-2' >Update</button>
                                                    <button onClick={() => appoinmentEvent('view')} className='btn btn-secondary' >Cancel</button></> :
                                                    eventname === 'delete' ? <><button onClick={() => deleteAppoinment(formik.values.appt_id)} className='btn btn-danger me-2 '>Delete</button>
                                                        <button onClick={() => appoinmentEvent('view')} className='btn btn-secondary' >Cancel</button></> : <>
                                                        <button onClick={() => navigate('/appoinment')} className='btn btn-primary' >Back</button>
                                                    </>
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                  
                </div>
            </div >
        );
    }
}

export default Appoinmentevent