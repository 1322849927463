import React, { useState, useContext, useRef, useEffect } from "react";
import { Signinempvalidationschema } from "../components/Validation";
import { useFormik } from "formik";
import { AuthContext } from "../context/Authcontext";
import authsService from "../services/auths.service";
import { useNavigate } from "react-router-dom";
import Alertmsg from "../components/Alertmsg";
import Loader from "../components/Loader";
import Toastmessage from "../components/Toastmessage";
import mylogo from "../assets/images/letscoolfulllogowhite.png";

const Signina = () => {
  const userinfo = authsService.userinfo();

  const ref = useRef();
  let navigate = useNavigate();
  const [iserror, setIserror] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [ismessage, setIsmessage] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const { signIn } = useContext(AuthContext);
  const formik = useFormik({
    initialValues: {
      emp_username: "",
      emp_password: "",
    },
    validationSchema: Signinempvalidationschema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });


  const signin = () => {
    setIserror(null);
    setIsmessage(null);
    setIsLoaded(true);
    authsService
      .signinemp(formik.values)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          localStorage.setItem("isloggedinemp", true);
          localStorage.setItem("userinfo", JSON.stringify(res.data.result));
          signIn();
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
        }
      })
      .catch((err) => {
        //console.log('error' + err);
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
        localStorage.setItem("isloggedin", false);
      });
  };

  return (
      <div style={{ position: "absolute" }} className="h-100 bg-primary w-100">
        <div className="d-flex flex-column justify-content-center h-100">
          <div className="d-flex flex-fill justify-content-center p-3 pt-5">
            <img
              className=""
              style={{ width: 140, height: 170 }}
              src={mylogo}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = { mylogo };
              }}
              alt="letscool"
            />
          </div>
          <div style={{borderRadius:30}} className="bg-white p-3 m-4 pt-5 pb-5">
            <div className="row row-cols-1 row-cols-lg-2">
              <div className="col"></div>
              <div className="col">
                <h1 className="">Welcome Back!</h1>
                <p className="description ">Please Sign In to your account</p>
                <form onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-md-12">
                      <label className="form-label me-4" htmlFor="emp_username">
                        Username
                      </label>
                      <div className="input-group ">
                        <input
                          className="form-control form-control-lg"
                          id="emp_username"
                          type="text"
                          {...formik.getFieldProps("emp_username")}
                        />
                      </div>
                      {formik.touched.emp_username &&
                      formik.errors.emp_username ? (
                        <div className="error">
                          {formik.errors.emp_username}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-12">
                      <label className="form-label me-4" htmlFor="emp_password">
                        Password
                      </label>
                      <div className="input-group ">
                        <input
                          className="form-control form-control-lg border-end-0"
                          id="emp_password"
                          type={showPass ? "text" : "password"}
                          autoComplete="off"
                          {...formik.getFieldProps("emp_password")}
                        />
                        <span
                          onClick={() => setShowPass(!showPass)}
                          className="input-group-text bg-white border-start-0"
                        >
                          <i
                            className={
                              showPass ? "fa fa-eye-slash" : "fa fa-eye"
                            }
                          ></i>{" "}
                        </span>
                      </div>
                      {formik.touched.emp_password &&
                      formik.errors.emp_password ? (
                        <div className="error">
                          {formik.errors.emp_password}
                        </div>
                      ) : null}
                    </div>
                    {/* <div className="col-md-12 text-end"><a className='btn btn-link text-decoration-none' onClick={() => navigate('/resetlink')}>Forget Password ?</a></div> */}
                  </div>
                  {/* <button type="submit" className="btn btn-primary mt-3" >Login</button> */}
                </form>
                <button
                  onClick={signin}
                  disabled={!(formik.isValid && formik.dirty)}
                  className="btn btn-lg d-block w-100 btn-primary mt-3"
                >
                  Login
                </button>
                {/*  <div className='mt-3'>
              Don't have an account yet? <button className='btn btn-link text-decoration-none m-0 p-0' onClick={() => navigate('/signup')}>SignUp</button> here.
            </div> */}
                <div className="mt-2">
                  {/*  loader and error handler */}
                  {iserror === false || iserror === true ? (
                    <Alertmsg alertmsg={ismessage} type={iserror} />
                  ) : (
                    <></>
                  )}
                  {/*  {<Toastmessage ref={ref} type={error} errormsg={ismessage} />} */}
                  {isLoaded ? <Loader /> : <></>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Signina;
