import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
/* import mylogo from "../assets/images/mylogo.png"; */
import authsService from "../services/auths.service";
import mylogo from "../assets/images/letscoolfulllogowhite.png";
/* import myproject from "../assets/images/myproject.jpg"; */
import myproject from "../assets/images/letscoolvan.png";

const Home = () => {
  const userinfo = authsService.userinfo();

  let navigate = useNavigate();

  return (
    <div
      style={{ position: "absolute",backgroundRepeat:"no-repeat",backgroundSize:"cover", backgroundImage: `url(${myproject})` }}
      className="h-100   w-100"
    >
      <div
        style={{
          background: "linear-gradient(to bottom, #33ccff 0%, #0033cc  100%)",
          opacity: "0.9",
        }}
        className="d-flex flex-column justify-content-center  h-100"
      >
        <div className="d-flex flex-fill justify-content-center p-3 pt-5">
          <img
            className=""
            style={{ width: 120, height: 150 }}
            src={mylogo}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = { mylogo };
            }}
            alt="letscool"
          />
        </div>
        <div>
          <div className="p-3 pb-5">
            <a
              onClick={() => navigate("/signina")}
              className="btn btn-lg btn-light shadow d-block mb-2"
            >
              Continue with Employee
            </a>
            <a
              onClick={() => navigate("/signin")}
              className="btn btn-lg btn-dark shadow d-block"
            >
              Continue with Admin
            </a>
          </div>
          <div className="row d-none">
            <div className="col-md-6">
              <div className="m-3 p-3 shadow-sm bg-white m-2 rounded">
                <div style={{ fontSize: 20, fontWeight: 600 }}>
                  Employee Login
                </div>
                <div>
                  Click to sign in and keep connect with us, if you are having
                  the EMPLOYEE account on letscoolsupport.
                </div>
                <a
                  onClick={() => navigate("/signina")}
                  className="btn btn-primary mt-3"
                >
                  Go to login
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="m-3 p-3 shadow-sm bg-white m-2 rounded">
                <div style={{ fontSize: 20, fontWeight: 600 }}>Admin Login</div>
                <div>
                  Click to sign in and keep connect with us, if you are having
                  the ADMIN account on letscoolsupport.
                </div>
                <a
                  onClick={() => navigate("/signin")}
                  className="btn btn-primary mt-3"
                >
                  Go to login
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
