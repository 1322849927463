import React, { useEffect, useLayoutEffect, useState } from "react";
import { useFormik } from "formik";
import appoinmentService from "../services/empbooking.service";
import { Appoinmentvalidationschema } from "../components/Validation";
import ReactPaginate from "react-paginate";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";
import Loader from "../components/Loader";
import Toastmessage from "../components/Toastmessage";
import { Link, useNavigate } from "react-router-dom";
import Alertmsg from "../components/Alertmsg";
import imagename from "../assets/images/user.png";
import categoryService from "../services/category.service";
import authsService from "../services/auths.service";

const Empmaterial = ({ navigation }) => {
  let navigate = useNavigate();
  const userinfo = authsService.userinfo();
  const userinfos = authsService.userdata();

  const [date, setDate] = useState(new Date());
  const [today, setToday] = useState(format(new Date(), "yyyy-MM-dd"));

  const [iserror, setIserror] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [ismessage, setIsmessage] = useState(false);

  const [appoinments, setAppoinments] = useState([]);
  const [result, setResult] = useState([]);
  //smart table data
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(30);
  const [filter, setFilter] = useState("");
  const [filterreceipttype, setFilterreceipttype] = useState("");
  const [filterreceiptemp, setFilterreceiptemp] = useState("");

  const [search, setSearch] = useState("");
  const [between, setBetween] = useState(
    format(date, "yyyy-MM-dd") + "," + format(date, "yyyy-MM-dd")
  );
  const [sort, setSort] = useState("");
  const [group, setGroup] = useState("");
  const [betweenstart, setBetweenstart] = useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [betweenend, setBetweenend] = useState("");
  const [pagecount, setPagecount] = useState("");
  const [resultCount, setResultcount] = useState("");
  const [categorylist, setCategorylist] = useState([]);

  //filter arrays
  const statusfilterarr = [
    { status_id: "01", status_name: "pending" },
    { status_id: "02", status_name: "active" },
    { status_id: "03", status_name: "inactive" },
  ];
  const [statusfilterarray, setStatusfilterarray] = useState(statusfilterarr);
  //date range
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };
  const handleSelectdate = async (date) => {
    await setStartDate(date.selection.startDate);
    await setEndDate(date.selection.endDate);
    setBetween(
      format(date.selection.startDate, "yyyy-MM-dd") +
        " , " +
        format(date.selection.endDate, "yyyy-MM-dd")
    );
  };
  const resetdaterange = () => {
    setBetween("");
    setStartDate(new Date());
    setEndDate(new Date());
  };

  //use effects
  useEffect(() => {
    setBetween(
      format(new Date(), "yyyy-MM-dd") + "," + format(new Date(), "yyyy-MM-dd")
    );
    console.log(between);

    getallapptcategory();
    setFilterreceipttype(userinfo.user_name);

    getallAppoinment();
  }, []);
  useLayoutEffect(() => {
    if (between) {
      getallAppoinment();
      setFilterreceipttype(userinfo.user_name);
    }
  }, [between]);
  /* useLayoutEffect(() => {
      getallAppoinment();
    setFilterreceipttype(userinfo.user_name);
  }, [page, limit, filter,filterreceiptemp, search, between, sort]); */
  //date increase decrease
  const [da, setDa] = useState(new Date());
  const [beforedate, setBeforedate] = useState(
    new Date(da.setDate(da.getDate() - 1))
  );
  const [daa, setDaa] = useState(new Date());

  const [afterdate, setAfterdate] = useState(
    new Date(daa.setDate(daa.getDate() - 1))
  );
  const datedecrement = () => {
    if (beforedate < date) {
      var yesterday = date.setDate(date.getDate() - 1);
      setBetweenstart(format(yesterday, "yyyy-MM-dd"));
      setBetween(
        format(yesterday, "yyyy-MM-dd") + "," + format(yesterday, "yyyy-MM-dd")
      );
      getallAppoinment();
    }
  };
  const dateincrement = () => {
    if (afterdate >= date) {
      var yesterday = date.setDate(date.getDate() + 1);
      setBetweenstart(format(yesterday, "yyyy-MM-dd"));
      setBetween(
        format(yesterday, "yyyy-MM-dd") + "," + format(yesterday, "yyyy-MM-dd")
      );
      getallAppoinment();
    }
  };
  //get allappoinment
  const getallAppoinment = () => {
    setIserror(null);
    setIsmessage(null);
    setIsLoaded(true);
    var data = {
      limit: limit,
      page: page,
      filter: filter,
      filterreceipttype: filterreceipttype,
      filterreceiptemp: filterreceiptemp,
      search: userinfo.user_name,
      between: between,
      sort: sort,
      group: group,
    };
    appoinmentService
      .getallAppoinment(data)
      .then((res) => {
        console.log(res.data);

        if (res.data.result) {
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);

          setAppoinments(res.data.result.data);
          setResult(res.data);
          setPage(res.data.result.page);
          setLimit(res.data.result.limit);
          setPagecount(res.data.result.pagecount);
          setResultcount(res.data.result.resultcount);
        } else {
          setAppoinments([]);
          setIserror(false);
          setIsmessage(res.data.message);
          setIsLoaded(false);
        }
      })
      .catch((err) => {
        setAppoinments([]);

        setIserror(false);
        setIsmessage(err.message);
        setIsLoaded(false);
      });
  };
  //pagination
  const paginationfn = (event) => {
    setPage(event.selected + 1);
    console.log(event.selected + 1);
  };
  //search
  const searchfn = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };
  //filter
  const [filterArr, setfilterArr] = useState([]);
  const filterfn = (value) => {
    setPage(1);
    var index = filterArr.indexOf(value);
    if (index > -1) {
      filterArr.splice(index, 1);
      setFilter(filterArr.toString());
    } else {
      filterArr.push(value);
      setFilter(filterArr.toString());
    }
  };
  //sort
  const sortfn = (value) => {
    /*    setSort(value); */
    if (sort === value + " desc") {
      setSort(value + " asc");
      console.log(value + " asc");
    } else if (sort === value + " asc") {
      setSort("");
      console.log(value + "");
    } else {
      setSort(value + " desc");
      console.log(value + " desc");
    }
  };
  //between

  const betweenfn = (value, e) => {
    setPage(1);
    if (e === null || e === "") {
      setBetween("");
      setBetweenstart("");
      setBetweenend("");
    } else {
      if (value === "start") {
        setBetween(e + "," + e);
        console.log(e + "," + e);
        setBetweenstart(e);
        setBetweenend(e);
      } else if (value === "end") {
        setBetweenend(e);
        const dd = between.split(",");
        setBetween(dd[0] + "," + e);
        console.log(dd[0] + "," + e);
        setBetweenstart(dd[0]);
        setBetweenend(e);
      } else {
        setBetween("");
        setBetweenstart("");
        setBetweenend("");
      }
    }
  };

  //category  list
  const getallapptcategory = () => {
    var data = {
      limit: 10,
      page: 1,
      filter: "",
      filtercategory: "main",
      search: "",
      between: "",
      sort: "",
      group: "",
    };
    categoryService
      .getallCategory(data)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          setCategorylist(res.data.result.data);
        } else {
          setCategorylist([]);
        }
      })
      .catch((err) => {
        setCategorylist([]);
      });
  };
  // Call appoinment clicks to request another page.
  if (ismessage == "Network Error") {
    return <Alertmsg alertmsg={ismessage} type={iserror} />;
  }
  /* if (isLoaded == true) {
    return <Loader />
  } */
  const selectedcolor = (value) => {
    if (value > 10) {
      return "badge rounded-pill bg-success";
    } else if (value > 3) {
      return "badge rounded-pill bg-warning ";
    } else if (value > 0) {
      return "badge rounded-pill bg-danger";
    } else {
      return "badge rounded-pill bg-dark";
    }
  };
  return (
    <div className="">
      {/*  container */}
      <div className="appoinment-container">
        {/* tebale header part search,filter,sort */}
        <div className="d-md-flex  mb-2 ">
          <div className="input-group mb-3">
            <span className="input-group-text p-1" id="basic-addon1">
              <a className="p-2" onClick={() => datedecrement()}>
                {" "}
                <i className="fa fa-arrow-left"></i>
              </a>
            </span>
            <input
              id="betweenstart"
              className="form-control"
              value={betweenstart}
              readOnly={true}
              onChange={(e) =>
                betweenfn("start", e.target.value ? e.target.value : "")
              }
              placeholder="start"
              type="date"
            ></input>
            <span className="input-group-text p-1" id="basic-addon1">
              <a className="p-2" onClick={() => dateincrement()}>
                <i className="fa fa-arrow-right"></i>
              </a>
            </span>
          </div>
        </div>
        {/* table section*/}
        <div>
          {" "}
          {appoinments ? (
            <>
              {appoinments.map((data, index) => (
                <div
                  onClick={() => {
                    navigate(
                      "/employeematerialbooking?receipt_id=" + data.receipt_id
                    );
                  }}
                  key={index}
                  className="card shadow-sm border-0 mb-2"
                >
                  <div className="card-body p-2">
                    <div
                      style={{ fontSize: 12 }}
                      className="card-title d-flex flex-wrap"
                    >
                      <div className="flex-fill">{data.receipt_id}</div>
                      <div>{format(new Date(data.receipt_date), "PP")}</div>
                    </div>
                    <div className="card-text">
                      <div className="d-flex">
                        <span className="flex-fill">Team Member</span>
                        <span className="text-dark">{data.team_name}</span>
                      </div>
                      <div className="d-flex">
                        <span className="flex-fill">Type</span>
                        <span>{data.receipt_type}</span>
                      </div>
                      <div className="d-flex">
                        <span className="flex-fill">Created</span>
                        <span>{data.createdAt}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="text-center">
              <Alertmsg alertmsg={ismessage} />
            </div>
          )}
        </div>
        <div style={{ height: 100 }}></div>
        {/*  create button */}
        <div
          style={{ position: "fixed", width: "100%", zIndex: 999, bottom: 10 }}
          className=" text-center p-3"
        >
          <button
            onClick={() => {
              navigate("/employeematerialbooking");
            }}
            type="button"
            className="btn btn-primary btn-lg rounded-circle"
          >
            <i className="fa fa-plus"></i>
          </button>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Empmaterial;
