import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import servicehistoryService from "../services/servicehistory.service";
import { Servicehistoryvalidationschema } from "../components/Validation";
import ReactPaginate from "react-paginate";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";
import Loader from "../components/Loader";
import Toastmessage from "../components/Toastmessage";
import { useNavigate } from "react-router-dom";
import Alertmsg from "../components/Alertmsg";
import authsService from "../services/auths.service";
import categoryService from "../services/category.service";
import appoinmentService from "../services/inventory.service";
import materialService from "../services/material.service";
import Select from "react-select";
const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const Servicehistory = (props) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const userinfo = authsService.userinfo();
  const [categorylist, setCategorylist] = useState([]);
  const [materialarr, setMaterialarr] = useState([]);
  const [loadOptions, setLoadOptions] = useState([
    { value: "111", label: "3 x one" },
    { value: "222", label: "3 x two" },
  ]);
  const [filtermatcategory, setFiltermatcategory] = useState("");

  const ref = useRef();
  let navigate = useNavigate();
  const [iserror, setIserror] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [ismessage, setIsmessage] = useState(false);
  const [searchbox, setSearchbox] = useState("");
  const [filtercategory, setFiltercategory] = useState("");

  const [eventnameser, setEventname] = useState("create");
  const [servicinglist, setServicinglist] = useState([]);

  const [servicehistorys, setServicehistorys] = useState([]);
  const [result, setResult] = useState([]);
  //smart table data
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [filter, setFilter] = useState("hello empty");
  const [search, setSearch] = useState("");
  const [between, setBetween] = useState("");
  const [sort, setSort] = useState("");
  const [group, setGroup] = useState("");

  const [pagecount, setPagecount] = useState("");
  const [resultCount, setResultcount] = useState("");

  //filter arrays
  const statusfilterarr = [
    { status_id: "01", status_name: "pending" },
    { status_id: "02", status_name: "active" },
    { status_id: "03", status_name: "inactive" },
  ];
  const [filterarray, setFilterarray] = useState(statusfilterarr);
  const [receipt_id, setReceipt_id] = useState("");

  //date range
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };
  const handleSelectdate = async (date) => {
    await setStartDate(date.selection.startDate);
    await setEndDate(date.selection.endDate);
    setBetween(
      format(date.selection.startDate, "yyyy-MM-dd") +
        " , " +
        format(date.selection.endDate, "yyyy-MM-dd")
    );
  };
  const resetdaterange = () => {
    setBetween("");
    setStartDate(new Date());
    setEndDate(new Date());
  };
  //form initial value
  const formikser = useFormik({
    initialValues: {
      mat_his_id: "",
      receipt_id: "",
      receipt_type: "",
      mat_id: "",
      com_id: "",
      mat_name: "",
      mat_category: "",
      mat_qty: "",
      mat_cost: "",
      mat_date: "",
      team_membera: "",
      team_memberb: "",
      team_memberc: "",
      team_memberd: "",
      team_membere: "",
      mat_status: "completed",
      createdBy: userinfo.user_name,
    },
    enableReinitialize: true,
    validationSchema: Servicehistoryvalidationschema,
    /*  validateOnChange: false,
         validateOnBlur: false, */
    onSubmit: (values, { resetForm }) => {
      //alert(JSON.stringify(values, null, 1));
      //resetForm()
    },
  });
  //use effects
  useEffect(() => {
    setFilter(props.receipt_id);
    getallServicehistory();
    getallmaterial();
    getallCategory();
    formikser.setFieldValue("createdBy", userinfo.user_name);
    formikser.setFieldValue("com_id", props.com_id);
    formikser.setFieldValue("receipt_id", props.receipt_id);
    formikser.setFieldValue("receipt_type", props.receipt_type);
    formikser.setFieldValue("mat_date", props.receipt_date);
    formikser.setFieldValue(
      "team_membera",
      props.team_membera != "" ? props.team_membera : ""
    );
    formikser.setFieldValue(
      "team_memberb",
      props.team_memberb != "" ? props.team_memberb : ""
    );
    formikser.setFieldValue(
      "team_memberc",
      props.team_memberc != "" ? props.team_memberc : ""
    );
    formikser.setFieldValue(
      "team_memberd",
      props.team_memberd != "" ? props.team_memberd : ""
    );
    formikser.setFieldValue(
      "team_membere",
      props.team_membere != "" ? props.team_membere : ""
    );
  }, []);
  useLayoutEffect(() => {
    setFilter(props.receipt_id);
    setReceipt_id(props.receipt_id);
  }, [props.receipt_id]);
  useLayoutEffect(() => {
    getallServicehistory();
  }, [page, limit, filter, between, sort]);

  useLayoutEffect(() => {
    getallmaterial();
  }, [filtermatcategory, search]);

  /*   useLayoutEffect(() => {
    console.log(formikser.values);
    if (props.receipt_event === "delete") {
      getallServicehistory();
    } else if (props.receipt_event === "update") {
      getallServicehistory();
    } else {
      getallServicehistory();
    }
  }, [props.receipt_event]); */
  useLayoutEffect(() => {
    console.log(props);
    console.log(formikser.values);

    formikser.setFieldValue("com_id", props.com_id);
    formikser.setFieldValue("receipt_id", props.receipt_id);
    formikser.setFieldValue("receipt_type", props.receipt_type);
    formikser.setFieldValue("mat_date", props.receipt_date);
    formikser.setFieldValue(
      "team_membera",
      props.team_membera != "" ? props.team_membera : ""
    );
    formikser.setFieldValue(
      "team_memberb",
      props.team_memberb != "" ? props.team_memberb : ""
    );
    formikser.setFieldValue(
      "team_memberc",
      props.team_memberc != "" ? props.team_memberc : ""
    );
    formikser.setFieldValue(
      "team_memberd",
      props.team_memberd != "" ? props.team_memberd : ""
    );
    formikser.setFieldValue(
      "team_membere",
      props.team_membere != "" ? props.team_membere : ""
    );
  }, [
    props.receipt_id,
    props.com_id,
    props.receipt_date,
    props.team_membera,
    props.team_memberb,
    props.team_memberc,
    props.team_memberd,
    props.team_membere,
  ]);
  //current event
  const servicehistoryEvent = (eventnameser, value) => {
    if (value != "") {
      getServicehistory(value);
      setEventname(eventnameser);
    }
    setEventname(eventnameser);
  };
  //get allservicehistory
  const getallServicehistory = async () => {
    setIsLoaded(true);
    var data = {
      limit: limit,
      page: page,
      filter: filter,
      search: "",
      between: between,
      sort: sort,
      group: group,
    };
    return await servicehistoryService
      .getallServicehistory(data)
      .then((res) => {
        console.log(res.data.result);
        if (res.data.result) {
          setIsLoaded(false);
          // setIserror(res.data.status);
          //setIsmessage(res.data.message);
          setServicehistorys(res.data.result.data ? res.data.result.data : []);
          setResult(res.data);
          setPage(res.data.result.page);
          setLimit(res.data.result.limit);
          setPagecount(res.data.result.pagecount);
          setResultcount(res.data.result.resultcount);
          console.log(res.data);
        } else {
          setServicehistorys([]);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
        }
      })
      .catch((err) => {
        setServicehistorys([]);
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
      });
  };
  //pagination
  const paginationfn = (event) => {
    setPage(event.selected + 1);
    console.log(event.selected + 1);
  };
  //search
  const searchfn = (e) => {
    setSearch(e.target.value);
  };
  //filter
  const [filterArr, setfilterArr] = useState([]);
  const filterfn = (value) => {
    setPage(1);
    var index = filterArr.indexOf(value);
    if (index > -1) {
      filterArr.splice(index, 1);
      setFilter(filterArr.toString());
    } else {
      filterArr.push(value);
      setFilter(filterArr.toString());
    }
  };
  //sort
  const sortfn = (value) => {
    /*    setSort(value); */
    if (sort == value + " desc") {
      setSort(value + " asc");
      console.log(value + " asc");
    } else if (sort == value + " asc") {
      setSort("");
      console.log(value + "");
    } else {
      setSort(value + " desc");
      console.log(value + " desc");
    }
  };
  //between
  const [betweenstart, setBetweenstart] = useState("");
  const [betweenend, setBetweenend] = useState("");
  const betweenfn = (value, e) => {
    setPage(1);
    if (e === null || e === "") {
      setBetween("");
      setBetweenstart("");
      setBetweenend("");
    } else {
      if (value == "start") {
        setBetween(e + "," + e);
        console.log(e + "," + e);
        setBetweenstart(e);
        setBetweenend(e);
      } else if (value == "end") {
        setBetweenend(e);
        const dd = between.split(",");
        setBetween(dd[0] + "," + e);
        console.log(dd[0] + "," + e);
        setBetweenstart(dd[0]);
        setBetweenend(e);
      } else {
        setBetween("");
        setBetweenstart("");
        setBetweenend("");
      }
    }
  };
  //get servicehistory
  const getServicehistory = (value) => {
    setIserror(null);
    setIsmessage(null);
    setIsLoaded(true);
    servicehistoryService
      .getServicehistory(value)
      .then((res) => {
        console.log(res.data);

        if (res.data.result) {
          console.log(res.data);
          setIsLoaded(false);
          let data = res.data.result;
          formikser.setValues({
            mat_his_id: data.mat_his_id,
            receipt_id: data.receipt_id,
            receipt_type: data.receipt_type,
            com_id: data.com_id,
            mat_name: data.mat_name,
            mat_category: data.mat_category,
            mat_qty: data.mat_qty,
            mat_cost: data.mat_cost,
            mat_date: data.mat_date,
            mat_status: data.mat_status,
            createdBy: userinfo.user_name,
          });
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
      });
  };
  const cleardata = () => {
    formikser.setFieldValue("mat_name", "");
    formikser.setFieldValue("mat_qty", "");
    formikser.setFieldValue("mat_cost", "");
  };
  //get existing appoinment
  const getexistingappoinment = (data) => {
    props.newFunc(data);
    /* console.log(data); */
  };
  //create servicehistory
  const createServicehistory = () => {
    console.log(formikser.values);
    formikser.setFieldValue("createdBy", userinfo.user_name);
    setIserror(null);
    setIsmessage(null);
    setIsLoaded(true);
    servicehistoryService
      .createServicehistory(formikser.values)
      .then((res) => {
        if (res.data.status) {
          console.log(res.data);
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          getallServicehistory();
          ref.current.log();
          cleardata();
        } else {
          console.log(res.data);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
          ref.current.log();
        }
      })
      .catch((err) => {
        console.log(err);

        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
        ref.current.log();
      });
  };

  //update servicehistory
  const updateServicehistory = (value) => {
    formikser.setFieldValue("createdBy", userinfo.user_name);
    console.log(JSON.stringify(formikser.values, null, 2));
    servicehistoryService
      .updateServicehistory(formikser.values)
      .then((res) => {
        console.log(res.data);
        if (res.data.status) {
          console.log(res.data);
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          ref.current.log();
          getallServicehistory();
          setEventname("create");
          cleardata();
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
          ref.current.log();
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
        ref.current.log();
      });
  };
  //update servicehistory
  const updateServicehistorybyapptid = (value) => {
    formikser.setFieldValue("createdBy", userinfo.user_name);
    console.log(JSON.stringify(formikser.values, null, 2));
    servicehistoryService
      .updateServicehistorybyapptid(formikser.values)
      .then((res) => {
        if (res.data.status) {
          console.log(res.data);
          getallServicehistory();
          setEventname("create");
          cleardata();
        } else {
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
        ref.current.log();
      });
  };
  //delete servicehistory
  const deleteServicehistory = (value) => {
    console.log(value);
    servicehistoryService
      .deleteServicehistory(String(value))
      .then((res) => {
        if (res.data.status) {
          console.log("succes");
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          getallServicehistory();
          setEventname("create");
          ref.current.log();
          cleardata();
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
          ref.current.log();
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
        ref.current.log();
      });
  };
  const deleteServicehistorybyapptid = (value) => {
    console.log(value);
    servicehistoryService
      .deleteServicehistorybyapptid(String(value))
      .then((res) => {
        if (res.data.status) {
          console.log("success");
          setReceipt_id("");
          getallServicehistory();
          setEventname("create");
          cleardata();
        } else {
        }
      })
      .catch((err) => {});
  };
  const [apptarr, setApparr] = useState([]);
  const [search_customer, setSearch_customer] = useState([]);

  //servicing list
  const getallCategory = (value) => {
    var data = {
      limit: 10,
      page: 1,
      filter: "",
      filtercategory: filtercategory,
      search: "",
      between: "",
      sort: "",
      group: "",
    };
    categoryService
      .getallCategory(data)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          setServicinglist(res.data.result.data);
        } else {
          setServicinglist([]);
        }
      })
      .catch((err) => {
        setServicinglist([]);
      });
  };
  //category  list
  const getallapptcategory = () => {
    var data = {
      limit: 10,
      page: 1,
      filter: "",
      filtercategory: "main",
      search: "",
      between: "",
      sort: "",
      group: "",
    };
    categoryService
      .getallCategory(data)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          setCategorylist(res.data.result.data);
        } else {
          setCategorylist([]);
        }
      })
      .catch((err) => {
        setCategorylist([]);
      });
  };
  //select onchange
  const selectonchange = (e) => {
    console.log(e);
    formikser.setFieldValue("mat_name", e.mat_name);
    formikser.setFieldValue("mat_id", e.mat_id);
    formikser.setFieldValue("mat_qty", 1);
    formikser.setFieldValue(
      "mat_cost",
      e.mat_price == null
        ? 0
        : e.mat_price == undefined
        ? 0
        : e.mat_price == ""
        ? 0
        : e.mat_price
    );
    formikser.setFieldValue("mat_category", e.mat_category);
    document.getElementById("mysearchInput").value = "";
    /* setSelectedOption(e.label); */
    setSearch("");
  };
  //material  list
  const getallmaterial = () => {
    var data = {
      limit: 30,
      page: 1,
      filter: "",
      filtercategory: filtermatcategory,
      search: search,
      between: "",
      sort: "",
      group: "",
    };
    materialService
      .getallMaterial(data)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          setMaterialarr(res.data.result.data);
          var arra = res.data.result.data;
          var sdsd = arra.map(({ mat_category, mat_id, mat_name }) => ({
            value: mat_id + "," + mat_category,
            label: mat_name,
          }));
          setLoadOptions(sdsd);
          console.log(sdsd);
        } else {
          setMaterialarr([]);
        }
      })
      .catch((err) => {
        setMaterialarr([]);
      });
  };

  const [wewe, setWewe] = useState("");
  const sss = (value) => {
    var d = new Date(value);
    d.setDate(d.getDate() - 3);
    console.log(d);
    return d;
  };
  // Call servicehistory clicks to request another page.
  if (ismessage == "Network Error") {
    return <Alertmsg alertmsg={ismessage} type={iserror} />;
  }
  return (
    <div className="">
      <div className="">
        {/*  loader and error handler */}
        {<Toastmessage ref={ref} type={iserror} errormsg={ismessage} />}
        {isLoaded ? <Loader /> : <></>}
      </div>
      {receipt_id ? (
        <>
          {/*  container */}
          <div className="servicehistory-container bg-white ">
            {/* servicehistory form */}
            <div className="card bg-transparent border-0  ">
              <div className="card-body p-2">
                <div className="d-flex p-0 m-0 d-none">
                  <h5 className=" card-title p-0 m-0 me-auto">
                    Servicehistory{" "}
                  </h5>
                  {eventnameser == "create" ? (
                    <></>
                  ) : (
                    <div>
                      <div className="dropdown">
                        <a
                          className="btn  btn-link"
                          href="#"
                          role="button"
                          id="servicehistoryeventdropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i
                            className="fa fa-ellipsis-v"
                            aria-hidden="true"
                          ></i>
                        </a>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="servicehistoryeventdropdownMenuLink"
                        >
                          {servicinglist.map((category, index) => (
                            <li key={index}>
                              <a
                                onClick={() => {
                                  setServicinglist(category.category_name);
                                }}
                                className="dropdown-item"
                              >
                                {category.category_name}
                              </a>
                            </li>
                          ))}
                          <li>
                            <a
                              onClick={() => {
                                servicehistoryEvent("edit", "");
                              }}
                              className="dropdown-item"
                            >
                              Edit
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => servicehistoryEvent("delete", "")}
                              className="dropdown-item"
                            >
                              Delete
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>

                <form onSubmit={formikser.handleSubmit}>
                  <div className="row">
                    <div className="col-md-12">
                      {/*  <label className="form-label">Material name</label> */}
                      {/*  <Select
                        defaultValue={selectedOption}
                        onChange={(e) => selectonchange(e)}
                        options={loadOptions}
                        isSearchable={true}
                      /> */}
                      <div style={{ position: "relative" }}>
                        <div className="input-group mb-1">
                          <button
                            className="btn btn-outline-secondary dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {filtermatcategory}
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="servicehistoryeventdropdownMenuLink"
                          >
                            <li>
                              <a
                                onClick={() => {
                                  setFiltermatcategory("");
                                }}
                                className="dropdown-item border-bottom"
                              >
                                Clear
                              </a>
                            </li>
                            {servicinglist &&
                              servicinglist.map((category, index) => (
                                <li key={index}>
                                  <a
                                    onClick={() => {
                                      setFiltermatcategory(
                                        category.category_name
                                      );
                                    }}
                                    className="dropdown-item"
                                  >
                                    {category.category_name}
                                  </a>
                                </li>
                              ))}
                          </ul>
                          <input
                            className="form-control"
                            onChange={searchfn}
                            placeholder="search"
                            type="Search"
                            id="mysearchInput"
                          ></input>
                        </div>

                        <div
                          className={filtermatcategory!="" ||search!=""?"border rounded d-block":"border rounded d-none"}
                          style={{
                          /*   position: "absolute", */
                            maxHeight: 150,
                            width: "100%",
                            backgroundColor: "white",
                            zIndex: 10,
                            overflowY: "auto",
                          }}
                        >
                          {materialarr ? (
                            <ul className="list-group list-group-flush ">
                              {materialarr.map((data, index) => (
                                <li
                                  className="list-group-item bg-light p-0"
                                  key={index}
                                >
                                  <button
                                    className="btn w-100 text-start"
                                    onClick={() => selectonchange(data)}
                                  >
                                    {data.mat_name}
                                  </button>
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <>
                              <div className="p-3">0 record found</div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ">
                      <label htmlFor="mat_name" className="form-label">
                        Material name
                      </label>
                      <div className="input-group">
                        <input
                          className="form-control"
                          placeholder="material name"
                          id="mat_name"
                          type="text"
                          readOnly={true}
                          {...formikser.getFieldProps("mat_name")}
                        />
                      </div>
                      <div>
                        {formikser.touched.mat_name &&
                        formikser.errors.mat_name ? (
                          <div className="error">
                            {formikser.errors.mat_name}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-2 ">
                      <label htmlFor="mat_qty" className="form-label">
                        Qty
                      </label>
                      <div className="input-group">
                        <input
                          className="form-control"
                          placeholder="Qty"
                          id="mat_qty"
                          type="number"
                          disabled={
                            eventnameser == "view"
                              ? true
                              : eventnameser == "delete"
                              ? true
                              : false
                          }
                          {...formikser.getFieldProps("mat_qty")}
                        />
                      </div>
                      {/*  <div>
                                        {formikser.touched.ser_unit && formikser.errors.ser_unit ? (
                                            <div className='error'>{formikser.errors.ser_unit}</div>
                                        ) : null}
                                    </div> */}
                    </div>

                    <div className="col-md-2 ">
                      <label htmlFor="mat_cost" className="form-label">
                        Cost
                      </label>
                      <div className="input-group">
                        <input
                          className="form-control"
                          placeholder="Cost"
                          id="mat_cost"
                          type="number"
                          readOnly={true}
                          {...formikser.getFieldProps("mat_cost")}
                        />
                      </div>
                      <div>
                        {formikser.touched.mat_cost &&
                        formikser.errors.mat_cost ? (
                          <div className="error">
                            {formikser.errors.mat_cost}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div style={{ paddingTop: 36 }} className="col-md-4">
                      {eventnameser === "create" ? (
                        <>
                          {/* create button based on contract service */}

                          <button
                            disabled={!formikser.isValid}
                            onClick={() => createServicehistory()}
                            className="btn btn-primary me-2"
                          >
                            Add
                          </button>
                        </>
                      ) : eventnameser === "edit" ? (
                        <button
                          onClick={() =>
                            updateServicehistory(formikser.values.mat_his_id)
                          }
                          className="btn btn-primary me-2"
                        >
                          Update
                        </button>
                      ) : eventnameser === "delete" ? (
                        <button
                          onClick={() =>
                            deleteServicehistory(formikser.values.mat_his_id)
                          }
                          className="btn btn-danger me-2 "
                        >
                          Delete
                        </button>
                      ) : (
                        <></>
                      )}
                      <button
                        type="submit"
                        disabled={!formikser.dirty}
                        onClick={() => {
                          servicehistoryEvent("create", "");
                          cleardata();
                        }}
                        className="btn btn-secondary"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* tebale header part search,filter,sort d-md-flex*/}
            <div className="d-none mb-2 ">
              {/* create servicehistory */}
              <div className="m-1">
                <button
                  onClick={formikser.handleReset}
                  type="submit"
                  className="btn btn-primary  w-100"
                >
                  <i className="fa fa-plus-circle"></i> Create
                </button>
              </div>
              {/* filter status*/}
              <div className="dropdown m-1">
                <button
                  className="btn btn-primary w-100 dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fa fa-filter" aria-hidden="true"></i> Filter
                </button>
                <ul
                  className="dropdown-menu p-2"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li className="text-secondary">Servicehistory Status</li>
                  {filterarray.map((filterdata, index) => (
                    <li key={index}>
                      <div className="form-check">
                        <input
                          onChange={(e) => filterfn(filterdata.status_name)}
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        ></input>
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          {filterdata.status_name}
                        </label>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              {/* search */}
              <div className=" m-1">
                <input
                  className="form-control"
                  onChange={searchfn}
                  placeholder="search"
                  type="search"
                ></input>
              </div>
              {/*  date range  */}
              <div className="m-1">
                <div className="">
                  <button
                    className="btn bg-white dropdown-toggle"
                    id="dropdownMenuClickableInside"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                  >
                    <i className="fa fa-calendar" aria-hidden="true"></i>{" "}
                    {between}
                    {between ? (
                      <a
                        className="ms-2 me-2 p-1 text-secondary"
                        onClick={resetdaterange}
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </a>
                    ) : (
                      <></>
                    )}
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuClickableInside"
                  >
                    <DateRangePicker
                      ranges={[selectionRange]}
                      onChange={(date) => handleSelectdate(date)}
                    />
                  </ul>
                </div>
              </div>
              {/* between */}
              {/*  <div className="m-1">
                            <input
                                id="betweenstart"
                                className="form-control"
                                value={betweenstart}
                                onChange={(e) => betweenfn("start", e.target.value ? e.target.value : '')}
                                placeholder="start"
                                type="date"
                            ></input>
                        </div>
                        <div className="m-1">
                            <input
                                id="betweenend"
                                className="form-control"
                                value={betweenend}
                                onChange={(e) => betweenfn("end", e.target.value)}
                                placeholder="start"
                                type="date"
                            ></input>
                        </div> */}
              {/*  sort */}
              <div className="m-1 ms-auto">
                <div className="input-group ">
                  <span
                    className="input-group-text bg-white border-end-0"
                    id="basic-addon1"
                  >
                    Sort :{" "}
                  </span>
                  <select
                    value={sort}
                    onChange={(e) => {
                      setSort(e.target.value);
                    }}
                    className="form-select border-start-0"
                    aria-label="ser_status"
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="ser_name">servicehistory</option>
                    <option value="ser_status">Status</option>
                    <option value="">Clear</option>
                  </select>
                </div>
              </div>
            </div>
            {/* table section*/}
            <div className="table-responsive card border-0 bg-transparent mb-2">
              <table className="table ">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Unit</th>
                    <th scope="col">cost</th>
                    {/*    <th scope="col">date</th> */}
                    <th style={{ width: 200 }} scope="col">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* table data */}
                  {servicehistorys ? (
                    <>
                      {servicehistorys.map((data, index) => (
                        <tr key={index}>
                          <td>{data.mat_name}</td>
                          <td>{data.mat_qty}</td>
                          <td>
                            <strong>${data.mat_cost}</strong>&nbsp;
                          </td>
                          {/*    <td>{data.ser_date}</td> */}
                          <td className="p-1 m-0">
                            <button
                              onClick={() =>
                                servicehistoryEvent("view", data.mat_his_id)
                              }
                              className="btn  me-2 "
                            >
                              <i className="fa fa-eye" aria-hidden="true"></i>
                            </button>
                            <button
                              onClick={() => {
                                servicehistoryEvent("edit", data.mat_his_id);
                              }}
                              className="btn  me-2 "
                            >
                              <i
                                className="fa fa-pencil-square"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <button
                              onClick={() =>
                                servicehistoryEvent("delete", data.mat_his_id)
                              }
                              className="btn  me-2 "
                            >
                              <i
                                className="fa fa-trash-o"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        0 data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <div className="d-none justify-content-end ">
                <div className="p-1 "> Items per page </div>
                <div>
                  {" "}
                  <select
                    value={limit}
                    onChange={(e) => {
                      setLimit(e.target.value);
                      setPage(1);
                    }}
                    className="form-select form-select-sm"
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                  </select>
                </div>
                <div className="p-1 me-4">
                  {page * limit - limit}-{page * limit} of {resultCount}
                </div>
                <ReactPaginate
                  previousLabel="< Prev"
                  nextLabel="Next >"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  pageCount={pagecount}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={3}
                  onPageChange={paginationfn}
                  containerClassName="pagination"
                  activeClassName="actives"
                  disabledLinkClassName="text-secondary"
                  forcePage={page - 1}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Servicehistory;
