import axios from "axios";
import { serverURL, headeroption, headeroptionupload } from "../http-common";

class AnalyticsService {
  getallAppoinment(data) {
    return axios.get(serverURL + "/analytics-api?req=smarttableAppoinment", {
      params: data,
      headers: headeroption,
    });
  }
  getallStacklist(data) {
    return axios.get(serverURL + "/analytics-api?req=smarttableStacklist", {
      params: data,
      headers: headeroption,
    });
  }
  
  getallemployeeStacklist(data) {
    return axios.get(serverURL + "/analytics-api?req=smarttableemployeeStacklist", {
      params: data,
      headers: headeroption,
    });
  }
  getallcompanyStacklist(data) {
    return axios.get(serverURL + "/analytics-api?req=smarttablecompanyStacklist", {
      params: data,
      headers: headeroption,
    });
  }
  getallAppoinmentjoin(data) {
    return axios.get(
      serverURL + "/analytics-api?req=smarttablejoinAppoinment",
      {
        params: data,
        headers: headeroption,
      }
    );
  }
  
  getallemployeeMaterialUsage(data) {
    return axios.get(serverURL + "/analytics-api?req=smarttablematerialStacklist", {
      params: data,
      headers: headeroption,
    });
  }
  getallcompanyMaterialUsage(data) {
    return axios.get(serverURL + "/analytics-api?req=smarttablematerialcompanyStacklist", {
      params: data,
      headers: headeroption,
    });
  }

  getallCompanyoverview(data) {
    return axios.get(serverURL + "/analytics-api?req=smarttablejoinCompanyoverview", {
      params: data,
      headers: headeroption,
    });
  }
  getallEmployeeoverview(data) {
    return axios.get(serverURL + "/analytics-api?req=smarttablejoinEmployeeoverview", {
      params: data,
      headers: headeroption,
    });
  }
  getallCompanymonthwiseoverview(data) {
    return axios.get(
      serverURL + "/analytics-api?req=smarttablejoinCompanymonthwiseoverview",
      {
        params: data,
        headers: headeroption,
      }
    );
  }
}
export default new AnalyticsService();
