import React, { useState, useContext, useRef } from 'react'
import { Passwordupdatevalidationschema } from '../components/Validation'
import { useFormik } from 'formik';
import { useEffect } from 'react';
import authsService from '../services/auths.service';
import Alertmsg from '../components/Alertmsg';
import Loader from '../components/Loader';
import Toastmessage from '../components/Toastmessage';
import { useNavigate } from 'react-router-dom';

const Resetpassword = (props) => {
  const ref = useRef();
  let navigate = useNavigate();
  const [iserror, setIserror] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [ismessage, setIsmessage] = useState(false);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const jwt = params.get('u_token');
  const [useremail, setUseremail] = useState('');

  const [showPass, setShowPass] = useState(false);
  const formik = useFormik({
    initialValues: {
      user_email: '',
      user_password: '',
      confirmPassword: ''
    },
    validationSchema: Passwordupdatevalidationschema,
    enableReinitialize: true,
    onSubmit: values => {
      // alert(JSON.stringify(values, null, 2));
      console.log(values, null, 2);
    },
  });
  useEffect(() => {
    tokenvalue();
  }, [])
  const tokenvalue = (() => {
    // let jwt = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'
    if (useremail != '') {

    } else {
      const tokenObj = authsService.jwtdecode(jwt);
      setUseremail(tokenObj.user_email);
      formik.setFieldValue('user_email', tokenObj.user_email)

    }
  })
  const updatepassword = (() => {
    console.log(formik.values);
    setIserror(null)
    setIsmessage(null)
    setIsLoaded(true);
    authsService.updatePassword(formik.values).then((res) => {
      console.log(res.data);
      if (res.data.status) {
        setIsLoaded(false);
        setIserror(res.data.status);
        setIsmessage(res.data.message);
        ref.current.log();
        navigate('/signIn');
      } else {
        setIserror(res.data.status);
        setIsmessage(res.data.message);
        setIsLoaded(false);
      }
    }).catch((err) => {
      //console.log('iserror' + err);
      setIserror(true);
      setIsmessage(err.message);
      setIsLoaded(false);
      localStorage.setItem('isloggedin', false);
    })
  })
  if (jwt) {
    return (
      <div className='container resetpassword-container'>
        <div className=' p-5'>
          <div className="row ">
            <div className="col">
              <h1 className=''>Reset your password</h1>
              <p className="description ">Enter a new password for account : {useremail}</p>
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <label className='form-label me-4' htmlFor="user_password">password</label>
                    <div className='input-group '>
                      <input
                        className='form-control border-end-0'
                        id="user_password"
                        type={showPass ? 'text' : 'password'}
                        {...formik.getFieldProps('user_password')}
                      />
                      <span onClick={() => setShowPass(!showPass)} className="input-group-text bg-white border-start-0"><i className={showPass ? 'fa fa-eye-slash' : 'fa fa-eye'}></i> </span>

                    </div>
                    {formik.touched.user_password && formik.errors.user_password ? (
                      <div className='error'>{formik.errors.user_password}</div>
                    ) : null}
                  </div>
                  <div className="col-md-12">
                    <label className='form-label me-4' htmlFor="confirmPassword">confirmPassword</label>
                    <div className='input-group '>
                      <input
                        className='form-control'
                        id="confirmPassword"
                        type="text"
                        {...formik.getFieldProps('confirmPassword')}
                      />
                    </div>
                    {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                      <div className='error'>{formik.errors.confirmPassword}</div>
                    ) : null}
                  </div>
                </div>
                <button type='button' onClick={updatepassword} className="btn btn-primary mt-3" >Save changes</button>
              </form>
            </div>
          </div>
          <div className='mt-2'>
            {/*  loader and iserror handler */}
            {iserror === false || iserror === true ? <Alertmsg alertmsg={ismessage} type={iserror} /> : <></>}
            {<Toastmessage ref={ref} type={iserror} errormsg={ismessage} />}
            {isLoaded ? <Loader /> : <></>}
          </div>
        </div>
      </div>
    )
  } else {
    return <div>
      <div className='p-3 text-danger0'>Token not available</div>
    </div>
  }

}

export default Resetpassword