import React, { useEffect, useLayoutEffect, useState } from "react";
import { useFormik } from "formik";
import employeeService from "../services/employee.service";
import { Employeevalidationschema } from "../components/Validation";
import ReactPaginate from "react-paginate";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";
import Loader from "../components/Loader";
import Toastmessage from "../components/Toastmessage";
import { Link, useNavigate } from "react-router-dom";
import Alertmsg from "../components/Alertmsg";
import imagename from "../assets/images/user.png";
import { serverURL } from "../http-common";

const Employee = ({ navigation }) => {
  let navigate = useNavigate();
  const [iserror, setIserror] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [ismessage, setIsmessage] = useState(false);

  const [employees, setEmployees] = useState([]);
  const [result, setResult] = useState([]);
  //smart table data
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState("");
  const [search, setSearch] = useState("");
  const [between, setBetween] = useState("");
  const [sort, setSort] = useState("emp_fullname ASC");
  const [group, setGroup] = useState("");

  const [pagecount, setPagecount] = useState("");
  const [resultCount, setResultcount] = useState("");

  //filter arrays
  const statusfilterarr = [
    { status_id: "01", status_name: "pending" },
    { status_id: "02", status_name: "active" },
    { status_id: "03", status_name: "inactive" },
  ];
  const [statusfilterarray, setStatusfilterarray] = useState(statusfilterarr);
  //date range
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };
  const handleSelectdate = async (date) => {
    await setStartDate(date.selection.startDate);
    await setEndDate(date.selection.endDate);
    setBetween(
      format(date.selection.startDate, "yyyy-MM-dd") +
        " , " +
        format(date.selection.endDate, "yyyy-MM-dd")
    );
  };
  const resetdaterange = () => {
    setBetween("");
    setStartDate(new Date());
    setEndDate(new Date());
  };

  //use effects
  useEffect(() => {
    getallEmployee();
  }, []);
  useLayoutEffect(() => {
    getallEmployee();
  }, [page, limit, filter, search, between, sort]);

  //get allemployee
  const getallEmployee = async () => {
    setIserror(null);
    setIsmessage(null);
    setIsLoaded(true);
    var data = {
      limit: limit,
      page: page,
      filter: filter,
      search: search,
      between: between,
      sort: sort,
      group: group,
    };
    return await employeeService
      .getallEmployee(data)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);

          setEmployees(res.data.result.data);
          setResult(res.data);
          setPage(res.data.result.page);
          setLimit(res.data.result.limit);
          setPagecount(res.data.result.pagecount);
          setResultcount(res.data.result.resultcount);
          console.log(res.data);
        } else {
          setIserror(false);
          setIsmessage(res.data.message);
          setIsLoaded(false);
        }
      })
      .catch((err) => {
        setIserror(false);
        setIsmessage(err.message);
        setIsLoaded(false);
      });
  };
  //pagination
  const paginationfn = (event) => {
    setPage(event.selected + 1);
    console.log(event.selected + 1);
  };
  //search
  const searchfn = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };
  //filter
  const [filterArr, setfilterArr] = useState([]);
  const filterfn = (value) => {
    setPage(1);
    var index = filterArr.indexOf(value);
    if (index > -1) {
      filterArr.splice(index, 1);
      setFilter(filterArr.toString());
    } else {
      filterArr.push(value);
      setFilter(filterArr.toString());
    }
  };
  //sort
  const sortfn = (value) => {
    /*    setSort(value); */
    if (sort === value + " desc") {
      setSort(value + " asc");
      console.log(value + " asc");
    } else if (sort === value + " asc") {
      setSort("");
      console.log(value + "");
    } else {
      setSort(value + " desc");
      console.log(value + " desc");
    }
  };
  //between
  const [betweenstart, setBetweenstart] = useState("");
  const [betweenend, setBetweenend] = useState("");
  const betweenfn = (value, e) => {
    setPage(1);
    if (e === null || e === "") {
      setBetween("");
      setBetweenstart("");
      setBetweenend("");
    } else {
      if (value === "start") {
        setBetween(e + "," + e);
        console.log(e + "," + e);
        setBetweenstart(e);
        setBetweenend(e);
      } else if (value === "end") {
        setBetweenend(e);
        const dd = between.split(",");
        setBetween(dd[0] + "," + e);
        console.log(dd[0] + "," + e);
        setBetweenstart(dd[0]);
        setBetweenend(e);
      } else {
        setBetween("");
        setBetweenstart("");
        setBetweenend("");
      }
    }
  };
  const selectedcolor = (value) => {
    if (value > 10) {
      return "badge rounded-pill bg-success";
    } else if (value > 5) {
      return "badge rounded-pill bg-warning ";
    } else if (value > 0) {
      return "badge rounded-pill bg-danger";
    } else {
      return "badge rounded-pill bg-dark";
    }
  };
  // Call employee clicks to request another page.
  if (ismessage == "Network Error") {
    return <Alertmsg alertmsg={ismessage} type={iserror} />;
  }
  /* if (isLoaded == true) {
    return <Loader />
  } */

  return (
    <div className=" ">
      <div className="flex-fill mb-3">
          <h2>Employee</h2>
        </div>
      {/*  container */}
      <div className="employee-container">
        {/* tebale header part search,filter,sort */}
        <div className="d-md-flex  mb-2 ">
          {/* create employee */}
          <div className="m-1">
            <button
              onClick={() => {
                navigate("/employee/employeeevent");
              }}
              type="submit"
              className="btn btn-primary  w-100"
            >
              <i className="fa fa-plus-circle"></i> Create
            </button>
          </div>
          {/* filter status*/}
          <div className="dropdown m-1">
            <button
              className="btn btn-primary w-100 dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fa fa-filter" aria-hidden="true"></i> Filter
            </button>
            <ul
              className="dropdown-menu p-2"
              aria-labelledby="dropdownMenuButton1"
            >
              <li className="text-secondary">Employee Status</li>
              {statusfilterarray.map((statusfilterdata, index) => (
                <li key={index}>
                  <div className="form-check">
                    <input
                      onChange={(e) => filterfn(statusfilterdata.status_name)}
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    ></input>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      {statusfilterdata.status_name}
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          {/* search */}
          <div className=" m-1">
            <input
              className="form-control"
              onChange={searchfn}
              placeholder="search"
              type="search"
            ></input>
          </div>
          {/*  date range  */}
          {/* <div className='m-1'>
            <div className="">
              <button className="btn bg-white dropdown-toggle" id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                <i className="fa fa-calendar" aria-hidden="true"></i> {between}{between ? <a className='ms-2 me-2 p-1 text-secondary' onClick={resetdaterange}><i className="fa fa-times" aria-hidden="true"></i></a> : <></>}
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuClickableInside">
                <DateRangePicker
                  ranges={[selectionRange]}
                  onChange={(date) => handleSelectdate(date)}
                />
              </ul>
            </div>
          </div> */}
          {/* between */}
          {/*  <div className="m-1">
                        <input
                            id="betweenstart"
                            className="form-control"
                            value={betweenstart}
                            onChange={(e) => betweenfn("start", e.target.value ? e.target.value : '')}
                            placeholder="start"
                            type="date"
                        ></input>
                    </div>
                    <div className="m-1">
                        <input
                            id="betweenend"
                            className="form-control"
                            value={betweenend}
                            onChange={(e) => betweenfn("end", e.target.value)}
                            placeholder="start"
                            type="date"
                        ></input>
                    </div> */}
          {/*  sort */}
          <div className="m-1 ms-auto">
            <div className="input-group ">
              <span
                className="input-group-text bg-white border-end-0"
                id="basic-addon1"
              >
                Sort :{" "}
              </span>
              <select
                value={sort}
                onChange={(e) => {
                  setSort(e.target.value);
                }}
                className="form-select border-start-0"
                aria-label="emp_status"
              >
                <option value="" disabled>
                  Select
                </option>
                <option value="emp_fullname">Fullname</option>
                <option value="emp_username">Username</option>
                <option value="">Clear</option>
              </select>
            </div>
          </div>
        </div>
        {/* table section*/}
        <div className="table-responsive  border-0 mb-2">
          {/*  all */}
          <table className="table table-hover ">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Fullname</th>
                <th scope="col">username</th>
                <th scope="col">status</th>
                <th scope="col">Role</th>
                <th style={{ width: 100 }} scope="col">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {/* table data */}
              {employees ? (
                <>
                  {employees.map((data, index) => (
                    <tr key={index}>
                      <td>
                        <div>
                          <img
                            style={{
                              width: 40,
                              height: 40,
                              borderRadius: "50%",
                            }}
                            src={ data.emp_pic ? serverURL + "/" + data.emp_pic : imagename}
                          />
                        </div>
                      </td>
                      <td>{data.emp_fullname}</td>
                      <td>{data.emp_username}</td>
                      <td>{data.emp_status}</td>
                      <td>{data.emp_role}</td>
                      <td className="p-1 m-0">
                        <Link
                          className="btn  me-2"
                          to={"employeeevent?emp_id=" + data.emp_id}
                        >
                          <i className="fa fa-eye" aria-hidden="true"></i>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={10} className="text-center">
                    <Alertmsg alertmsg={ismessage} />
                  </td>
                </tr>
              )}
            </tbody>
            <caption className="">
              <div className="d-flex">
                <div className="flex-fill"></div>
                <div className="p-1 "> Items per page </div>
                <div>
                  {" "}
                  <select
                    value={limit}
                    onChange={(e) => {
                      setLimit(e.target.value);
                      setPage(1);
                    }}
                    className="form-select form-select-sm"
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                  </select>
                </div>
                <div className="p-1 me-4">
                  {page * limit - limit}-{page * limit} of {resultCount}
                </div>
                <ReactPaginate
                  previousLabel="< Prev"
                  nextLabel="Next >"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  pageCount={pagecount}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={3}
                  onPageChange={paginationfn}
                  containerClassName="pagination"
                  activeClassName="actives"
                  disabledLinkClassName="text-secondary"
                  forcePage={page - 1}
                />
              </div>
            </caption>
          </table>
          <div className="text-center">
            {/*  loader and error handler */}
            {/*  {<Toastmessage ref={ref} type={error} errormsg={ismessage} />} */}
            {isLoaded ? <Loader /> : <></>}
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Employee;
