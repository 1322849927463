import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import materialService from "../services/material.service";
import { Materialvalidationschema } from "../components/Validation";
import ReactPaginate from "react-paginate";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import DropdownButton from "react-bootstrap/DropdownButton";
import { format } from "date-fns";
import Loader from "../components/Loader";
import Toastmessage from "../components/Toastmessage";
import { useNavigate } from "react-router-dom";
import Alertmsg from "../components/Alertmsg";
import categoryService from "../services/category.service";
const Material = () => {
  const ref = useRef();
  let navigate = useNavigate();
  const [iserror, setIserror] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [ismessage, setIsmessage] = useState(false);

  const [eventname, setEventname] = useState("create");
  const [maincategory, setMaincategory] = useState([]);

  const [categorys, setMaterials] = useState([]);
  const [result, setResult] = useState([]);
  //smart table data
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState("");
  const [filtercategory, setFiltercategory] = useState("");

  const [search, setSearch] = useState("");
  const [between, setBetween] = useState("");
  const [sort, setSort] = useState("");
  const [group, setGroup] = useState("");

  const [pagecount, setPagecount] = useState("");
  const [resultCount, setResultcount] = useState("");

  //filter arrays
  const statusfilterarr = [
    { status_id: "01", status_name: "pending" },
    { status_id: "02", status_name: "active" },
    { status_id: "03", status_name: "inactive" },
  ];
  const [filterarray, setFilterarray] = useState(statusfilterarr);
  //date range
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };
  const handleSelectdate = async (date) => {
    await setStartDate(date.selection.startDate);
    await setEndDate(date.selection.endDate);
    setBetween(
      format(date.selection.startDate, "yyyy-MM-dd") +
        " , " +
        format(date.selection.endDate, "yyyy-MM-dd")
    );
  };
  const resetdaterange = () => {
    setBetween("");
    setStartDate(new Date());
    setEndDate(new Date());
  };
  //form initial value
  const formik = useFormik({
    initialValues: {
      mat_id: "",
      mat_name: "",
      mat_price: "",
      mat_category: "",
      mat_status: "active",
    },
    validationSchema: Materialvalidationschema,
    /*  validateOnChange: false,
         validateOnBlur: false, */
    onSubmit: (values, { resetForm }) => {
      //alert(JSON.stringify(values, null, 1));
      resetForm();
    },
  });
  //use effects
  useEffect(() => {
    getallMaterial();
    getallCategory();
  }, []);
  useLayoutEffect(() => {
    getallMaterial();
  }, [page, limit, filter, filtercategory, search, between, sort]);

  //current event
  const categoryEvent = (eventname, value) => {
    if (value != "") {
      getMaterial(value);
      setEventname(eventname);
    }
    setEventname(eventname);
  };
  //main category
  //get allcategory
  const getallCategory = async () => {
    var data = {
      limit: limit,
      page: page,
      filter: "",
      filtercategory: "main",
      search: search,
      between: between,
      sort: sort,
      group: group,
    };
    return await categoryService
      .getallCategory(data)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          setMaincategory(res.data.result.data);
        } else {
          setMaincategory([]);
        }
      })
      .catch((err) => {
        setMaincategory([]);
      });
  };
  //get allcategory
  const getallMaterial = async () => {
    setIsLoaded(true);
    var data = {
      limit: limit,
      page: page,
      filter: filter,
      filtercategory: filtercategory,
      search: search,
      between: between,
      sort: sort,
      group: group,
    };
    return await materialService
      .getallMaterial(data)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          setIsLoaded(false);
          // setIserror(res.data.status);
          //setIsmessage(res.data.message);
          setMaterials(res.data.result.data);
          setResult(res.data);
          setPage(res.data.result.page);
          setLimit(res.data.result.limit);
          setPagecount(res.data.result.pagecount);
          setResultcount(res.data.result.resultcount);
          console.log(res.data);
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
      });
  };
  //pagination
  const paginationfn = (event) => {
    setPage(event.selected + 1);
    console.log(event.selected + 1);
  };
  //search
  const searchfn = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };
  //filter
  const [filterArr, setfilterArr] = useState([]);
  const filterfn = (value) => {
    setPage(1);
    var index = filterArr.indexOf(value);
    if (index > -1) {
      filterArr.splice(index, 1);
      setFilter(filterArr.toString());
    } else {
      filterArr.push(value);
      setFilter(filterArr.toString());
    }
  };
  //filter category
  const [filtercategoryArr, setfiltercategoryArr] = useState([]);
  const filtercategoryfn = (value) => {
    setPage(1);
    var index = filtercategoryArr.indexOf(value);
    if (index > -1) {
      filtercategoryArr.splice(index, 1);
      setFiltercategory(filtercategoryArr.toString());
    } else {
      filtercategoryArr.push(value);
      setFiltercategory(filtercategoryArr.toString());
    }
  };
  //sort
  const sortfn = (value) => {
    /*    setSort(value); */
    if (sort == value + " desc") {
      setSort(value + " asc");
      console.log(value + " asc");
    } else if (sort == value + " asc") {
      setSort("");
      console.log(value + "");
    } else {
      setSort(value + " desc");
      console.log(value + " desc");
    }
  };
  //between
  const [betweenstart, setBetweenstart] = useState("");
  const [betweenend, setBetweenend] = useState("");
  const betweenfn = (value, e) => {
    setPage(1);
    if (e === null || e === "") {
      setBetween("");
      setBetweenstart("");
      setBetweenend("");
    } else {
      if (value == "start") {
        setBetween(e + "," + e);
        console.log(e + "," + e);
        setBetweenstart(e);
        setBetweenend(e);
      } else if (value == "end") {
        setBetweenend(e);
        const dd = between.split(",");
        setBetween(dd[0] + "," + e);
        console.log(dd[0] + "," + e);
        setBetweenstart(dd[0]);
        setBetweenend(e);
      } else {
        setBetween("");
        setBetweenstart("");
        setBetweenend("");
      }
    }
  };
  //get category
  const getMaterial = (value) => {
    setIserror(null);
    setIsmessage(null);
    setIsLoaded(true);
    materialService
      .getMaterial(value)
      .then((res) => {
        if (res.data.result) {
          console.log(res.data);
          setIsLoaded(false);
          let data = res.data.result;
          formik.setValues({
            mat_id: data.mat_id,
            mat_name: data.mat_name,
            mat_price: data.mat_price,
            mat_category: data.mat_category,
            mat_status: data.mat_status,
          });
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
      });
  };
  //create category
  const createMaterial = () => {
    setIserror(null);
    setIsmessage(null);
    setIsLoaded(true);
    materialService
      .createMaterial(formik.values)
      .then((res) => {
        if (res.data.status) {
          console.log(res.data);
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          getallMaterial();
          ref.current.log();
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
          ref.current.log();
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
        ref.current.log();
      });
  };

  //update category
  const updateMaterial = (value) => {
    console.log(JSON.stringify(formik.values, null, 2));
    materialService
      .updateMaterial(formik.values)
      .then((res) => {
        if (res.data.status) {
          console.log(res.data);
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          ref.current.log();
          getallMaterial();
          setEventname("create");
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
          ref.current.log();
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
        ref.current.log();
      });
  };
  //delete category
  const deleteMaterial = (value) => {
    console.log(value);
    materialService
      .deleteMaterial(String(value))
      .then((res) => {
        if (res.data.status) {
          console.log("succes");
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          getallMaterial();
          setEventname("create");
          ref.current.log();
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
          ref.current.log();
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
        ref.current.log();
      });
  };
  // Call category clicks to request another page.
  if (ismessage == "Network Error") {
    return <Alertmsg alertmsg={ismessage} type={iserror} />;
  }
  return (
    <div className="">
      <div className="">
        {/*  loader and error handler */}
        {<Toastmessage ref={ref} type={iserror} errormsg={ismessage} />}
        {isLoaded ? <Loader /> : <></>}
      </div>
      {/*  container */}
      <div className="category-container">
        {/* category form */}
        <div className="card  border shadow-sm mb-2">
          <div className="card-body ">
            <div className="d-flex">
            <h3 className="card-title p-0 m-0 me-auto">Material Event</h3>

              {eventname == "create" ? (
                <></>
              ) : (
                <div className="d-none">
                  <div className="dropdown">
                    <a
                      className="btn  btn-link"
                      href="#"
                      role="button"
                      id="categoryeventdropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="categoryeventdropdownMenuLink"
                    >
                      <li>
                        <a
                          onClick={() => {
                            categoryEvent("edit", "");
                          }}
                          className="dropdown-item"
                        >
                          Edit
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => categoryEvent("delete", "")}
                          className="dropdown-item"
                        >
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-6 ">
                  <label htmlFor="mat_name" className="form-label">
                    Material name
                  </label>
                  <div className="input-group">
                    <input
                      className="form-control"
                      placeholder="Enter Material name"
                      id="mat_name"
                      type="text"
                      disabled={
                        eventname == "view"
                          ? true
                          : eventname == "delete"
                          ? true
                          : false
                      }
                      {...formik.getFieldProps("mat_name")}
                    />
                  </div>
                  <div>
                    {formik.touched.mat_name && formik.errors.mat_name ? (
                      <div className="error">{formik.errors.mat_name}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-2 ">
                  <label htmlFor="mat_price" className="form-label">
                    price
                  </label>
                  <div className="input-group">
                    <input
                      className="form-control"
                      placeholder="Enter price"
                      id="mat_price"
                      type="number"
                      disabled={
                        eventname == "view"
                          ? true
                          : eventname == "delete"
                          ? true
                          : false
                      }
                      {...formik.getFieldProps("mat_price")}
                    />
                  </div>
                  <div>
                    {formik.touched.mat_price && formik.errors.mat_price ? (
                      <div className="error">{formik.errors.mat_price}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-2">
                  <label htmlFor="mat_category" className="form-label">
                    Category
                  </label>
                  <div className="input-group col-md-6">
                    <select
                      id="mat_category"
                      {...formik.getFieldProps("mat_category")}
                      className="form-select"
                      disabled={
                        eventname == "view"
                          ? true
                          : eventname == "delete"
                          ? true
                          : false
                      }
                      aria-label="mat_category"
                    >
                      <option value="" disabled>
                        Select category
                      </option>
                      {maincategory.map((data, index) => (
                        <option key={index} value={data.category_name}>
                          {data.category_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    {formik.touched.mat_category &&
                    formik.errors.mat_category ? (
                      <div className="error">{formik.errors.mat_category}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-2">
                  <label htmlFor="mat_status" className="form-label">
                    status
                  </label>
                  <div className="input-group col-md-6">
                    <select
                      id="mat_status"
                      {...formik.getFieldProps("mat_status")}
                      className="form-select"
                      disabled={
                        eventname == "view"
                          ? true
                          : eventname == "delete"
                          ? true
                          : false
                      }
                      aria-label="mat_status"
                    >
                      <option value="" disabled>
                        Select status
                      </option>
                      <option value="pending">Pending</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                  <div>
                    {formik.touched.mat_status && formik.errors.mat_status ? (
                      <div className="error">{formik.errors.mat_status}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12 pt-2 pb-2">
                  {eventname === "create" ? (
                    <button
                      disabled={!formik.isValid}
                      onClick={() => createMaterial()}
                      className="btn btn-primary me-2"
                    >
                      Create
                    </button>
                  ) : eventname === "edit" ? (
                    <button
                      onClick={() => updateMaterial(formik.values.mat_id)}
                      className="btn btn-primary me-2"
                    >
                      Update
                    </button>
                  ) : eventname === "delete" ? (
                    <button
                      onClick={() => deleteMaterial(formik.values.mat_id)}
                      className="btn btn-danger me-2 "
                    >
                      Delete
                    </button>
                  ) : (
                    <button
                      onClick={() => categoryEvent("create", "")}
                      className="btn btn-primary"
                    >
                      Back
                    </button>
                  )}
                  <button
                    type="submit"
                    disabled={!formik.dirty}
                    onClick={() => {
                      categoryEvent("create", "");
                    }}
                    className="btn btn-secondary"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="flex-fill mb-3 mt-3">
          <h2>Material</h2>
        </div>
        <div className=" mb-2 p-1">
          {/* tebale header part search,filter,sort */}
          <div className="d-md-flex  mb-2 ">
            {/* create category */}
            {/* <div className="m-1">
              <button
                onClick={formik.handleReset}
                type="submit"
                className="btn btn-primary  w-100"
              >
                <i className="fa fa-plus-circle"></i> Create
              </button>
            </div> */}
            {/* filter status*/}
            <div className="dropdown m-1">
              <button
                className="btn btn-primary w-100 dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fa fa-filter" aria-hidden="true"></i> Category
              </button>
              <ul
                className="dropdown-menu p-2"
                aria-labelledby="dropdownMenuButton2"
              >
                {maincategory.map((maincategory, index) => (
                  <li key={index}>
                    <div className="form-check">
                      <input
                        onChange={(e) =>
                          filtercategoryfn(maincategory.category_name)
                        }
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      ></input>
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        {maincategory.category_name}
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
              {/*  category type filter */}
            </div>
            {/* filter status*/}
            <div className="dropdown m-1">
              <button
                className="btn btn-primary w-100 dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fa fa-filter" aria-hidden="true"></i> Status
              </button>
              <ul
                className="dropdown-menu p-2"
                aria-labelledby="dropdownMenuButton1"
              >
                {filterarray.map((filterdata, index) => (
                  <li key={index}>
                    <div className="form-check">
                      <input
                        onChange={(e) => filterfn(filterdata.status_name)}
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      ></input>
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        {filterdata.status_name}
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
              {/*  category type filter */}
            </div>

            {/* search */}
            <div className=" m-1">
              <input
                className="form-control"
                onChange={searchfn}
                placeholder="search"
                type="search"
              ></input>
            </div>
            {/*  date range  */}
            {/* <div className="m-1">
              <div className="">
                <button
                  className="btn bg-white dropdown-toggle"
                  id="dropdownMenuClickableInside"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  <i className="fa fa-calendar" aria-hidden="true"></i>{" "}
                  {between}
                  {between ? (
                    <a
                      className="ms-2 me-2 p-1 text-secondary"
                      onClick={resetdaterange}
                    >
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </a>
                  ) : (
                    <></>
                  )}
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuClickableInside"
                >
                  <DateRangePicker  
                    ranges={[selectionRange]}
                    onChange={(date) => handleSelectdate(date)}
                  />
                </ul>
              </div>
            </div> */}
            {/* between */}
            {/*  <div className="m-1">
                            <input
                                id="betweenstart"
                                className="form-control"
                                value={betweenstart}
                                onChange={(e) => betweenfn("start", e.target.value ? e.target.value : '')}
                                placeholder="start"
                                type="date"
                            ></input>
                        </div>
                        <div className="m-1">
                            <input
                                id="betweenend"
                                className="form-control"
                                value={betweenend}
                                onChange={(e) => betweenfn("end", e.target.value)}
                                placeholder="start"
                                type="date"
                            ></input>
                        </div> */}
            {/*  sort */}
            <div className="m-1 ms-auto">
              <div className="input-group ">
                <span
                  className="input-group-text bg-white border-end-0"
                  id="basic-addon1"
                >
                  Sort :{" "}
                </span>
                <select
                  value={sort}
                  onChange={(e) => {
                    setSort(e.target.value);
                  }}
                  className="form-select border-start-0"
                  aria-label="mat_status"
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option value="mat_name">Material name</option>
                  <option value="mat_category">Material category</option>
                  <option value="">Clear</option>
                </select>
              </div>
            </div>
          </div>
          {/* table section*/}
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Category</th>
                  <th scope="col">Material name</th>
                  <th scope="col">Price</th>
                  <th scope="col">Status</th>
                  <th scope="col">Created</th>

                  <th style={{ width: 200 }} scope="col">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* table data */}
                {categorys ? (
                  <>
                    {categorys.map((data, index) => (
                      <tr key={index}>
                        <td>{data.mat_id}</td>
                        <td>{data.mat_category}</td>
                        <td>{data.mat_name}</td>
                        <td>{data.mat_price}</td>
                        <td>{data.mat_status}</td>
                        <td>
                        {format(new Date(data.createdAt), "PPpp")}
                         </td>

                        <td className="p-1 m-0">
                          <button
                            onClick={() => categoryEvent("view", data.mat_id)}
                            className="btn  me-2 "
                          >
                            <i className="fa fa-eye" aria-hidden="true"></i>
                          </button>
                          <button
                            onClick={() => {
                              categoryEvent("edit", data.mat_id);
                            }}
                            className="btn  me-2 "
                          >
                            <i
                              className="fa fa-pencil-square"
                              aria-hidden="true"
                            ></i>
                          </button>
                          <button
                            onClick={() => categoryEvent("delete", data.mat_id)}
                            className="btn  me-2 "
                          >
                            <i className="fa fa-trash-o" aria-hidden="true"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center">
                      0 data found
                    </td>
                  </tr>
                )}
              </tbody>
              <caption className="">
                <div className="d-flex">
                  <div className="flex-fill"></div>
                  <div className="p-1 "> Items per page </div>
                  <div>
                    {" "}
                    <select
                      value={limit}
                      onChange={(e) => {
                        setLimit(e.target.value);
                        setPage(1);
                      }}
                      className="form-select form-select-sm"
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                    </select>
                  </div>
                  <div className="p-1 me-4">
                    {page * limit - limit}-{page * limit} of {resultCount}
                  </div>
                  <ReactPaginate
                    previousLabel="< Prev"
                    nextLabel="Next >"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    pageCount={pagecount}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={3}
                    onPageChange={paginationfn}
                    containerClassName="pagination"
                    activeClassName="actives"
                    disabledLinkClassName="text-secondary"
                    forcePage={page - 1}
                  />
                </div>
              </caption>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Material;
