import React, { useEffect, useLayoutEffect, useState } from "react";
import { useFormik } from "formik";
import inventoryService from "../services/inventory.service";
import { Inventoryvalidationschema } from "../components/Validation";
import ReactPaginate from "react-paginate";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";
import Loader from "../components/Loader";
import Toastmessage from "../components/Toastmessage";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Alertmsg from "../components/Alertmsg";
import imagename from "../assets/images/user.png";
import categoryService from "../services/category.service";

const Inventory = ({ navigation }) => {
  let navigate = useNavigate();
  const [iserror, setIserror] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [ismessage, setIsmessage] = useState(false);

  const [inventorys, setInventorys] = useState([]);
  const [result, setResult] = useState([]);
  //smart table data
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState("");
  const [filterreceipttype, setFilterreceipttype] = useState("");
  const [search, setSearch] = useState("");
  const [between, setBetween] = useState("");
  const [sort, setSort] = useState("");
  const [group, setGroup] = useState("");

  const [pagecount, setPagecount] = useState("");
  const [resultCount, setResultcount] = useState("");
  const [categorylist, setCategorylist] = useState([]);

  //filter arrays
  const statusfilterarr = [
    { status_label: "inventory in", status_name: "inventoryin" },
    { status_label: "inventory out", status_name: "inventoryout" },
  ];
  const [statusfilterarray, setStatusfilterarray] = useState(statusfilterarr);
  //date range
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };
  const handleSelectdate = async (date) => {
    await setStartDate(date.selection.startDate);
    await setEndDate(date.selection.endDate);
    setBetween(
      format(date.selection.startDate, "yyyy-MM-dd") +
        " , " +
        format(date.selection.endDate, "yyyy-MM-dd")
    );
  };
  const resetdaterange = () => {
    setBetween("");
    setStartDate(new Date());
    setEndDate(new Date());
  };

  //use effects
  useEffect(() => {
    getallInventory();
    getallapptcategory();
  }, []);
  useLayoutEffect(() => {
    getallInventory();
  }, [page, limit, filter, search, between, sort]);

  //get allinventory
  const getallInventory = async () => {
    setIserror(null);
    setIsmessage(null);
    setIsLoaded(true);
    var data = {
      limit: limit,
      page: page,
      filter: filter,
      filterreceipttype: filterreceipttype,
      search: search,
      between: between,
      sort: sort,
      group: group,
    };
    return await inventoryService
      .getallInventory(data)
      .then((res) => {
        console.log(res.data);

        if (res.data.result) {
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);

          setInventorys(res.data.result.data);
          setResult(res.data);
          setPage(res.data.result.page);
          setLimit(res.data.result.limit);
          setPagecount(res.data.result.pagecount);
          setResultcount(res.data.result.resultcount);
        } else {
          setIserror(false);
          setIsmessage(res.data.message);
          setIsLoaded(false);
        }
      })
      .catch((err) => {
        setIserror(false);
        setIsmessage(err.message);
        setIsLoaded(false);
      });
  };
  //pagination
  const paginationfn = (event) => {
    setPage(event.selected + 1);
    console.log(event.selected + 1);
  };
  //search
  const searchfn = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };
  //filter
  const [filterArr, setfilterArr] = useState([]);
  const filterfn = (value) => {
    setPage(1);
    var index = filterArr.indexOf(value);
    if (index > -1) {
      filterArr.splice(index, 1);
      setFilter(filterArr.toString());
    } else {
      filterArr.push(value);
      setFilter(filterArr.toString());
    }
  };
  //sort
  const sortfn = (value) => {
    /*    setSort(value); */
    if (sort === value + " desc") {
      setSort(value + " asc");
      console.log(value + " asc");
    } else if (sort === value + " asc") {
      setSort("");
      console.log(value + "");
    } else {
      setSort(value + " desc");
      console.log(value + " desc");
    }
  };
  //between
  const [betweenstart, setBetweenstart] = useState("");
  const [betweenend, setBetweenend] = useState("");
  const betweenfn = (value, e) => {
    setPage(1);
    if (e === null || e === "") {
      setBetween("");
      setBetweenstart("");
      setBetweenend("");
    } else {
      if (value === "start") {
        setBetween(e + "," + e);
        console.log(e + "," + e);
        setBetweenstart(e);
        setBetweenend(e);
      } else if (value === "end") {
        setBetweenend(e);
        const dd = between.split(",");
        setBetween(dd[0] + "," + e);
        console.log(dd[0] + "," + e);
        setBetweenstart(dd[0]);
        setBetweenend(e);
      } else {
        setBetween("");
        setBetweenstart("");
        setBetweenend("");
      }
    }
  };

  //category  list
  const getallapptcategory = () => {
    var data = {
      limit: 10,
      page: 1,
      filter: "",
      filtercategory: "main",
      search: "",
      between: "",
      sort: "",
      group: "",
    };
    categoryService
      .getallCategory(data)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          setCategorylist(res.data.result.data);
        } else {
          setCategorylist([]);
        }
      })
      .catch((err) => {
        setCategorylist([]);
      });
  };
  // Call inventory clicks to request another page.
  if (ismessage == "Network Error") {
    return <Alertmsg alertmsg={ismessage} type={iserror} />;
  }
  /* if (isLoaded == true) {
    return <Loader />
  } */
  const selectedcolor = (value) => {
    if (value > 10) {
      return "badge rounded-pill bg-success";
    } else if (value > 3) {
      return "badge rounded-pill bg-warning ";
    } else if (value > 0) {
      return "badge rounded-pill bg-danger";
    } else {
      return "badge rounded-pill bg-dark";
    }
  };
  return (
    <div className="">
      <div className="flex-fill mb-3">
        <h2>Inventory</h2>
      </div>
      {/*  container */}
      <div className="inventory-container">
        {/* tebale header part search,filter,sort */}
        <div className="d-md-flex  mb-2 ">
          {/* create inventory */}
          <div className="m-1">
            <button
              onClick={() => {
                navigate("inventorybooking");
              }}
              type="submit"
              className="btn btn-primary  w-100"
            >
              <i className="fa fa-plus-circle"></i> Create
            </button>
          </div>
          {/* filter status*/}
          <div className="dropdown m-1">
            <button
              className="btn btn-primary w-100 dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fa fa-filter" aria-hidden="true"></i> Filter
            </button>
            <ul
              className="dropdown-menu p-2"
              aria-labelledby="dropdownMenuButton1"
            >
              <li className="text-secondary pb-1 pt-1">Type</li>
              {statusfilterarr.map((statusfilterarr, index) => (
                <li key={index}>
                  <div className="form-check">
                    <input
                      onChange={(e) => filterfn(statusfilterarr.status_name)}
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    ></input>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      {statusfilterarr.status_label}
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          {/* search */}
          <div className=" m-1">
            <input
              className="form-control"
              onChange={searchfn}
              placeholder="search"
              type="search"
            ></input>
          </div>

          {/*  sort */}
          <div className="m-1 ms-auto">
            {/*  date range  */}
            <div className="m-1">
              <div className="">
                <button
                  className="btn bg-white dropdown-toggle"
                  id="dropdownMenuClickableInside"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  <i className="fa fa-calendar" aria-hidden="true"></i>{" "}
                  {between}
                  {between ? (
                    <a
                      className="ms-2 me-2 p-1 text-secondary"
                      onClick={resetdaterange}
                    >
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </a>
                  ) : (
                    <></>
                  )}
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuClickableInside"
                >
                  <DateRangePicker
                    ranges={[selectionRange]}
                    onChange={(date) => handleSelectdate(date)}
                  />
                </ul>
              </div>
            </div>
            <div className="input-group d-none">
              <span
                className="input-group-text bg-white border-end-0"
                id="basic-addon1"
              >
                Sort :{" "}
              </span>
              <select
                value={sort}
                onChange={(e) => {
                  setSort(e.target.value);
                }}
                className="form-select border-start-0"
                aria-label="emp_status"
              >
                <option value="" disabled>
                  Select
                </option>
                <option value="appt_date">Inventory date</option>
                <option value="appt_category">Category</option>
                <option value="">Clear</option>
              </select>
            </div>
          </div>
        </div>
        {/* table section*/}
        <div className="table-responsive  border-0 mb-2">
          {/*  all */}
          <table className="table table-hover">
            <thead>
              <tr>
                <th></th>
                <th scope="col">Team</th>
                <th scope="col">Receipt type</th>
                <th scope="col">material sender</th>
                <th scope="col">material receiver</th>
                <th scope="col">receipt date</th>
                <th scope="col">status</th>
                <th scope="col">Created</th>
                <th style={{ width: 100 }} scope="col">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {/* table data */}
              {inventorys ? (
                <>
                  {inventorys.map((data, index) => (
                    <tr key={index}>
                      <td>
                        <div>
                          <img
                            style={{
                              width: 40,
                              height: 40,
                              borderRadius: "50%",
                            }}
                            src={imagename ? imagename : imagename}
                          />
                        </div>
                      </td>
                      <td>{data.team_name}</td>
                      <td>{data.receipt_type}</td>
                      <td>{data.mat_sender}</td>
                      <td>{data.mat_receiver}</td>
                      <td>{data.receipt_date}</td>
                      {/* <td>
                        {format(new Date(data.receipt_date), "dd-MM-yyyy")}
                      </td> */}
                      <td>{data.receipt_status}</td>
                      <td>{format(new Date(data.createdAt), "PP")}</td>
                      <td className="p-1 m-0">
                        <Link
                          className="btn  me-2"
                          to={"inventorybooking?receipt_id=" + data.receipt_id}
                        >
                          <i className="fa fa-eye" aria-hidden="true"></i>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr className="bg-light">
                  <td colSpan={13} className="text-center">
                    <Alertmsg alertmsg={ismessage} />
                  </td>
                </tr>
              )}
            </tbody>
            <caption className="">
              <div className="d-flex">
                <div className="flex-fill"></div>
                <div className="p-1 "> Items per page </div>
                <div>
                  {" "}
                  <select
                    value={limit}
                    onChange={(e) => {
                      setLimit(e.target.value);
                      setPage(1);
                    }}
                    className="form-select form-select-sm"
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                  </select>
                </div>
                <div className="p-1 me-4">
                  {page * limit - limit}-{page * limit} of {resultCount}
                </div>
                <ReactPaginate
                  previousLabel="< Prev"
                  nextLabel="Next >"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  pageCount={pagecount}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={3}
                  onPageChange={paginationfn}
                  containerClassName="pagination"
                  activeClassName="actives"
                  disabledLinkClassName="text-secondary"
                  forcePage={page - 1}
                />
              </div>
            </caption>
          </table>
          <div className="text-center">
            {/*  loader and error handler */}
            {/*  {<Toastmessage ref={ref} type={error} errormsg={ismessage} />} */}
            {isLoaded ? <Loader /> : <></>}
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Inventory;
