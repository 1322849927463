import React, { useEffect, useLayoutEffect, useState, useRef } from 'react'
import { useFormik } from 'formik';
import userService from '../services/user.service';
import { Uservalidationschema } from '../components/Validation';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Loader from '../components/Loader';
import Toastmessage from '../components/Toastmessage';
import { useNavigate,useSearchParams } from 'react-router-dom';
import Alertmsg from '../components/Alertmsg';

const Usersmartevent = (props) => {
    const ref = useRef();
    let navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();

    const [user_id, setUserid] = useState();

    const [iserror, setIserror] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [ismessage, setIsmessage] = useState(false);
    const [eventname, setEventname] = useState('view')

    //form initial value
    const formik = useFormik({
        initialValues: {
            user_id: '',
            user_firstname: '',
            user_lastname: '',
            user_email: '',
            user_status: '',
        },
        validationSchema: Uservalidationschema,
        /*  validateOnChange: false,
         validateOnBlur: false, */
        onSubmit: (values, { resetForm }) => {
            //alert(JSON.stringify(values, null, 1));
            /*  resetForm({ values: '' }) */
        }
    });
    //use effects
    useEffect(() => {
        setUserid(searchParams.get('user_id'))
        if (searchParams.get('user_id')) {
            setEventname('view')
            getUser(searchParams.get('user_id'))
        } else {
            setEventname('create')
        }
    }, []);

    //current event
    const userEvent = (eventname, value) => {
        if (value) {
            getUser(value);
        }
        setEventname(eventname)
    }

    //get user
    const getUser = (value) => {
        setIserror(null)
        setIsmessage(null)
        setIsLoaded(true);
        userService.getUser(value).
            then(
                (res) => {
                    if (res.data.result) {
                        console.log(res.data);
                        setIsLoaded(false);
                        let data = res.data.result;
                        formik.setValues({
                            user_id: data.user_id,
                            user_firstname: data.user_firstname,
                            user_lastname: data.user_lastname,
                            user_status: data.user_status,
                            user_email: data.user_email == null ? '' : data.user_email,
                        });
                    } else {
                        setIserror(res.data.status);
                        setIsmessage(res.data.message);
                        setIsLoaded(false);
                    }
                }

            ).catch((err) => {
                setIserror(false);
                setIsmessage(err.message);
                setIsLoaded(false);
            });
    }
    //create user
    const createUser = () => {
        setIserror(null)
        setIsmessage(null)
        setIsLoaded(true);
        userService.createUser(formik.values).
            then(
                (res) => {
                    if (res.data.status) {
                        console.log(res.data);
                        setIsLoaded(false);
                        setIserror(res.data.status);
                        setIsmessage(res.data.message);
                        navigate('/usersmarttable');
                        ref.current.log()
                    } else {
                        setIserror(res.data.status);
                        setIsmessage(res.data.message);
                        setIsLoaded(false);
                        ref.current.log()
                    }
                }
            ).catch((err) => {
                setIserror(false);
                setIsmessage(err.message);
                setIsLoaded(false);
                ref.current.log()
            });
    }

    //update user
    const updateUser = () => {
        console.log(JSON.stringify(formik.values, null, 2));
        userService.updateUser(formik.values).
            then(
                (res) => {
                    if (res.data.result) {
                        console.log(res.data);
                        setIsLoaded(false);
                        setIserror(res.data.status);
                        setIsmessage(res.data.message);
                        userEvent('view');
                        ref.current.log()
                    } else {
                        setIserror(res.data.status);
                        setIsmessage(res.data.message);
                        setIsLoaded(false);
                        ref.current.log()
                    }
                }

            ).catch((err) => {
                setIserror(false);
                setIsmessage(err.message);
                setIsLoaded(false);
                ref.current.log()
            });
    }
    //delete user
    const deleteUser = (value) => {
        console.log(value);
        userService.deleteUser(String(value)).
            then(
                (res) => {
                    if (res.data.status) {
                        console.log('succes');
                        navigate('/usersmarttable')
                        setIsLoaded(false);
                        setIserror(res.data.status);
                        setIsmessage(res.data.message);
                        /*  ref.current.log() */
                    } else {
                        setIserror(res.data.status);
                        setIsmessage(res.data.message);
                        setIsLoaded(false);
                        ref.current.log();
                    }
                }

            ).catch((err) => {
                setIserror(false);
                setIsmessage(err.message);
                setIsLoaded(false);
                ref.current.log()
            });
    }
    // Call user clicks to request another page.  
    if (ismessage == 'Network Error') {
        return <Alertmsg alertmsg={ismessage} type={iserror} />
    }
    return (
        <div className="">
            <div className='text-center'>
                {/*  loader and error handler */}
                {/*  {<Toastmessage ref={ref} type={error} errormsg={ismessage} />} */}
                {isLoaded ? <Loader /> : <></>}
            </div>
            {/*  container */}
            <div className="user-container">
                {/* form container */}
                <div className='card bg-transparent mb-2'>
                    <div className='card-body '>

                        <div className='d-flex p-2 '>
                            <h5 className="card-title p-0 m-0 me-auto">Users</h5>
                            {eventname == 'create' ? <></> :
                                <div>
                                    <div className="dropdown">
                                        <a className="btn  btn-link" href="#" role="button" id="usereventdropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                        </a>
                                        <ul className="dropdown-menu" aria-labelledby="usereventdropdownMenuLink">
                                            <li><a onClick={() => { userEvent('edit') }} className="dropdown-item" >Edit</a></li>
                                            <li><a onClick={() => userEvent('delete')}
                                                className="dropdown-item" >Delete</a></li>
                                        </ul>
                                    </div>
                                </div>}
                        </div>
                        <form onSubmit={formik.handleSubmit}  >
                            <div className='row'>
                                <div className='col-md-6 '>
                                    <label htmlFor="user_firstname" className="form-label">Firstname</label>
                                    <div className='input-group'>
                                        <input
                                            className='form-control'
                                            placeholder='Enter Firstname'
                                            id="user_firstname"
                                            type="text"
                                            disabled={eventname == 'view' ? true : eventname == 'delete' ? true : false}
                                            {...formik.getFieldProps('user_firstname')}
                                        />
                                    </div>
                                    <div>
                                        {formik.touched.user_firstname && formik.errors.user_firstname ? (
                                            <div className='error'>{formik.errors.user_firstname}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <label htmlFor="user_lastname" className="form-label">Lastname</label>
                                    <div className='input-group col-md-6'>
                                        <input
                                            className='form-control'
                                            placeholder='Enter Lastname'
                                            id="user_lastname"
                                            type="text"
                                            disabled={eventname == 'view' ? true : eventname == 'delete' ? true : false}
                                            {...formik.getFieldProps('user_lastname')}
                                        />
                                    </div>
                                    <div>
                                        {formik.touched.user_lastname && formik.errors.user_lastname ? (
                                            <div className='error'>{formik.errors.user_lastname}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <label htmlFor="user_email" className="form-label ">Email</label>
                                    <div className='input-group col-md-6'>
                                        <input
                                            className='form-control'
                                            placeholder='Enter Email'
                                            id="user_email"
                                            type="text"
                                            disabled={eventname == 'view' ? true : eventname == 'delete' ? true : false}
                                            {...formik.getFieldProps('user_email')}
                                        />
                                    </div>
                                    <div>{formik.touched.user_email && formik.errors.user_email ? (
                                        <div className='error'>{formik.errors.user_email}</div>
                                    ) : null}</div>
                                </div>
                                <div className='col-md-12'>
                                    <label htmlFor="user_status" className="form-label">status</label>
                                    <div className='input-group col-md-6'>
                                        <select id="user_status"  {...formik.getFieldProps('user_status')} className="form-select" aria-label="user_status" disabled={eventname == 'view' ? true : eventname == 'delete' ? true : false}>
                                            <option value='' disabled>Select status</option>
                                            <option value="pending">Pending</option>
                                            <option value="active">Active</option>
                                            <option value="inactive">Inactive</option>
                                        </select>
                                    </div>
                                    <div>
                                        {formik.touched.user_status && formik.errors.user_status ? (
                                            <div className='error'>{formik.errors.user_status}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='col-md-12 pt-2 pb-2'>
                                    {
                                        eventname === 'create' ? <><button disabled={!formik.isValid} onClick={() => createUser()} className='btn btn-primary me-2' >Create</button>
                                            <button onClick={formik.handleReset} className='btn btn-secondary' >Clear</button></> :
                                            eventname === 'edit' ? <> <button onClick={() => updateUser()} className='btn btn-primary me-2' >Update</button>
                                                <button onClick={() => userEvent('view')} className='btn btn-secondary' >Cancel</button></> :
                                                eventname === 'delete' ? <><button onClick={() => deleteUser(formik.values.user_id)} className='btn btn-danger me-2 '>Delete</button>
                                                    <button onClick={() => userEvent('view')} className='btn btn-secondary' >Cancel</button></> : <>
                                                    <button onClick={() => navigate('/usersmarttable')} className='btn btn-primary' >Back</button>
                                                </>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {/*  loader and error handler */}
                {/*  {iserror === false || iserror === true ? <Alertmsg alertmsg={ismessage} type={iserror} /> : <></>} */}
                {<Toastmessage ref={ref} type={iserror} errormsg={ismessage} />}
            </div>
        </div >
    );
}

export default Usersmartevent