import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import userService from "../services/user.service";
import { Usersvalidationschema } from "../components/Validation";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import Loader from "../components/Loader";
import Toastmessage from "../components/Toastmessage";
import { useNavigate, useSearchParams } from "react-router-dom";
import imagename from "../assets/images/user.png";
import { serverURL } from "../http-common";
import Reactcropper from "../components/Reactcropper";

const Userevent = (props) => {
  const [showPass, setShowPass] = useState(false);
  const ref = useRef();
  let navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const [user_id, setUserid] = useState();

  const [iserror, setIserror] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [ismessage, setIsmessage] = useState(false);
  const [eventname, setEventname] = useState("view");
  const [userpic, setUserpic] = useState();

  //form initial value
  const formik = useFormik({
    initialValues: {
      user_id: "",
      user_fullname: "",
      user_name: "",
      user_password: "",
      user_email: "",
      user_phone: "",
      user_role: "",
      user_address: "",
      user_status: "",
    },
    validationSchema: Usersvalidationschema,
    /*  validateOnChange: false,
         validateOnBlur: false, */
    onSubmit: (values, { resetForm }) => {
      //alert(JSON.stringify(values, null, 1));
      /*  resetForm({ values: '' }) */
    },
  });
  //use effects
  useEffect(() => {
    setUserid(searchParams.get("user_id"));
    if (searchParams.get("user_id")) {
      setEventname("view");
      getUser(searchParams.get("user_id"));
    } else {
      setEventname("create");
    }
  }, []);

  //current event
  const userEvent = (eventname, value) => {
    if (value) {
      getUser(value);
    }
    setEventname(eventname);
  };

  //get user
  const getUser = (value) => {
    setIserror(null);
    setIsmessage(null);
    setIsLoaded(true);
    userService
      .getUser(value)
      .then((res) => {
        if (res.data.result) {
          console.log(res.data);
          setIsLoaded(false);
          let data = res.data.result;
          setUserpic(serverURL+'/'+data.user_profile_pic);
          formik.setValues({
            user_id: data.user_id,
            user_fullname: data.user_fullname,
            user_name: data.user_name,
            user_password: data.user_password,
            user_email: data.user_email,
            user_phone: data.user_phone == null ? "" : data.user_phone,
            user_address: data.user_address == null ? "" : data.user_address,
            user_role: data.user_role == null ? "" : data.user_role,
            user_status: data.user_status == null ? "" : data.user_status,
          });
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
      });
  };
  //create user
  const createUser = () => {
    setIserror(null);
    setIsmessage(null);
    setIsLoaded(true);
    userService
      .createUser(formik.values)
      .then((res) => {
        if (res.data.status) {
          console.log(res.data);
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          navigate("/users");
          ref.current.log();
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
          ref.current.log();
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
        ref.current.log();
      });
  };

  //update user
  const updateUser = () => {
    console.log(JSON.stringify(formik.values, null, 2));
    userService
      .updateUser(formik.values)
      .then((res) => {
        if (res.data.result) {
          console.log(res.data);
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          userEvent("view");
          ref.current.log();
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
          ref.current.log();
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
        ref.current.log();
      });
  };
  //delete user
  const deleteUser = (value) => {
    console.log(value);
    userService
      .deleteUser(String(value))
      .then((res) => {
        if (res.data.status) {
          console.log("succes");
          navigate("/users");
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          /*  ref.current.log() */
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
          ref.current.log();
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
        ref.current.log();
      });
  };
  const uploadFn = (value) => {
    const formData = new FormData();
    formData.append("file", value);
    userService
      .upload(formik.values.user_id,formData)
      .then((data) => {
        console.log(data.data);
        getUser(formik.values.user_id)
      })
      .catch((err) => {
        console.log("failed:" + err);
      });
  };
  // Call user clicks to request another page.
  if (isLoaded) {
    <Loader />;
  } else {
    return (
      <div className="container">
        {/*  container */}
        <div className="user-container">
          {/* form container */}
          <div className="card shadow-sm border-0 ">
            <div className="card-body ">
              <div className="d-flex p-2 ">
                <h5 className="card-title p-0 m-0 me-auto"></h5>
                {eventname == "create" ? (
                  <></>
                ) : (
                  <div>
                    <div className="dropdown">
                      <a
                        className="btn  btn-link"
                        href="#"
                        role="button"
                        id="usereventdropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </a>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="usereventdropdownMenuLink"
                      >
                        <li>
                          <a
                            onClick={() => {
                              userEvent("edit");
                            }}
                            className="dropdown-item"
                          >
                            Edit
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => userEvent("delete")}
                            className="dropdown-item"
                          >
                            Delete
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  {/*  profile section */}
                  <div className="col-md-3 ">
                    <div
                      className="d-flex justify-content-center "
                      style={{ position: "relative" }}
                    >
                      <div>
                        <img
                          style={{
                            width: 150,
                            height: 150,
                            borderRadius: "50%",
                          }}
                          src={userpic ? userpic : imagename}
                        />
                      </div>
                      <div
                        style={{ position: "absolute", bottom: 0, right: 90 }}
                      >
                        <button
                          data-bs-toggle="modal"
                          data-bs-target="#uploadmodal"
                          className="btn btn-sm btn-secondary "
                        >
                          <i className="fa fa-pencil-square-o"></i>
                        </button>
                      </div>
                    </div>
                    <div>
                      <div className="col-md-12">
                        <label htmlFor="user_name" className="form-label">
                          Username
                        </label>
                        <div className="input-group col-md-6">
                          <input
                            className="form-control"
                            placeholder="Enter username"
                            id="user_name"
                            type="text"
                            disabled={
                              eventname == "view"
                                ? true
                                : eventname == "delete"
                                ? true
                                : false
                            }
                            {...formik.getFieldProps("user_name")}
                          />
                        </div>
                        <div>
                          {formik.touched.user_name &&
                          formik.errors.user_name ? (
                            <div className="error">
                              {formik.errors.user_name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label
                          className="form-label me-4"
                          htmlFor="user_password"
                        >
                          Password
                        </label>
                        <div className="input-group ">
                          <input
                            className="form-control"
                            id="user_password"
                            type={showPass ? "text" : "password"}
                            autoComplete="off"
                            disabled={
                              eventname == "view"
                                ? true
                                : eventname == "delete"
                                ? true
                                : false
                            }
                            {...formik.getFieldProps("user_password")}
                          />
                          <span
                            onClick={() => setShowPass(!showPass)}
                            className="input-group-text bg-white"
                          >
                            <i
                              className={
                                showPass ? "fa fa-eye-slash" : "fa fa-eye"
                              }
                            ></i>{" "}
                          </span>
                        </div>
                        {formik.touched.user_password &&
                        formik.errors.user_password ? (
                          <div className="error">
                            {formik.errors.user_password}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="user_email" className="form-label">
                          Email
                        </label>
                        <div className="input-group col-md-6">
                          <input
                            className="form-control"
                            placeholder="Enter Email"
                            id="user_email"
                            type="text"
                            disabled={
                              eventname == "view"
                                ? true
                                : eventname == "delete"
                                ? true
                                : false
                            }
                            {...formik.getFieldProps("user_email")}
                          />
                        </div>
                        <div>
                          {formik.touched.user_email &&
                          formik.errors.user_email ? (
                            <div className="error">
                              {formik.errors.user_email}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* form section */}
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-12 ">
                        <label htmlFor="user_fullname" className="form-label">
                          Fullname
                        </label>
                        <div className="input-group">
                          <input
                            className="form-control"
                            placeholder="Enter Fullname"
                            id="user_fullname"
                            type="text"
                            disabled={
                              eventname == "view"
                                ? true
                                : eventname == "delete"
                                ? true
                                : false
                            }
                            {...formik.getFieldProps("user_fullname")}
                          />
                        </div>
                        <div>
                          {formik.touched.user_fullname &&
                          formik.errors.user_fullname ? (
                            <div className="error">
                              {formik.errors.user_fullname}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="user_phone" className="form-label">
                          Phone
                        </label>
                        <div className="input-group col-md-6">
                          <input
                            className="form-control"
                            placeholder="Enter Phone"
                            id="user_phone"
                            type="text"
                            disabled={
                              eventname == "view"
                                ? true
                                : eventname == "delete"
                                ? true
                                : false
                            }
                            {...formik.getFieldProps("user_phone")}
                          />
                        </div>
                        <div>
                          {formik.touched.user_phone &&
                          formik.errors.user_phone ? (
                            <div className="error">
                              {formik.errors.user_phone}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-12 ">
                        <label htmlFor="user_address" className="form-label">
                          Address
                        </label>
                        <div className="input-group">
                          <textarea
                            className="form-control"
                            placeholder="Enter Address"
                            id="user_address"
                            type="text"
                            disabled={
                              eventname == "view"
                                ? true
                                : eventname == "delete"
                                ? true
                                : false
                            }
                            {...formik.getFieldProps("user_address")}
                          ></textarea>
                        </div>
                        <div>
                          {formik.touched.user_address &&
                          formik.errors.user_address ? (
                            <div className="error">
                              {formik.errors.user_address}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="user_role" className="form-label">
                          Role
                        </label>
                        <div className="input-group col-md-6">
                          <select
                            id="user_role"
                            {...formik.getFieldProps("user_role")}
                            className="form-select"
                            disabled={
                              eventname == "view"
                                ? true
                                : eventname == "delete"
                                ? true
                                : false
                            }
                            aria-label="user_role"
                          >
                            <option value="" disabled>
                              Select Role
                            </option>
                            <option value="admin">Admin</option>
                            <option value="member">Editor</option>
                            <option value="member">Member</option>
                          </select>
                        </div>
                        <div>
                          {formik.touched.user_role &&
                          formik.errors.user_role ? (
                            <div className="error">
                              {formik.errors.user_role}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="user_status" className="form-label">
                          status
                        </label>
                        <div className="input-group col-md-6">
                          <select
                            id="user_status"
                            {...formik.getFieldProps("user_status")}
                            className="form-select"
                            disabled={
                              eventname == "view"
                                ? true
                                : eventname == "delete"
                                ? true
                                : false
                            }
                            aria-label="user_status"
                          >
                            <option value="" disabled>
                              Select status
                            </option>
                            <option value="pending">Pending</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </select>
                        </div>
                        <div>
                          {formik.touched.user_status &&
                          formik.errors.user_status ? (
                            <div className="error">
                              {formik.errors.user_status}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-12 pt-2 pb-2">
                        {eventname === "create" ? (
                          <>
                            <button
                              disabled={!formik.isValid}
                              onClick={() => createUser()}
                              className="btn btn-primary me-2"
                            >
                              Create
                            </button>
                            <button
                              onClick={formik.handleReset}
                              className="btn btn-secondary"
                            >
                              Clear
                            </button>
                          </>
                        ) : eventname === "edit" ? (
                          <>
                            {" "}
                            <button
                              onClick={() => updateUser()}
                              className="btn btn-primary me-2"
                            >
                              Update
                            </button>
                            <button
                              onClick={() => userEvent("view")}
                              className="btn btn-secondary"
                            >
                              Cancel
                            </button>
                          </>
                        ) : eventname === "delete" ? (
                          <>
                            <button
                              onClick={() => deleteUser(formik.values.user_id)}
                              className="btn btn-danger me-2 "
                            >
                              Delete
                            </button>
                            <button
                              onClick={() => userEvent("view")}
                              className="btn btn-secondary"
                            >
                              Cancel
                            </button>
                          </>
                        ) : (
                          <>
                            {/* <button onClick={() => navigate('/userloyee')} className='btn btn-primary' >Back</button> */}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/*  loader and error handler */}
          {/*  {iserror === false || iserror === true ? <Alertmsg alertmsg={ismessage} type={iserror} /> : <></>} */}
          {<Toastmessage ref={ref} type={iserror} errormsg={ismessage} />}
        </div>
         {/*  upload modal */}
         <div
            className="modal modal-lg"
            id="uploadmodal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content p-2">
                <div className="modal-header p-0 ps-2 pe-2 border-0">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Upload
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body p-0">
                  <Reactcropper newFunc={uploadFn} />
                </div>
                {/*  <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="button" className="btn btn-primary">Understood</button>
                </div> */}
              </div>
            </div>
          </div>
      </div>
    );
  }
};

export default Userevent;
