import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import companyService from "../services/company.service";
import { Companyvalidationschema } from "../components/Validation";
import ReactPaginate from "react-paginate";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import DropdownButton from "react-bootstrap/DropdownButton";
import { format } from "date-fns";
import Loader from "../components/Loader";
import Toastmessage from "../components/Toastmessage";
import { useNavigate } from "react-router-dom";
import Alertmsg from "../components/Alertmsg";
const Company = () => {
  const ref = useRef();
  let navigate = useNavigate();
  const [iserror, setIserror] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [ismessage, setIsmessage] = useState(false);

  const [eventname, setEventname] = useState("create");

  const [companys, setCompanys] = useState([]);
  const [result, setResult] = useState([]);
  //smart table data
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState("");
  const [search, setSearch] = useState("");
  const [between, setBetween] = useState("");
  const [sort, setSort] = useState("");
  const [group, setGroup] = useState("");

  const [pagecount, setPagecount] = useState("");
  const [resultCount, setResultcount] = useState("");

  //filter arrays
  const statusfilterarr = [
    { status_id: "01", status_name: "pending" },
    { status_id: "02", status_name: "active" },
    { status_id: "03", status_name: "inactive" },
  ];
  const [filterarray, setFilterarray] = useState(statusfilterarr);
  //date range
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };
  const handleSelectdate = async (date) => {
    await setStartDate(date.selection.startDate);
    await setEndDate(date.selection.endDate);
    setBetween(
      format(date.selection.startDate, "yyyy-MM-dd") +
        " , " +
        format(date.selection.endDate, "yyyy-MM-dd")
    );
  };
  const resetdaterange = () => {
    setBetween("");
    setStartDate(new Date());
    setEndDate(new Date());
  };
  //form initial value
  const formik = useFormik({
    initialValues: {
      com_id: "",
      com_fullname: "",
      com_username: "",
      com_role: "",
      com_address: "",
      com_status: "",
    },
    validationSchema: Companyvalidationschema,
    /*  validateOnChange: false,
         validateOnBlur: false, */
    onSubmit: (values, { resetForm }) => {
      //alert(JSON.stringify(values, null, 1));
      resetForm();
    },
  });
  //use effects
  useEffect(() => {
    getallCompany();
  }, []);
  useLayoutEffect(() => {
    getallCompany();
  }, [page, limit, filter, search, between, sort]);

  //current event
  const companyEvent = (eventname, value) => {
    if (value != "") {
      getCompany(value);
      setEventname(eventname);
    }
    setEventname(eventname);
  };
  //get allcompany
  const getallCompany = async () => {
    setIsLoaded(true);
    var data = {
      limit: limit,
      page: page,
      filter: filter,
      filterrole: "",
      search: search,
      between: between,
      sort: sort,
      group: group,
    };
    return await companyService
      .getallCompany(data)
      .then((res) => {
        if (res.data.result) {
          setIsLoaded(false);
          // setIserror(res.data.status);
          //setIsmessage(res.data.message);
          setCompanys(res.data.result.data);
          setResult(res.data);
          setPage(res.data.result.page);
          setLimit(res.data.result.limit);
          setPagecount(res.data.result.pagecount);
          setResultcount(res.data.result.resultcount);
          console.log(res.data);
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
      });
  };
  //pagination
  const paginationfn = (event) => {
    setPage(event.selected + 1);
    console.log(event.selected + 1);
  };
  //search
  const searchfn = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };
  //filter
  const [filterArr, setfilterArr] = useState([]);
  const filterfn = (value) => {
    setPage(1);
    var index = filterArr.indexOf(value);
    if (index > -1) {
      filterArr.splice(index, 1);
      setFilter(filterArr.toString());
    } else {
      filterArr.push(value);
      setFilter(filterArr.toString());
    }
  };
  //sort
  const sortfn = (value) => {
    /*    setSort(value); */
    if (sort == value + " desc") {
      setSort(value + " asc");
      console.log(value + " asc");
    } else if (sort == value + " asc") {
      setSort("");
      console.log(value + "");
    } else {
      setSort(value + " desc");
      console.log(value + " desc");
    }
  };
  //between
  const [betweenstart, setBetweenstart] = useState("");
  const [betweenend, setBetweenend] = useState("");
  const betweenfn = (value, e) => {
    setPage(1);
    if (e === null || e === "") {
      setBetween("");
      setBetweenstart("");
      setBetweenend("");
    } else {
      if (value == "start") {
        setBetween(e + "," + e);
        console.log(e + "," + e);
        setBetweenstart(e);
        setBetweenend(e);
      } else if (value == "end") {
        setBetweenend(e);
        const dd = between.split(",");
        setBetween(dd[0] + "," + e);
        console.log(dd[0] + "," + e);
        setBetweenstart(dd[0]);
        setBetweenend(e);
      } else {
        setBetween("");
        setBetweenstart("");
        setBetweenend("");
      }
    }
  };
  //get company
  const getCompany = (value) => {
    setIserror(null);
    setIsmessage(null);
    setIsLoaded(true);
    companyService
      .getCompany(value)
      .then((res) => {
        if (res.data.result) {
          console.log(res.data);
          setIsLoaded(false);
          let data = res.data.result;
          formik.setValues({
            com_id: data.com_id,
            com_fullname: data.com_fullname,
            com_username: data.com_username,
            com_role: data.com_role,
            com_address: data.com_address,
            com_status: data.com_status,
          });
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
      });
  };
  //create company
  const createCompany = () => {
    setIserror(null);
    setIsmessage(null);
    setIsLoaded(true);
    companyService
      .createCompany(formik.values)
      .then((res) => {
        if (res.data.status) {
          console.log(res.data);
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          getallCompany();
          ref.current.log();
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
          ref.current.log();
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
        ref.current.log();
      });
  };

  //update company
  const updateCompany = (value) => {
    console.log(JSON.stringify(formik.values, null, 2));
    companyService
      .updateCompany(formik.values)
      .then((res) => {
        if (res.data.status) {
          console.log(res.data);
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          ref.current.log();
          getallCompany();
          setEventname("create");
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
          ref.current.log();
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
        ref.current.log();
      });
  };
  //delete company
  const deleteCompany = (value) => {
    console.log(value);
    companyService
      .deleteCompany(String(value))
      .then((res) => {
        if (res.data.status) {
          console.log("succes");
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          getallCompany();
          setEventname("create");
          ref.current.log();
        } else {
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setIsLoaded(false);
          ref.current.log();
        }
      })
      .catch((err) => {
        setIserror(true);
        setIsmessage(err.message);
        setIsLoaded(false);
        ref.current.log();
      });
  };
  // Call company clicks to request another page.
  if (ismessage == "Network Error") {
    return <Alertmsg alertmsg={ismessage} type={iserror} />;
  }
  return (
    <div className="">
      <div className="">
        {/*  loader and error handler */}
        {<Toastmessage ref={ref} type={iserror} errormsg={ismessage} />}
        {isLoaded ? <Loader /> : <></>}
      </div>
      {/*  container */}
      <div className="company-container">
        {/* company form */}
        <div className="card  border shadow-sm mb-2">
          <div className="card-body ">
            <div className="d-flex p-2 ">
              <h3 className="card-title p-0 m-0 me-auto">Company Event</h3>
              {eventname == "create" ? (
                <></>
              ) : (
                <div>
                  <div className="dropdown">
                    <a
                      className="btn  btn-link"
                      href="#"
                      role="button"
                      id="companyeventdropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="companyeventdropdownMenuLink"
                    >
                      <li>
                        <a
                          onClick={() => {
                            companyEvent("edit", "");
                          }}
                          className="dropdown-item"
                        >
                          Edit
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => companyEvent("delete", "")}
                          className="dropdown-item"
                        >
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="com_fullname" className="form-label">
                    Company name
                  </label>
                  <div className="input-group">
                    <input
                      className="form-control"
                      placeholder="Enter Company name"
                      id="com_fullname"
                      type="text"
                      disabled={
                        eventname == "view"
                          ? true
                          : eventname == "delete"
                          ? true
                          : false
                      }
                      {...formik.getFieldProps("com_fullname")}
                    />
                  </div>
                  <div>
                    {formik.touched.com_fullname &&
                    formik.errors.com_fullname ? (
                      <div className="error">{formik.errors.com_fullname}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4">
                  <label htmlFor="com_username" className="form-label">
                    Company username
                  </label>
                  <div className="input-group col-md-6">
                    <input
                      className="form-control"
                      placeholder="Enter Company username"
                      id="com_username"
                      type="text"
                      disabled={
                        eventname == "view"
                          ? true
                          : eventname == "delete"
                          ? true
                          : false
                      }
                      {...formik.getFieldProps("com_username")}
                    />
                  </div>
                  <div>
                    {formik.touched.com_username &&
                    formik.errors.com_username ? (
                      <div className="error">{formik.errors.com_username}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-2">
                  <label htmlFor="com_role" className="form-label">
                    Role
                  </label>
                  <div className="input-group col-md-6">
                    <select
                      id="com_role"
                      {...formik.getFieldProps("com_role")}
                      className="form-select"
                      disabled={
                        eventname == "view"
                          ? true
                          : eventname == "delete"
                          ? true
                          : false
                      }
                      aria-label="com_role"
                    >
                      <option value="" disabled>
                        Select role
                      </option>
                      <option value="main">Main</option>
                      <option value="vendor">Vendor</option>
                    </select>
                  </div>
                  <div>
                    {formik.touched.com_role && formik.errors.com_role ? (
                      <div className="error">{formik.errors.com_role}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-2">
                  <label htmlFor="com_status" className="form-label">
                    status
                  </label>
                  <div className="input-group col-md-6">
                    <select
                      id="com_status"
                      {...formik.getFieldProps("com_status")}
                      className="form-select"
                      disabled={
                        eventname == "view"
                          ? true
                          : eventname == "delete"
                          ? true
                          : false
                      }
                      aria-label="com_status"
                    >
                      <option value="" disabled>
                        Select status
                      </option>
                      <option value="pending">Pending</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                  <div>
                    {formik.touched.com_status && formik.errors.com_status ? (
                      <div className="error">{formik.errors.com_status}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12 ">
                  <label htmlFor="com_address" className="form-label">
                    Address
                  </label>
                  <div className="input-group">
                    <textarea
                      className="form-control"
                      placeholder="Enter Address"
                      id="com_address"
                      type="text"
                      disabled={
                        eventname == "view"
                          ? true
                          : eventname == "delete"
                          ? true
                          : false
                      }
                      {...formik.getFieldProps("com_address")}
                    ></textarea>
                  </div>
                  <div>
                    {formik.touched.com_address && formik.errors.com_address ? (
                      <div className="error">{formik.errors.com_address}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-12 pt-2 pb-2">
                  {eventname === "create" ? (
                    <button
                      disabled={!formik.isValid}
                      onClick={() => createCompany()}
                      className="btn btn-primary me-2"
                    >
                      Create
                    </button>
                  ) : eventname === "edit" ? (
                    <button
                      onClick={() => updateCompany(formik.values.com_id)}
                      className="btn btn-primary me-2"
                    >
                      Update
                    </button>
                  ) : eventname === "delete" ? (
                    <button
                      onClick={() => deleteCompany(formik.values.com_id)}
                      className="btn btn-danger me-2 "
                    >
                      Delete
                    </button>
                  ) : (
                    <button
                      onClick={() => companyEvent("create", "")}
                      className="btn btn-primary  me-2"
                    >
                      Back
                    </button>
                  )}
                  <button
                    type="submit"
                    disabled={!formik.dirty}
                    onClick={() => {
                      companyEvent("create", "");
                    }}
                    className="btn btn-secondary"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="flex-fill  p-2">
          <h2>Company</h2>
        </div>
        <div className="">
          {/* tebale header part search,filter,sort */}
          <div className="d-md-flex  mb-2 ">
            {/* create company */}
            {/*  <div className="m-1">
              <button
                onClick={formik.handleReset}
                type="submit"
                className="btn btn-primary  w-100"
              >
                <i className="fa fa-plus-circle"></i> Create
              </button>
            </div> */}
            {/* filter status*/}
            <div className="dropdown m-1">
              <button
                className="btn btn-primary w-100 dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fa fa-filter" aria-hidden="true"></i> Company
                Status
              </button>
              <ul
                className="dropdown-menu p-2"
                aria-labelledby="dropdownMenuButton1"
              >
                {/*  <li className="text-secondary">Company Status</li> */}
                {filterarray.map((filterdata, index) => (
                  <li key={index}>
                    <div className="form-check">
                      <input
                        onChange={(e) => filterfn(filterdata.status_name)}
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      ></input>
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        {filterdata.status_name}
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            {/* search */}
            <div className=" m-1">
              <input
                className="form-control"
                onChange={searchfn}
                placeholder="search"
                type="search"
              ></input>
            </div>
            {/*  date range  */}
            <div className="m-1">
              <div className="d-none">
                <button
                  className="btn bg-white dropdown-toggle"
                  id="dropdownMenuClickableInside"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  <i className="fa fa-calendar" aria-hidden="true"></i>{" "}
                  {between}
                  {between ? (
                    <a
                      className="ms-2 me-2 p-1 text-secondary"
                      onClick={resetdaterange}
                    >
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </a>
                  ) : (
                    <></>
                  )}
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuClickableInside"
                >
                  <DateRangePicker
                    ranges={[selectionRange]}
                    onChange={(date) => handleSelectdate(date)}
                  />
                </ul>
              </div>
            </div>
            {/* between */}
            {/*  <div className="m-1">
                            <input
                                id="betweenstart"
                                className="form-control"
                                value={betweenstart}
                                onChange={(e) => betweenfn("start", e.target.value ? e.target.value : '')}
                                placeholder="start"
                                type="date"
                            ></input>
                        </div>
                        <div className="m-1">
                            <input
                                id="betweenend"
                                className="form-control"
                                value={betweenend}
                                onChange={(e) => betweenfn("end", e.target.value)}
                                placeholder="start"
                                type="date"
                            ></input>
                        </div> */}
            {/*  sort */}
            <div className="m-1 ms-auto">
              <div className="input-group ">
                <span
                  className="input-group-text bg-white border-end-0"
                  id="basic-addon1"
                >
                  Sort :{" "}
                </span>
                <select
                  value={sort}
                  onChange={(e) => {
                    setSort(e.target.value);
                  }}
                  className="form-select border-start-0"
                  aria-label="com_status"
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option value="com_fullname">Company name</option>
                  <option value="com_username">Company username</option>
                  <option value="">Clear</option>
                </select>
              </div>
            </div>
          </div>

          {/* table section*/}
          <div className="table-responsive  border-0 mb-2">
            {/*  all */}
            <table className="table table-hover ">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Company name</th>
                  <th scope="col">Address</th>
                  <th scope="col">Role</th>
                  <th scope="col">Status</th>
                  <th scope="col">Created</th>
                  <th style={{ width: 200 }} scope="col">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* table data */}
                {companys ? (
                  <>
                    {companys.map((data, index) => (
                      <tr key={index}>
                        <td>{data.com_id}</td>
                        <td>{data.com_fullname}</td>
                        <td>{data.com_address}</td>
                        <td>{data.com_role}</td>
                        <td>{data.com_status}</td>
                        <td>{format(new Date(data.createdAt), "PPpp")}</td>
                        <td className="p-1 m-0">
                          <button
                            onClick={() => companyEvent("view", data.com_id)}
                            className="btn  me-2 "
                          >
                            <i className="fa fa-eye" aria-hidden="true"></i>
                          </button>
                          <button
                            onClick={() => {
                              companyEvent("edit", data.com_id);
                            }}
                            className="btn  me-2 "
                          >
                            <i
                              className="fa fa-pencil-square"
                              aria-hidden="true"
                            ></i>
                          </button>
                          <button
                            onClick={() => companyEvent("delete", data.com_id)}
                            className="btn  me-2 "
                          >
                            <i className="fa fa-trash-o" aria-hidden="true"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center">
                      0 data found
                    </td>
                  </tr>
                )}
              </tbody>
              <caption className="">
                <div className="d-flex">
                  <div className="flex-fill"></div>
                  <div className="p-1 "> Items per page </div>
                  <div>
                    {" "}
                    <select
                      value={limit}
                      onChange={(e) => {
                        setLimit(e.target.value);
                        setPage(1);
                      }}
                      className="form-select form-select-sm"
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                    </select>
                  </div>
                  <div className="p-1 me-4">
                    {page * limit - limit}-{page * limit} of {resultCount}
                  </div>
                  <ReactPaginate
                    previousLabel="< Prev"
                    nextLabel="Next >"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    pageCount={pagecount}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={3}
                    onPageChange={paginationfn}
                    containerClassName="pagination"
                    activeClassName="actives"
                    disabledLinkClassName="text-secondary"
                    forcePage={page - 1}
                  />
                </div>
              </caption>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Company;
