import React, { useEffect, useLayoutEffect, useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";
import Loader from "../components/Loader";
import { Link, useNavigate } from "react-router-dom";
import Alertmsg from "../components/Alertmsg";
import analyticsService from "../services/analytics.service";
import imagename from "../assets/images/user.png";
import ReactPaginate from "react-paginate";
import Googlecharts from "../components/Googlecharts";
import { serverURL } from "../http-common";

const Dashboard = () => {
  let navigate = useNavigate();
  const [companychartdata, setCompanychartdata] = useState([]);
  const [employeechartdata, setEmployeechartdata] = useState([]);
  const [date, setDate] = useState(new Date());

  const [today, setToday] = useState("");
  const [yesterday, setYesterday] = useState("");
  const [thismonthfirstday, setThismonthfirstday] = useState("");
  const [thismonthlastday, setThismonthlastday] = useState("");
  const [lastmonthfirstday, setLastmonthfirstday] = useState("");
  const [lastmonthlastday, setLastmonthlastday] = useState("");

  const [iserror, setIserror] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [ismessage, setIsmessage] = useState(false);

  const [companydata, setCompanydata] = useState([]);
  const [companycomparedata, setCompanycomparedata] = useState([]);

  const [employeedata, setEmployeedata] = useState([]);
  const [eventname, setEventname] = useState("all");

  const [pagecount, setPagecount] = useState("");
  const [resultCount, setResultcount] = useState("");
  const [result, setResult] = useState([]);
  //smart table data
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState("");
  const [filtercategory, setFiltercategory] = useState("");

  const [search, setSearch] = useState("");
  const [between, setBetween] = useState("");
  const [between1, setBetween1] = useState("");

  const [sort, setSort] = useState("");
  const [group, setGroup] = useState("emp_username");

  //use effects
  useEffect(() => {
    getallCompanyoverview();
    getallEmployeeoverview();
    /*  getallCompanyoverviewcompare(); */
    console.log(today);
    handleSelectdate();
  }, []);
  useLayoutEffect(() => {
    getallCompanyoverview();
    getallEmployeeoverview();
  }, [page, limit, filter, search, between, between1, sort]);

  //get date
  const handleSelectdate = async () => {
    var thismonthfirstday = new Date(date.getFullYear(), date.getMonth(), 1);
    var thismonthlastday = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var lastmonthfirstday = new Date(
      date.getFullYear(),
      date.getMonth() - 1,
      1
    );
    var lastmonthlastday = new Date(date.getFullYear(), date.getMonth(), 0);
    var yesterdays = new Date();
    var yesterday = yesterdays.setDate(yesterdays.getDate() - 1);
    var today = new Date();

    setThismonthfirstday(format(thismonthfirstday, "yyyy-MM-dd"));
    setThismonthlastday(format(thismonthlastday, "yyyy-MM-dd"));
    setLastmonthfirstday(format(lastmonthfirstday, "yyyy-MM-dd"));
    setLastmonthlastday(format(lastmonthlastday, "yyyy-MM-dd"));
    setToday(format(today, "yyyy-MM-dd"));
    setYesterday(format(yesterday, "yyyy-MM-dd"));
  };
  //daywise filtering
  const monthwisedata = async () => {
    setEventname("month");
    setBetween(thismonthfirstday + " , " + thismonthlastday);
    setBetween1(lastmonthfirstday + " , " + lastmonthlastday);
    console.log(between, between1);
  };
  //all filtering
  const allwisedata = async () => {
    setEventname("all");
    setBetween("");
    setBetween1("");
  };
  //daywise filtering
  const daywisedata = async () => {
    setEventname("day");
    setBetween(today + " , " + today);
    setBetween1(yesterday + " , " + yesterday);
    console.log(between, between1);
  };
  //weekwise filtering
  const weekwisedata = async () => {
    console.log(yesterday);
    setBetween(thismonthfirstday + " , " + thismonthlastday);
    setBetween1(lastmonthfirstday + " , " + lastmonthlastday);
  };
  //yearwise filtering
  const yearwisedata = async () => {
    console.log(yesterday);
    setBetween(thismonthfirstday + " , " + thismonthlastday);
    setBetween1(lastmonthfirstday + " , " + lastmonthlastday);
  };
  //monthwise filtering
  //weekwise filtering
  //yearwise filtering
  //get allappoinment
  const getallCompanyoverview = async () => {
    setIserror(null);
    setIsmessage(null);
    setIsLoaded(true);
    var data = {
      limit: limit,
      page: page,
      filter: "",
      filtercategory: "",
      filterrole: "main",
      search: "",
      between: between,
      sort: "com_total_receipt DESC",
      group: "com_id",
    };
    return await analyticsService
      .getallCompanyoverview(data)
      .then((res) => {
        console.log(res.data.result);

        if (res.data.result) {
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setCompanydata(res.data.result.data);
          const data = [
            ["name", "amount"],
            ...res.data.result.data.map((d) => [
              d.com_fullname,
              Number(d.com_total_receipt),
            ]),
          ];
          setCompanychartdata(data);
          console.log(data);
        } else {
          setIserror(false);
          setIsmessage(res.data.message);
          setIsLoaded(false);
        }
      })
      .catch((err) => {
        setIserror(false);
        setIsmessage(err.message);
        setIsLoaded(false);
      });
  };
  //get allappoinment
  const getallCompanyoverviewcompare = async () => {
    setIserror(null);
    setIsmessage(null);
    setIsLoaded(true);
    var data = {
      limit: limit,
      page: page,
      filter: "",
      filtercategory: "",
      search: "",
      between: between1,
      sort: "",
      group: "com_id",
    };
    return await analyticsService
      .getallCompanyoverview(data)
      .then((res) => {
        console.log(res.data.result);

        if (res.data.result) {
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);
          setCompanycomparedata(res.data.result.data);
        } else {
          setIserror(false);
          setIsmessage(res.data.message);
          setIsLoaded(false);
        }
      })
      .catch((err) => {
        setIserror(false);
        setIsmessage(err.message);
        setIsLoaded(false);
      });
  };
  //get app employee information
  //get allappoinment
  const getallEmployeeoverview = async () => {
    setIserror(null);
    setIsmessage(null);
    setIsLoaded(true);
    var data = {
      limit: limit,
      page: page,
      filter: filter,
      filtercategory: filtercategory,

      search: search,
      between: between,
      sort: "emp_total_receipt DESC",
      group: group,
    };
    return await analyticsService
      .getallEmployeeoverview(data)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          setIsLoaded(false);
          setIserror(res.data.status);
          setIsmessage(res.data.message);

          setEmployeedata(res.data.result.data);
          setResult(res.data);
          setPage(res.data.result.page);
          setLimit(res.data.result.limit);
          setPagecount(res.data.result.pagecount);
          setResultcount(res.data.result.resultcount);
        } else {
          setIserror(false);
          setIsmessage(res.data.message);
          setIsLoaded(false);
        }
      })
      .catch((err) => {
        setIserror(false);
        setIsmessage(err.message);
        setIsLoaded(false);
      });
  };
  //pagination
  const paginationfn = (event) => {
    setPage(event.selected + 1);
    console.log(event.selected + 1);
  };
  //search
  const searchfn = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };
  // Call appoinment clicks to request another page.
  if (ismessage == "Network Error") {
    return <Alertmsg alertmsg={ismessage} type={iserror} />;
  }

  const selectedcolor = (value) => {
    if (value > 10) {
      return "badge rounded-pill bg-success";
    } else if (value > 5) {
      return "badge rounded-pill bg-warning ";
    } else if (value > 0) {
      return "badge rounded-pill bg-danger";
    } else {
      return "badge rounded-pill bg-dark";
    }
  };
  const calculatearr = (filterby, requirevalue, amount) => {
    /*  const resultdata = companycomparedata.find(
      (x) => x.com_id === value
    ).com_total_cost; */
    if (companycomparedata.length) {
      const resultdata = companycomparedata.find((x) => x.com_id === filterby)[
        requirevalue
      ];
      console.log(resultdata, amount);

      const finalvalue =
        100 *
        Math.abs((amount - resultdata) / ((amount + resultdata) / 2)).toFixed(
          3
        );
      return Number(resultdata.toFixed(3));
    } else {
      return 0;
    }
  };
  if (isLoaded) {
    <Loader />;
  } else {
    return (
      <div className="">
        <div className="d-flex mb-2">
          <div className="flex-fill mb-3">
            <h2>Overview</h2>
          </div>
          <div className="">
            <a
              onClick={() => allwisedata()}
              className={eventname === "all" ? " btn border-bottom " : "btn"}
            >
              All
            </a>
            <a
              onClick={() => daywisedata()}
              className={eventname === "day" ? " btn border-bottom " : "btn"}
            >
              Day
            </a>
            <a
              onClick={() => monthwisedata()}
              className={eventname === "month" ? "btn border-bottom" : "btn"}
            >
              Month
            </a>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4   g-2 g-lg-3">
          {companydata.map((data, index) => (
            <div key={index} className="col">
              <div className=" shadow-sm bg-white rounded p-2">
                <div className="row ">
                  <div className="col-3 ">
                    <div
                      className="bg-light rounded h-100 text-center fw-bold pt-2"
                      style={{ minWidth: 100 }}
                    >
                      {data.com_total_receipt}
                      <div style={{ fontSize: 12, fontWeight: 400 }}>
                        Receipt
                      </div>
                    </div>{" "}
                  </div>
                  <div className="col ">
                    <div className="ps-3">
                      <div
                        className="text-capitalize fw-bold "
                        style={{ minWidth: 100 }}
                      >
                        &nbsp;{data.com_fullname}
                      </div>
                      <div className="d-flex flex-wrap">
                        <div className="p-1">
                          In&nbsp;
                          <span className="fw-bold">
                            {data.com_total_receipt_in}
                          </span>
                        </div>
                        <div className="p-1">
                          Out&nbsp;
                          <span className="fw-bold">
                            {data.com_total_receipt_out}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="m-4"></div>
        <div className="flex-fill mb-3">
          <h2>Employee Usage</h2>
        </div>
        {/*  table section */}
        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive  border-0 mb-2">
              {/*  all */}
              <table className="table table-hover ">
                <thead>
                  <tr>
                    <th>#</th>
                    <th scope="col">name</th>
                    <th style={{ width: 100 }} scope="col">
                      Total receipt
                    </th>
                    <th style={{ width: 100 }} scope="col">
                      Inventory In
                    </th>
                    <th style={{ width: 100 }} scope="col">
                      inventory out
                    </th>
                    {/* <th scope="col">total Nos/Qty</th> */}
                  </tr>
                </thead>
                <tbody>
                  {/* table data */}
                  {employeedata ? (
                    <>
                      {employeedata.map((data, index) => (
                        <tr key={index}>
                          <td>
                            <div>
                              <img
                                style={{
                                  width: 40,
                                  height: 40,
                                  borderRadius: "50%",
                                }}
                                src={
                                  data.emp_pic
                                    ? serverURL + "/" + data.emp_pic
                                    : imagename
                                }
                              />
                            </div>
                          </td>
                          <td>{data.emp_fullname}</td>
                          <td>{data.emp_total_receipt}</td>
                          <td>{data.emp_total_in}</td>
                          <td>{data.emp_total_out}</td>
                          {/*   <td>{data.emp_total_qty}</td> */}
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        <Alertmsg alertmsg={ismessage} />
                      </td>
                    </tr>
                  )}
                </tbody>
                <caption className="">
                  <div className="d-flex">
                    <div className="flex-fill"></div>
                    <div className="p-1 "> Items per page </div>
                    <div>
                      {" "}
                      <select
                        value={limit}
                        onChange={(e) => {
                          setLimit(e.target.value);
                          setPage(1);
                        }}
                        className="form-select form-select-sm"
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                      </select>
                    </div>
                    <div className="p-1 me-4">
                      {page * limit - limit}-{page * limit} of {resultCount}
                    </div>
                    <ReactPaginate
                      previousLabel="< Prev"
                      nextLabel="Next >"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      pageCount={pagecount}
                      marginPagesDisplayed={3}
                      pageRangeDisplayed={3}
                      onPageChange={paginationfn}
                      containerClassName="pagination"
                      activeClassName="actives"
                      disabledLinkClassName="text-secondary"
                      forcePage={page - 1}
                    />
                  </div>
                </caption>
              </table>
              <div className="text-center">
                {/*  loader and error handler */}
                {/*  {<Toastmessage ref={ref} type={error} errormsg={ismessage} />} */}
                {isLoaded ? <Loader /> : <></>}
              </div>
            </div>
          </div>
          <div className="col-md-4 d-none">
            <div className=" p-1">
              {/*    <div className="text-center">Company data</div> */}
              <Googlecharts
                com_data={companychartdata}
                chart_type="PieChart"
                haxis="name"
                vaxis="amount"
                title=""
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Dashboard;
