import axios from "axios";
import { serverURL,headeroption,headeroptionupload } from "../http-common";

class CompanyService {
  getallCompany(data) {
    return axios.get(serverURL + "/company-api?req=smarttableCompany", {
      params: data,
      headers: headeroption,
    })
  }
  getCompany(com_id) {
    return axios.get(serverURL + "/company-api?req=getCompany", {
      params: { com_id: com_id }, headers: headeroption,
    });
  }
  createCompany(data) {
    return axios.post(serverURL + "/company-api?req=createCompany", data, {
      headers: headeroption,
    });
  }
  updateCompany(data) {
    return axios.put(serverURL + "/company-api?req=updateCompany", data, {
      headers: headeroption,
    });
  }
  deleteCompany(com_id) {
    return axios.delete(serverURL + "/company-api?req=deleteCompany", { params: { com_id: com_id }, headers: headeroption, });
  }
  deleteAll() {
    return axios.delete(serverURL + "/company-api?req=deleteCompany");
  }
  findByTitle(com_name) {
    return axios.get(serverURL + "/user/company-api?name=" + com_name);
  }
  upload(com_id, data) {
    return axios.post(serverURL + "/company-api/uploadsinglefile?com_id=" + com_id, data, { headers: headeroptionupload });
  }
  uploads(com_id, data) {
    return axios.post(serverURL + "/company-api/uploadmultifile?com_id=" + com_id, data, { headers: headeroptionupload });
  }
}
export default new CompanyService();
