import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const modules = {
    toolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
            {
              color: ["red", "blue", "yellow"],
            },
          ],
       
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        /*  [{ script: "sub" }, { script: "super" }], // superscript/subscript */
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ direction: "rtl" }], // text direction
        [{ size: ["small", false, "large", "huge"] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        /* [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }], */
        ["link", "image", "video"],
    ],
};

const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
];
const Reactquill = (props) => {
    const [value, setValue] = useState("");

    const editordatachange = (value) => {
        setValue(value);
        //props.newFunc(value);
    };
    useEffect(() => {
        setValue(props.dataParentToChild);
    }, []);
    return (
        <div className="container">
            <div className="shadow p-2">
                <div className=""><strong><i className="fa fa-file-text-o"></i>&nbsp;&nbsp;Editor</strong></div>
            <ReactQuill style={{minHeight:300}}
                modules={modules}
                placeholder="Compose an epic"
                theme="snow"
                value={value}
                onChange={editordatachange}
            />
            </div>
            {/*      quill editor toolbar twise issue fix 
  .quill > .ql-toolbar:first-child {
    display: none !important;
  } */}
            {/* <div>her{props.dataParentToChild}</div> */}
        </div>
    );
}

export default Reactquill